import React from 'react'
import { Button, Col, Divider, Menu, Row, Switch, Tag, Typography } from 'antd'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppStore } from '../store/applicationState'
import { DeleteSignOut } from '../store/auth/actions'
import { snakeCaseToString } from '../utils/helpers'
import { ROUTES } from '../utils/routes'
import { SetOnline } from '../store/myself/actions'

const Sidebar = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { MySelf, Auth } = useSelector((store: AppStore) => store)

  const setIsOnline = (value: boolean) => {
    dispatch(SetOnline(value))
    if (value && location.pathname !== ROUTES.home) history.push(ROUTES.home)
  }

  const onLogOut = () => dispatch(DeleteSignOut.request())

  const getItem = (label: React.ReactNode, key: string, children?: React.ReactNode[]) => ({
    label,
    key,
    children,
  })

  const items = [
    getItem(
      <Link to='/shops/list'>
        <i className='icon icon-widgets' />
        <span>List of Shops</span>
      </Link>,
      'shops',
      [
        getItem(
          <Link to='/shops/create'>
            <i className='icon icon-widgets' />
            <span>Create a Shop</span>
          </Link>,
          'shops/create'
        ),
      ]
    ),
    getItem(
      <Link to='/departments/list'>
        <i className='icon icon-widgets' />
        <span>List of Departments</span>
      </Link>,
      'departments',
      [
        getItem(
          <Link to='/departments/create'>
            <i className='icon icon-widgets' />
            <span>Create a Department</span>
          </Link>,
          'departments/create'
        ),
      ]
    ),
    getItem(
      <Link to='/consultants/list'>
        <i className='icon icon-widgets' />
        <span>List of Consultants</span>
      </Link>,
      'consultants',
      [
        getItem(
          <Link to='/consultants/add'>
            <i className='icon icon-widgets' />
            <span>Add a Consultants</span>
          </Link>,
          'consultants/create'
        ),
      ]
    ),
    getItem(
      <Link to='/create-qr-code'>
        <i className='icon icon-widgets' />
        <span>Create a QR Code</span>
      </Link>,
      'create-qr-code'
    ),
    getItem(
      <Link to='/call-statistics'>
        <i className='icon icon-widgets' />
        <span>Call Statistics</span>
      </Link>,
      'call-statistics'
    ),
    getItem(
      <Link to={ROUTES.settingsMyAccount}>
        <i className='icon icon-widgets' />
        <span>Settings</span>
      </Link>,
      'settings',
      [
        getItem(
          <Link to={ROUTES.settingsMyAccount}>
            <i className='icon icon-widgets' />
            <span>My Account</span>
          </Link>,
          'settings/my-account'
        ),
      ]
    ),
  ]

  const itemsForConsultant = [
    getItem(
      <Link to={ROUTES.settingsMyAccount}>
        <i className='icon icon-widgets' />
        <span>Settings</span>
      </Link>,
      'settings',
      [
        getItem(
          <Link to={ROUTES.settingsMyAccount}>
            <i className='icon icon-widgets' />
            <span>My Account</span>
          </Link>,
          'settings/my-account'
        ),
      ]
    ),
  ]

  if (Auth.user?.isConsultant) {
    return (
      <>
        <div className='logo'>Remote-consultant</div>
        <Row>
          <Col span={24} style={{ padding: '12px 20px' }}>
            <Row>
              <Col>
                {MySelf.data?.retailer?.name && (
                  <Typography.Title level={5} style={{ color: '#fff', margin: 0 }}>
                    {snakeCaseToString(MySelf.data?.retailer?.name)}
                  </Typography.Title>
                )}
                {MySelf?.data && (
                  <Typography.Title level={5} style={{ color: '#fff', margin: 0 }}>
                    {snakeCaseToString(MySelf.data?.first_name)} {snakeCaseToString(MySelf.data?.last_name)}
                  </Typography.Title>
                )}
              </Col>
            </Row>
            <Divider style={{ borderColor: 'rgba(255,255,255,0.1)', margin: '20px 0' }} />
            <Row>
              <Col span={24}>
                <Tag color='#444' style={{ padding: '4px 8px', fontSize: '14px', width: '100%' }}>
                  Work Space
                </Tag>
              </Col>
            </Row>
            <Row justify='space-between' style={{ margin: '20px 0 40px 0' }}>
              <Col>
                <Typography.Text style={{ color: '#fff', margin: 0 }}>Online</Typography.Text>
              </Col>
              <Col>
                <Switch
                  disabled={!!MySelf.qrCodeData}
                  checked={MySelf.isOnline}
                  loading={MySelf.loading}
                  onChange={setIsOnline}
                />
              </Col>
            </Row>
            {MySelf.qrCodeData?.call_started && (
              <Row>
                <Col span={24}>
                  <Typography.Title level={5} style={{ color: '#BCBCBC', fontWeight: 500, margin: 0 }}>
                    QR Code information
                  </Typography.Title>
                </Col>
                <Col span={24}>
                  <Typography.Title level={5} style={{ color: '#BCBCBC', fontWeight: 400, margin: 0 }}>
                    Shop
                  </Typography.Title>
                </Col>
                <Col span={24}>
                  <Typography.Text style={{ color: '#BCBCBC', margin: 0 }}>
                    {MySelf.data?.trade_points.find((trade) => trade.id === MySelf.qrCodeData?.trade_point_id)?.Name}
                  </Typography.Text>
                </Col>
                <Divider style={{ borderColor: 'rgba(255,255,255,0.1)', margin: '20px 0' }} />
                <Col span={24}>
                  <Typography.Title level={5} style={{ color: '#BCBCBC', fontWeight: 400, margin: 0 }}>
                    Departament
                  </Typography.Title>
                </Col>
                <Col span={24}>
                  <Typography.Text style={{ color: '#BCBCBC', margin: 0 }}>
                    {MySelf.data?.retailer_tags.find((tag) => tag.id === MySelf.qrCodeData?.retailer_tag_id)?.Name}
                  </Typography.Text>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Menu items={itemsForConsultant} theme='dark' mode='inline' />
        <Button type='text' style={{ position: 'absolute', bottom: 20, left: 0 }} onClick={onLogOut}>
          <Typography.Text style={{ color: '#fff', margin: 0 }}>Log out</Typography.Text>
        </Button>
      </>
    )
  }

  return (
    <>
      <div className='logo'>Remote-consultant</div>
      <Row>
        <Col span={24} style={{ padding: '12px 20px' }}>
          <Row>
            <Col>
              {MySelf.data?.retailer?.name && (
                <Typography.Title level={5} style={{ color: '#fff', margin: 0 }}>
                  {snakeCaseToString(MySelf.data?.retailer?.name)}
                </Typography.Title>
              )}
              {MySelf?.data && (
                <Typography.Title level={5} style={{ color: '#fff', margin: 0 }}>
                  {snakeCaseToString(MySelf.data?.first_name)} {snakeCaseToString(MySelf.data?.last_name)}
                </Typography.Title>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Menu items={items} theme='dark' mode='inline' />
      <Button type='text' style={{ position: 'absolute', bottom: 20, left: 0 }} onClick={onLogOut}>
        <Typography.Text style={{ color: '#fff', margin: 0 }}>Log out</Typography.Text>
      </Button>
    </>
  )
}

export default Sidebar
