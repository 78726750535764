import React from 'react'
import { Col, Row } from 'antd'
import { useIntl } from 'react-intl'
import { EllipseBackgroundIcon } from '../../../asserts/svg/MainBlock5/EllipseBackgroundIcon'
import humanDevice from '../../../asserts/png/landing-human-device.png'
import humanDeviceMob from '../../../asserts/png/landing-human-device-mob.png'
import areaDevice from '../../../asserts/png/landing-area-device.png'
import areaDeviceMob from '../../../asserts/png/landing-area-device-mob.png'
import consultants from '../../../asserts/png/landing-consultants.png'
import { GreenBlur } from '../GreenBlur'
import { Line } from '../Line'
import { Background } from '../Background'

export const MainBlock6 = () => {
  const intl = useIntl()

  return (
    <Row
      justify='center'
      className='br-top-20 b-md-0 background-black background-md-none pt-md-100 pb-md-40 py-16 mt-50'
      style={{ position: 'relative' }}
    >
      <Col xl={20} xs={22} style={{ zIndex: 100, position: 'relative' }}>
        <Row justify='center' className='mb-md-24 mb-20' gutter={[0, 16]}>
          <Col md={0} xs={24}>
            <Line style={{ marginLeft: 0, justifyContent: 'flex-start', width: '24%' }}>1</Line>
          </Col>
          <Col md={16} xs={24}>
            <p className='landing-text-46'>{intl.formatMessage({ id: 'Implementation with the device creation' })}</p>
          </Col>
        </Row>
        <div className='hidden block-md' style={{ position: 'absolute', left: 0, top: 14, width: 380 }}>
          <Line style={{ marginLeft: 0, justifyContent: 'flex-start' }}>1</Line>
        </div>
      </Col>
      <Col xl={20} md={22} xs={24} style={{ zIndex: 100, position: 'relative' }}>
        <Row gutter={[0, 30]}>
          <Col span={24}>
            <div className='basic-info-container'>
              <Row gutter={[32, 16]}>
                <Col md={12} xs={0} style={{ margin: 'auto' }}>
                  <img width='100%' className='b-20' loading='lazy' src={humanDevice} alt='human-device_image' />
                </Col>
                <Col md={0} xs={24} style={{ margin: 'auto' }}>
                  <img width='100%' className='b-20' loading='lazy' src={humanDeviceMob} alt='human-device-mob_image' />
                </Col>
                <Col md={0} xs={6}>
                  <div style={{ width: '100%', height: 2, background: '#489E38' }} />
                </Col>
                <Col md={12} xs={24} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <p className='landing-text-20-thin'>
                    {intl.formatMessage({ id: 'The device will be realized as a monoblock with a camera' })}
                  </p>
                  <br />
                  <p className='landing-text-20-thin'>
                    {intl.formatMessage({ id: 'It will be activated in one of the alternative ways' })}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24}>
            <div className='basic-info-container'>
              <Row gutter={[32, 16]}>
                <Col
                  md={{ span: 12, order: 1 }}
                  xs={{ span: 24, order: 2 }}
                  style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
                >
                  <p className='landing-text-20-thin'>
                    {intl.formatMessage({ id: 'Additional benefits of the device' })}
                  </p>
                </Col>
                <Col md={0} xs={6}>
                  <div style={{ width: '100%', height: 2, background: '#489E38' }} />
                </Col>
                <Col md={{ span: 12, order: 2 }} xs={0} style={{ margin: 'auto' }}>
                  <img width='100%' className='b-20' loading='lazy' src={areaDevice} alt='area-device_image' />
                </Col>
                <Col md={0} xs={{ span: 24, order: 1 }} style={{ margin: 'auto' }}>
                  <img width='100%' className='b-20' loading='lazy' src={areaDeviceMob} alt='area-device-mob_image' />
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24}>
            <div className='basic-info-container'>
              <Row gutter={[32, 16]}>
                <Col md={12} xs={24} style={{ margin: 'auto' }}>
                  <img width='100%' className='b-20' loading='lazy' src={consultants} alt='consultants_image' />
                </Col>
                <Col md={0} xs={6}>
                  <div style={{ width: '100%', height: 2, background: '#489E38' }} />
                </Col>
                <Col md={12} xs={24}>
                  <Row justify='space-between' style={{ height: '100%' }} gutter={[0, 12]}>
                    <Col span={24}>
                      <p className='landing-text-20-thin mt-md-20'>
                        {intl.formatMessage({ id: 'Also, the implementation of this approach includes the creation' })}
                      </p>
                    </Col>
                    <Col lg={12} md={24} xs={0} style={{ marginTop: 'auto' }}>
                      <button
                        type='button'
                        className='default-landing-btn hidden block-md'
                        style={{ width: '100%' }}
                        onClick={() => {
                          window.location.href = '#contact-us'
                        }}
                      >
                        {intl.formatMessage({ id: 'Book Your Free Demo' })}
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
      <EllipseBackgroundIcon
        className='hidden block-md'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          transform: 'rotate(180deg)',
        }}
      />
      <GreenBlur style={{ top: 40, left: '50%', transform: 'translateX(-50%)' }} blur='big' />
      <GreenBlur style={{ top: '45%', right: 0 }} blur='big' />
      <GreenBlur style={{ bottom: 20, left: 0 }} blur='big' />
      <Background className='hidden block-md' style={{ top: '20%' }} />
    </Row>
  )
}
