/* eslint-disable no-console */
import { useEffect, useRef, useState } from 'react'
import { TOKEN } from '../utils/consts'

export const useSocketMain = (tradePointID?: string, retailerTagId?: string) => {
  const wss = useRef<WebSocket | null>(null)

  const [connected, setConnected] = useState(false)
  const [reconnectStep, setReconnectStep] = useState<number>(0)

  const connect = () => {
    wss.current = new WebSocket(
      tradePointID && retailerTagId
        ? `${process.env.REACT_APP_WEB_SOCKET_PATH}/api/v1/ws/customer/${tradePointID}/${retailerTagId}`
        : `${process.env.REACT_APP_WEB_SOCKET_PATH}/api/v1/ws/consultant`
    )

    wss.current.onopen = () => {
      const token = localStorage.getItem(TOKEN.access)
      if (token) wss.current?.send(JSON.stringify({ access_token: token }))
      console.log('connect')
      setConnected(true)
    }

    wss.current.onerror = (evt) => console.log(evt, 'onerror')

    wss.current.onclose = (evt) => {
      console.log(evt, 'onclose')
      setReconnectStep((prev) => prev + 1)
      setTimeout(() => setConnected(false), 1000)
    }
  }

  useEffect(() => {
    if (!connected) connect()
  }, [connected])

  useEffect(() => {
    if (reconnectStep > 10) window.location.reload()
  }, [reconnectStep])

  return {
    ws: wss.current,
    connected,
  }
}
