import { combineReducers } from 'redux'
import { AuthReducer } from './auth/reducer'
import { UsersReducer } from './users/reducer'
import { TradePointReducer } from './tradePoints/reducer'
import { RetailerTagReducer } from './retailerTags/reducer'
import { MySelfReducer } from './myself/reducer'
import { CallReducer } from './calls/reducer'
import { ContactUsReducer } from './contact-us/reducer'
import { GeneralReducer } from './general/reducer'

const rootReducer = combineReducers({
  Auth: AuthReducer,
  Users: UsersReducer,
  MySelf: MySelfReducer,
  TradePoints: TradePointReducer,
  RetailerTags: RetailerTagReducer,
  Calls: CallReducer,
  ContactUs: ContactUsReducer,
  General: GeneralReducer,
})

export default rootReducer
