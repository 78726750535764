import React, { CSSProperties } from 'react'

interface LineProps {
  style?: CSSProperties
  children: any
}

export const Line: React.FC<LineProps> = ({ style, children }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        width: '35%',
        ...style,
      }}
    >
      <div style={{ minWidth: 12, width: 12, height: 12, borderRadius: '50%', background: '#489E38' }} />
      <div style={{ width: '100%', height: 1, background: '#489E38' }} />
      <p className='landing-text-16-green' style={{ marginLeft: 12, whiteSpace: 'nowrap' }}>
        {children}
      </p>
    </div>
  )
}
