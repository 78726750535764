export const API_ROUTES = {
  getPosts: '/posts',
  signIn: '/auth/sign-in',
  signOut: '/auth/sign-out',
  getCurrentUser: '/users/myself/info',
  refreshToken: '/auth/refresh', // add route for refresh token
  tradePoints: '/admin/trade-points',
  retailerTags: '/admin/retailer-tags',
  users: '/admin/users',
  consultants: '/admin/users/consultants',
  userToTradePoints: '/admin/user-to-trade-points',
  retailerTagToUser: '/admin/retailer-tag-to-user',
  updateUsers: '/admin/users',
  updateMyPassword: '/users/myself/change-password',
  updateMyInfo: '/users/myself/change-myself-information',
  calls: '/admin/calls',
  callStatuses: '/admin/calls/statuses',
  contactUs: '/contact-us',
}
