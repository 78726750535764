import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Select, Form, Col, Typography } from 'antd'
import QRCode from 'qrcode.react'
import { jsPDF } from 'jspdf'
import { Container } from '../wrappers/Container'
import { GetRetailerTags } from '../store/retailerTags/actions'
import { GetTradePoints } from '../store/tradePoints/actions'
import { AppStore } from '../store/applicationState'
import { TTradePoint } from '../store/tradePoints/types'
import { TRetailerTag } from '../store/retailerTags/types'
import { API_PATH } from '../utils/consts'
import { snakeCaseToString } from '../utils/helpers'

interface CreateQRCodeViewProps {}

export const CreateQRCodeView: React.FC<CreateQRCodeViewProps> = () => {
  const [tradePointId, setTradePointId] = useState<string>('')
  const [retailerTagId, setRetailerTagId] = useState<string>('')
  const [qRCodeValue, setQRCodeValue] = useState<string>('Please, generate your QR code')

  const dispatch = useDispatch()

  const { RetailerTags, TradePoints } = useSelector((store: AppStore) => store)

  useEffect(() => {
    dispatch(GetRetailerTags.request({}))
    dispatch(GetTradePoints.request({}))
  }, [])

  const handleChangeTradePoint = (value: string) => {
    setTradePointId(value)
  }

  const handleChangeRetailerTag = (value: string) => {
    setRetailerTagId(value)
  }

  const generateQRCode = (tValueId: string, rTagId: string) => {
    setQRCodeValue(`${API_PATH}/make-call/${tValueId}/${rTagId}`)
  }

  const generatePDF = (name: string) => {
    if (document.getElementById('qrCode')) {
      /* eslint new-cap: ["error", { "newIsCap": false }] */
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: [100, 100],
      })
      const canvas = document.getElementById('qrCode')
      // @ts-ignore
      const base64Image = canvas?.toDataURL()
      pdf.addImage(base64Image, 'png', 0, 0, 100, 100)
      pdf.save(name)
    }
  }

  const downloadQR = (name: string) => {
    const canvas = document.getElementById('qrCode')
    if (canvas) {
      // @ts-ignore
      const pngUrl = canvas?.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      const downloadLink = document.createElement('a')
      downloadLink.href = pngUrl
      downloadLink.download = name
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
  }

  return (
    <Container>
      <Typography.Title level={3} className='pageTitle'>
        Create QR Code
      </Typography.Title>
      <Row>
        <Col style={{ margin: '15px' }} xl={4} lg={6} md={8} sm={10} xs={12}>
          <Form.Item initialValue='Any' label='Assign a shop' labelCol={{ span: 24 }} name='tradePoint'>
            <Select id='select' placeholder='Select shop' onChange={(val) => handleChangeTradePoint(val)}>
              {TradePoints?.data?.trade_points?.map((option: TTradePoint) => (
                <Select.Option key={option.id} value={option.id}>
                  {snakeCaseToString(option.name)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col style={{ margin: '15px' }} xl={4} lg={6} md={8} sm={10} xs={12}>
          <Form.Item initialValue='Any' label='Assign department' labelCol={{ span: 24 }} name='retailerTag'>
            <Select id='select' placeholder='Select department' onChange={(val) => handleChangeRetailerTag(val)}>
              {RetailerTags?.data?.RetailerTagInformation?.map((option: TRetailerTag) => (
                <Select.Option key={option.id} value={option.id}>
                  {snakeCaseToString(option.name)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col style={{ margin: '5px 15px 0' }} xl={4} lg={6} md={8} sm={10} xs={12}>
          {qRCodeValue && (
            <QRCode
              id='qrCode'
              size={220}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={qRCodeValue}
              renderAs='canvas'
            />
          )}
        </Col>
        <Col
          style={{
            margin: '5px 15px 0',
            display: 'flex',
            alignContent: 'space-between',
            flexWrap: 'wrap',
          }}
          xl={4}
          lg={6}
          md={8}
          sm={10}
          xs={12}
        >
          <Button
            size='large'
            type='primary'
            style={{ padding: '0 20px' }}
            disabled={!tradePointId || !retailerTagId}
            onClick={() => generateQRCode(tradePointId, retailerTagId)}
          >
            Generate a QR code
          </Button>
          <div>
            <Typography.Title level={4}>Download: </Typography.Title>
            <Button
              style={{ margin: '10px 5px 5px 0' }}
              onClick={() => generatePDF(`pdf-make-call/${tradePointId}/${retailerTagId}`)}
              disabled={!tradePointId || !retailerTagId}
            >
              PDF
            </Button>
            <Button
              style={{ margin: '10px 10px 5px 0' }}
              onClick={() => downloadQR(`png-make-call/${tradePointId}/${retailerTagId}`)}
              disabled={!tradePointId || !retailerTagId}
            >
              PNG
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
