import { Reducer } from 'redux'
import { ActionTypes, TMySelfState } from './types'
import { ONLINE } from '../../utils/consts'

export const initialState: TMySelfState = {
  loading: false,
  data: null,
  qrCodeData: null,
  isOnline: sessionStorage.getItem(ONLINE) === 'true',
}

const reducer: Reducer<TMySelfState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CURRENT_USER_INFO_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_CURRENT_USER_INFO_S:
      return { ...state, loading: false, data: action.payload }
    case ActionTypes.GET_CURRENT_USER_INFO_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.UPDATE_MY_PASSWORD_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.UPDATE_MY_PASSWORD_S:
      return { ...state, loading: false }
    case ActionTypes.UPDATE_MY_PASSWORD_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.UPDATE_MY_INFO_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.UPDATE_MY_INFO_S:
      return { ...state, loading: false }
    case ActionTypes.UPDATE_MY_INFO_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.SET_ONLINE:
      return { ...state, isOnline: action.payload }

    case ActionTypes.SET_QR_CODE_DATA:
      return {
        ...state,
        qrCodeData: action.payload ? { ...state.qrCodeData, ...action.payload } : action.payload,
      }

    default:
      return state
  }
}

export { reducer as MySelfReducer }
