export interface TTradePoint {
  id: string
  name: string
  address?: string
  phone?: string
  description?: string
}

export interface TTradePointData {
  trade_points: TTradePoint[]
  total: number
}

export interface TTradePointState {
  loading: boolean
  data: {
    total: number
    trade_points: TTradePoint[] | null
    tradePointForEdit?: TTradePoint | null
  }
  errors?: string | undefined
}

export interface TTradePointMessage {
  message: string
}

export enum ActionTypes {
  GET_TRADE_POINTS_R = '@@tradePoints/GET_TRADE_POINTS_R',
  GET_TRADE_POINTS_S = '@@tradePoints/GET_TRADE_POINTS_S',
  GET_TRADE_POINTS_E = '@@tradePoints/GET_TRADE_POINTS_E',

  GET_TRADE_POINT_BY_ID_R = '@@tradePoints/GET_TRADE_POINT_BY_ID_R',
  GET_TRADE_POINT_BY_ID_S = '@@tradePoints/GET_TRADE_POINT_BY_ID_S',
  GET_TRADE_POINT_BY_ID_E = '@@tradePoints/GET_TRADE_POINT_BY_ID_E',

  CREATE_NEW_TRADE_POINT_R = '@@tradePoints/CREATE_NEW_TRADE_POINT_R',
  CREATE_NEW_TRADE_POINT_S = '@@tradePoints/CREATE_NEW_TRADE_POINT_S',
  CREATE_NEW_TRADE_POINT_E = '@@tradePoints/CREATE_NEW_TRADE_POINT_E',

  UPDATE_TRADE_POINT_R = '@@tradePoints/UPDATE_TRADE_POINT_R',
  UPDATE_TRADE_POINT_S = '@@tradePoints/UPDATE_TRADE_POINT_S',
  UPDATE_TRADE_POINT_E = '@@tradePoints/UPDATE_TRADE_POINT_E',

  DELETE_TRADE_POINT_R = '@@tradePoints/DELETE_TRADE_POINT_R',
  DELETE_TRADE_POINT_S = '@@tradePoints/DELETE_TRADE_POINT_S',
  DELETE_TRADE_POINT_E = '@@tradePoints/DELETE_TRADE_POINT_E',
}
