import React, { useEffect, useState } from 'react'
import { Button, Col, message, Row, Typography } from 'antd'
import { useParams } from 'react-router'
import { CallImage } from '../asserts/images/CallImage'
import { HumanImage } from '../asserts/images/HumanImage'
import { useSocketMakeCall } from '../hooks/useSocketMakeCall'
import { MicrophoneIcon } from '../asserts/svg/MicrophoneIcon'
import { SwapCameraIcon } from '../asserts/svg/SwapCameraIcon'
import { ScanBarcodeIcon } from '../asserts/svg/ScanBarcodeIcon'
import { EndCallIcon } from '../asserts/svg/EndCallIcon'
import { MicrophoneEndIcon } from '../asserts/svg/MicrophoneEndIcon'
import { MiniCamera } from '../components/makeCall/MiniCamera'
// import { PhoneIcon } from '../asserts/svg/PhoneIcon'
// import { ChooseSoundType } from '../components/makeCall/ChooseSoundType'
// import { HeadphonesIcon } from '../asserts/svg/HeadphonesIcon'
// import { changeAudioOutput } from '../utils/utils'
import { SpeakerIcon } from '../asserts/svg/SpeakerIcon'
import { SpeakerMutedIcon } from '../asserts/svg/SpeakerMutedIcon'
import { ContainerSimple } from '../wrappers/ContainerSimple'

interface MainCallProps {}

export const MakeCall: React.FC<MainCallProps> = () => {
  const { tradePointId, retailerTagId } = useParams<any>()

  const {
    onOnline,
    onOffline,

    isCalling,
    callTime,
    callIsStarted,
    microphone,
    connecting,
    callIsFinished,
    isBackCamera,

    finishCall,
    turnMicrophone,
    changeCamera,

    localVideoRef,
    remoteVideoRef,
    remoteSoundRef,
  } = useSocketMakeCall(tradePointId, retailerTagId)

  const [waitingTime, setWaitingTime] = useState(0)
  // const [soundType, setSoundType] = useState<'speaker' | 'phone' | 'mute' | 'headphones'>('speaker')
  // const [chooseSoundVisible, setChooseSoundVisible] = useState<boolean>(false)
  const [muted, setMuted] = useState<boolean>(false)
  // const [audioOutputs, setAudioOutputs] = useState<MediaDeviceInfo[]>([])

  useEffect(() => {
    onOnline()
  }, [onOnline])

  useEffect(() => {
    if (connecting) {
      const hide = message.loading('Connecting...', 0)
      return () => hide()
    }
  }, [connecting])

  useEffect(() => {
    if (!isCalling) {
      const interval = setInterval(() => setWaitingTime((prev) => prev + 1), 1000)
      return () => {
        setWaitingTime(0)
        clearInterval(interval)
      }
    }
  }, [isCalling])

  // useEffect(() => {
  //   getAudioOutputs().then((res) => setAudioOutputs(res))
  // }, [])

  // const changeSoundOutput = async (type: 'speaker' | 'phone' | 'mute' | 'headphones') => {
  //   if (remoteSoundRef.current) await changeAudioOutput(type, remoteSoundRef.current)
  // }

  if (callIsFinished) {
    return (
      <ContainerSimple>
        <Row justify='center' align='middle' style={{ height: '100%' }}>
          <Col span={20}>
            <Typography.Title level={1} style={{ textAlign: 'center' }}>
              Thank you for your call.
            </Typography.Title>
          </Col>
        </Row>
      </ContainerSimple>
    )
  }

  if (callIsStarted) {
    return (
      <ContainerSimple>
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
          <div className='mk-top-container'>
            <Row justify='space-between' align='middle' style={{ height: '100%' }}>
              <Col>
                <Button size='large' type='text' shape='circle' style={{ padding: '4px' }}>
                  <ScanBarcodeIcon />
                </Button>
              </Col>
              <Col style={{ textAlign: 'center' }}>
                <Typography.Text style={{ color: '#fff' }}>Consultant</Typography.Text>
                <br />
                <Typography.Text style={{ color: 'rgba(255,255,255,0.8)', fontSize: 12 }}>
                  {new Date(callTime * 1000).toUTCString().split(' ')[4]}
                </Typography.Text>
              </Col>
              <Col>
                <Button size='large' type='text' shape='circle' style={{ padding: '4px' }} onClick={changeCamera}>
                  <SwapCameraIcon />
                </Button>
              </Col>
            </Row>
          </div>
          <video
            width='100%'
            height='100%'
            ref={remoteVideoRef}
            style={{ objectFit: 'cover', transform: 'rotateY(180deg)' }}
            autoPlay
            playsInline
            muted
          />
          <audio ref={remoteSoundRef} autoPlay playsInline muted={muted} />
          <div style={{ position: 'absolute', top: 60, right: 10 }}>
            <MiniCamera videoRef={localVideoRef} muted={!microphone} turned={!isBackCamera} />
          </div>
          <div className='mk-bottom-container'>
            <Row justify='space-between' align='middle' style={{ height: '100%' }}>
              <Col>
                <Button
                  size='large'
                  type='text'
                  shape='circle'
                  style={{ padding: '4px' }}
                  onClick={() => setMuted(!muted)}
                >
                  {muted ? <SpeakerMutedIcon /> : <SpeakerIcon />}
                  {/* {soundType === 'speaker' && <SpeakerIcon />} */}
                  {/* {soundType === 'phone' && <PhoneIcon />} */}
                  {/* {soundType === 'mute' && <SpeakerMutedIcon />} */}
                  {/* {soundType === 'headphones' && <HeadphonesIcon />} */}
                </Button>
              </Col>
              <Col>
                <Button size='large' type='text' shape='circle' style={{ padding: '4px' }} onClick={finishCall}>
                  <EndCallIcon style={{ borderRadius: '40%' }} />
                </Button>
              </Col>
              <Col>
                <Button size='large' type='text' shape='circle' style={{ padding: '4px' }} onClick={turnMicrophone}>
                  {microphone ? <MicrophoneIcon /> : <MicrophoneEndIcon />}
                </Button>
              </Col>
            </Row>
          </div>
          {/* <div style={{ position: 'fixed', zIndex: 1000, bottom: 0, left: 0, right: 0 }}> */}
          {/*   <ChooseSoundType */}
          {/*     soundType={soundType} */}
          {/*     setSoundType={setSoundType} */}
          {/*     changeSoundOutput={changeSoundOutput} */}
          {/*     visible={chooseSoundVisible} */}
          {/*     setVisible={setChooseSoundVisible} */}
          {/*   /> */}
          {/* </div> */}
        </div>
      </ContainerSimple>
    )
  }

  return (
    <ContainerSimple>
      <Row align='middle' gutter={[0, 24]} style={{ height: '100%' }}>
        <Col span={24}>
          <Row justify='center'>
            <Col>
              <Typography.Title level={5} style={{ textAlign: 'center' }}>
                Remote consultant
              </Typography.Title>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify='center'>
            <Col span={22}>
              <Row justify='center' style={{ margin: '20px 0' }}>
                <Col>{!isCalling ? <HumanImage /> : <CallImage />}</Col>
              </Row>
              <Row justify='center' style={{ margin: '20px 0' }}>
                <Col>
                  {!isCalling ? (
                    <>
                      <Typography.Title level={3} style={{ textAlign: 'center', marginBottom: 10 }}>
                        We are looking for a free consultant for you, will connect you as soon as possible, please wait
                      </Typography.Title>
                      <Typography.Title level={2} style={{ textAlign: 'center', margin: 0 }}>
                        {new Date(waitingTime * 1000).toUTCString().split(' ')[4].substring(3)} min.
                      </Typography.Title>
                    </>
                  ) : (
                    <Typography.Title level={3} style={{ textAlign: 'center' }}>
                      Please wait 30 sec., we are connecting video call with a consultant
                    </Typography.Title>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify='center'>
            <Col>
              {!isCalling && (
                <Button disabled={connecting} type='primary' size='large' onClick={() => onOffline()}>
                  Cancel consultation
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </ContainerSimple>
  )
}
