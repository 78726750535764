import React, { CSSProperties } from 'react'
import { Col, Layout, Row } from 'antd'

interface ContainerSimpleProps {
  children: React.ReactNode
  style?: CSSProperties
}

export const ContainerSimple: React.FC<ContainerSimpleProps> = ({ children, style }) => {
  return (
    <Layout>
      <Layout.Content style={{ overflow: 'hidden', ...style }}>
        <Row className='container-simple-main'>
          <Col span={24}>{children}</Col>
        </Row>
      </Layout.Content>
    </Layout>
  )
}
