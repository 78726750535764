/* eslint-disable no-console */
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { SetQRCodeData } from '../store/myself/actions'
import { STUN2 } from '../utils/consts'

interface CallViaFrontProps {
  onRelaySdp: (peerID: string, sessionDescription: RTCSessionDescriptionInit) => void
  onRelayIce: (peerID: string, iceCandidate: RTCIceCandidate) => void
  setConnecting: (value: boolean) => void
  setCallIsFinished: (value: boolean) => void
  setCallIsStarted: (value: boolean) => void
  setCallTime: React.Dispatch<React.SetStateAction<number>>
  sendCallDuration: (value: number) => void
  localMediaStream: React.MutableRefObject<MediaStream | null>
}

export const useCallViaFront = ({
  onRelaySdp,
  onRelayIce,
  setCallIsFinished,
  setCallIsStarted,
  localMediaStream,
  setCallTime,
  sendCallDuration,
}: CallViaFrontProps) => {
  const dispatch = useDispatch()

  const peerConnections = useRef<{ [key: string]: RTCPeerConnection }>({})

  const remoteVideoFrontRef = useRef<HTMLVideoElement>(null)
  const remoteSoundFrontRef = useRef<HTMLAudioElement>(null)

  const getTime = (): Promise<number> =>
    new Promise((resolve) =>
      setCallTime((prev) => {
        resolve(prev)
        return prev
      })
    )

  const finishCall = () => {
    dispatch(SetQRCodeData(null))
    setCallIsFinished(true)
    setCallIsStarted(false)
    // eslint-disable-next-line no-restricted-syntax
    for (const [, value] of Object.entries(peerConnections.current)) value.close()
    localMediaStream.current?.getTracks().forEach((track) => track.stop())

    getTime().then((val) => {
      sendCallDuration(val)
      setCallTime(0)
    })
  }

  const onWebRTCConnectionChange = (pc: any) => {
    console.log('pc.target.connectionState', pc.target.connectionState)
    switch (pc.target.connectionState) {
      case 'new':
      case 'connected':
      case 'failed':
        break
      case 'disconnected':
      case 'closed':
        finishCall()
        break
    }
  }

  const addNewPeer = async ({ peerID, createOffer }: { peerID: string; createOffer: boolean }) => {
    try {
      peerConnections.current[peerID] = new RTCPeerConnection({ iceServers: STUN2 })

      console.log('onAddPeer', peerConnections.current[peerID].connectionState)
      peerConnections.current[peerID].onicecandidate = (event: RTCPeerConnectionIceEvent) => {
        if (event.candidate) onRelayIce(peerID, event.candidate)
      }

      peerConnections.current[peerID].onconnectionstatechange = onWebRTCConnectionChange

      let tracksNumber: number = 0
      peerConnections.current[peerID].ontrack = ({ streams: [remoteStream] }: RTCTrackEvent) => {
        tracksNumber += 1
        if (tracksNumber === 2) {
          // video & audio tracks received
          tracksNumber = 0
          remoteVideoFrontRef.current!.srcObject = remoteStream
          if (remoteSoundFrontRef.current) remoteSoundFrontRef.current.srcObject = remoteStream
        }
      }
      localMediaStream.current?.getTracks().forEach((track) => {
        peerConnections.current[peerID].addTrack(track, localMediaStream.current!)
      })
      if (createOffer) {
        // const transceivers = peerConnections.current[peerID]?.getTransceivers()
        // const video = transceivers.find((item) => item.receiver.track.kind === 'video')
        // const audio = transceivers.find((item) => item.receiver.track.kind === 'audio')
        //
        // if (RTCRtpReceiver?.getCapabilities) {
        //   const codecs = RTCRtpReceiver?.getCapabilities('video')?.codecs.filter((item) =>
        //     item.mimeType.includes('VP8')
        //   )
        //   const codecsAudio = RTCRtpReceiver?.getCapabilities('audio')?.codecs.filter((item) =>
        //     item.mimeType.includes('opus')
        //   )
        //
        //   video?.setCodecPreferences(codecs!)
        //   audio?.setCodecPreferences(codecsAudio!)
        // }

        // peerConnections.current[peerID]?.getTransceivers().forEach((item) => {
        //   if (item.sender.track) peerConnections.current[peerID].removeTrack(item.sender)
        // })
        //
        // if (video?.sender.track) peerConnections.current[peerID].addTransceiver(video.sender.track)
        // if (audio?.sender.track) peerConnections.current[peerID].addTransceiver(audio.sender.track)

        console.log('onAddPeer', peerConnections.current[peerID].connectionState)

        const offer: RTCSessionDescriptionInit = await peerConnections.current[peerID].createOffer()
        await peerConnections.current[peerID].setLocalDescription(offer)
        console.log('onAddPeer', peerConnections.current[peerID].connectionState)

        onRelaySdp(peerID, offer)
      }
      return true
    } catch (e) {
      return console.error('onAddPeer', e)
    }
  }

  const sendRemoteMediaViaFront = async ({ peerID, sessionDescription }: any, deep = 0) => {
    try {
      const session: RTCSessionDescriptionInit = sessionDescription
      await peerConnections.current[peerID].setRemoteDescription(new RTCSessionDescription(session))

      if (session.type === 'offer') {
        // const transceivers = peerConnections.current[peerID]?.getTransceivers()
        // const video = transceivers?.find((item) => item.receiver.track?.kind === 'video')
        // const audio = transceivers?.find((item) => item.receiver.track?.kind === 'audio')
        //
        // if (RTCRtpReceiver?.getCapabilities) {
        //   const codecs = RTCRtpReceiver.getCapabilities('video')?.codecs.filter((item) => item.mimeType.includes('VP8'))
        //   const codecsAudio = RTCRtpReceiver.getCapabilities('audio')?.codecs.filter((item) =>
        //     item.mimeType.includes('opus')
        //   )
        //
        //   video?.setCodecPreferences(codecs!)
        //   audio?.setCodecPreferences(codecsAudio!)
        // }

        // peerConnections.current[peerID]?.getTransceivers().forEach((item) => {
        //   if (item.sender.track) peerConnections.current[peerID].removeTrack(item.sender)
        // })
        //
        // if (video?.sender.track) peerConnections.current[peerID].addTransceiver(video.sender.track)
        // if (audio?.sender.track) peerConnections.current[peerID].addTransceiver(audio.sender.track)

        const answer = await peerConnections.current[peerID].createAnswer()
        await peerConnections.current[peerID].setLocalDescription(answer)
        onRelaySdp(peerID, answer)
      }
    } catch (e) {
      console.warn('onSetRemoteMedia', e)
      if (deep < 30) {
        setTimeout(() => sendRemoteMediaViaFront({ peerID, sessionDescription }, deep + 1), 1000)
      }
    }
  }

  const onIceCandidate = async ({ peerID, iceCandidate }: any, deep = 0) => {
    try {
      await peerConnections.current[peerID]?.addIceCandidate(new RTCIceCandidate(iceCandidate))
    } catch (e) {
      if (deep < 30) setTimeout(async () => onIceCandidate({ peerID, iceCandidate }, deep + 1), 1000)
      console.warn('onIceCandidate', e)
    }
  }

  return {
    finishCall,
    addNewPeer,
    sendRemoteMediaViaFront,
    onIceCandidate,
    remoteVideoFrontRef,
    remoteSoundFrontRef,
    peerConnections,
  }
}
