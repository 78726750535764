/* eslint-disable prefer-promise-reject-errors */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Form, Input, Modal, Typography, Row, Col, Select } from 'antd'
import { Container } from '../wrappers/Container'
import { ConsultantsTable } from '../components/consultants/ConsultantsTable'
import { GetUsers, UpdateUserPassword } from '../store/users/actions'
import { AppStore } from '../store/applicationState'
import { ROUTES } from '../utils/routes'
import { TUser } from '../store/users/types'
import { RequiredMark } from '../types/actions'
import { TRetailerTag } from '../store/retailerTags/types'
import { snakeCaseToString } from '../utils/helpers'
import { GetRetailerTags } from '../store/retailerTags/actions'
import { GetTradePoints } from '../store/tradePoints/actions'
import { TTradePoint } from '../store/tradePoints/types'

interface ConsultantsViewProps {}

export const ConsultantsListView: React.FC<ConsultantsViewProps> = () => {
  const history = useHistory()

  const dispatch = useDispatch()
  const { Users, RetailerTags, TradePoints } = useSelector((store: AppStore) => store)

  const [form] = Form.useForm()
  const [modalForm] = Form.useForm()
  const pagination = useRef<{ start: number; limit: number }>({ start: 0, limit: 10 })
  const currentPage = Math.round(pagination.current.start / pagination.current.limit + 1)

  const [requiredModalMark, setRequiredModalMark] = useState<RequiredMark>('optional')
  const [modalData, setModalData] = useState<TUser | null>(null)

  useEffect(() => {
    form.submit()
    dispatch(GetRetailerTags.request())
    dispatch(GetTradePoints.request())
  }, [])

  const onRequiredModalTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
    setRequiredModalMark(requiredMarkValue)
  }

  const onPageClick = (page: number, pageSize: number) => {
    pagination.current.start = (page - 1) * pagination.current.limit
    pagination.current.limit = pageSize
    form.submit()
  }

  const onValuesChange = () => {
    pagination.current.start = 0
    form.submit()
  }

  const handleOk = () => modalForm.submit()

  const handleCancel = () => setModalData(null)

  const onChangePass = (data: TUser) => setModalData(data)

  const onFinishModal = (values: any) => {
    if (modalData)
      dispatch(
        UpdateUserPassword.request({
          user_id: modalData.id,
          new_password: values.newPassword,
          confirm_new_password: values.repeatNewPassword,
        })
      )
    setModalData(null)
    form.resetFields()
  }

  const onFinishSearch = (values: any) => {
    dispatch(
      GetUsers.request({
        limit: pagination.current.limit,
        offset: pagination.current.start,
        q: values.search,
        trade_points: values.tradePoints,
        tags: values.retailerTags,
      })
    )
  }

  const onReset = () => {
    form.resetFields()
    form.submit()
  }

  return (
    <Container>
      <Row className='pageTitle'>
        <Typography.Title level={3}>Consultants</Typography.Title>
        <div className='results'>
          {Users?.consultantsData?.total} {Users?.consultantsData?.total === 1 ? 'result' : 'results'}
        </div>
      </Row>
      <Row gutter={[0, 12]} style={{ padding: '20px 15px' }}>
        <Col lg={7} xs={24}>
          <Button size='large' type='primary' onClick={() => history.push(ROUTES.createUser)}>
            Add consultant +
          </Button>
        </Col>
        <Col lg={17} xs={24}>
          <Form
            form={form}
            layout='vertical'
            onFinish={onFinishSearch}
            onValuesChange={(data) => (data?.search ? null : onValuesChange())}
            style={{ marginTop: '10px' }}
          >
            <Row gutter={[0, 12]} justify='space-between'>
              <Col md={10} xs={24}>
                <Form.Item name='search' noStyle>
                  <Input.Search
                    placeholder='Search consultant'
                    allowClear
                    enterButton='Search'
                    size='middle'
                    onSearch={onValuesChange}
                  />
                </Form.Item>
              </Col>
              <Col md={5} sm={12} xs={24}>
                <Form.Item name='tradePoints' noStyle>
                  <Select placeholder='Shop' bordered={false} showArrow mode='multiple' style={{ width: '100%' }}>
                    {TradePoints.data.trade_points?.map((option: TTradePoint) => (
                      <Select.Option key={option.id} value={option.id}>
                        {snakeCaseToString(option.name)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={5} sm={12} xs={24}>
                <Form.Item name='retailerTags' noStyle>
                  <Select placeholder='Department' bordered={false} showArrow mode='multiple' style={{ width: '100%' }}>
                    {RetailerTags?.data?.RetailerTagInformation?.map((option: TRetailerTag) => (
                      <Select.Option key={option.id} value={option.id}>
                        {snakeCaseToString(option.name)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={2} xs={24} className='reset-btn-container'>
                <Form.Item noStyle>
                  <Button size='small' type='text' onClick={onReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <ConsultantsTable
        onPageClick={onPageClick}
        users={Users.consultantsData?.users || []}
        loading={Users.loading}
        onChangePass={onChangePass}
        currentPage={currentPage}
        pagination={pagination}
        total={Users.consultantsData.total}
      />
      <Modal title='Reset password' open={!!modalData} onOk={handleOk} onCancel={handleCancel}>
        <Form
          form={modalForm}
          layout='vertical'
          onFinish={onFinishModal}
          initialValues={{ requiredMarkValue: requiredModalMark }}
          onValuesChange={onRequiredModalTypeChange}
          requiredMark={requiredModalMark}
        >
          <Form.Item
            name='newPassword'
            label='New Password'
            required
            rules={[{ required: true, message: 'Please input users New Password!' }]}
          >
            <Input.Password size='large' />
          </Form.Item>
          <Form.Item
            name='repeatNewPassword'
            label='Repeat New Password'
            required
            rules={[
              {
                required: true,
                message: 'Please repeat users New Password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) return Promise.resolve()
                  return Promise.reject('The two passwords that you entered do not match!')
                },
              }),
            ]}
          >
            <Input.Password size='large' />
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  )
}
