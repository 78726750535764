import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ActionTypes, TRetailerTagMessage, TRetailerTagsData } from './types'
import { callApi } from '../../utils/callApi'
import { API_ROUTES } from '../../utils/apiRoutes'
import * as Actions from './actions'
import { getQueryFromObj } from '../../utils/helpers'

function* getRetailerTagsWorker(action: ReturnType<typeof Actions.GetRetailerTags.request>) {
  const { callBack, query } = action.payload as Actions.TypeGetRetailerTagsR
  let success: boolean = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.retailerTags}${getQueryFromObj(query || {})}`,
    })) as TRetailerTagsData | TRetailerTagMessage

    yield put(Actions.GetRetailerTags.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetRetailerTags.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getRetailerTagById(action: ReturnType<typeof Actions.GetRetailerTagById.request>) {
  const { callBack, id } = action.payload as Actions.TypeGetRetailerTaById
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.retailerTags}/${id}`,
    })) as TRetailerTagMessage

    yield put(Actions.GetRetailerTagById.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetRetailerTagById.error(`${data?.detail || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* createNewRetailerTag(action: ReturnType<typeof Actions.CreateNewRetailerTag.request>) {
  const { body, callBack } = action.payload as Actions.TypeCreateNewRetailerTag
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.retailerTags,
      data: body,
    })) as TRetailerTagMessage

    yield put(Actions.CreateNewRetailerTag.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.CreateNewRetailerTag.error(`${data?.detail || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* updateRetailerTag(action: ReturnType<typeof Actions.UpdateRetailerTag.request>) {
  const { callBack, body, id } = action.payload as Actions.TypeUpdateRetailerTag
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'put',
      path: `${API_ROUTES.retailerTags}/${id}`,
      data: body,
    })) as TRetailerTagMessage

    yield put(Actions.UpdateRetailerTag.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.UpdateRetailerTag.error(`${data?.detail || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* deleteRetailerTag(action: ReturnType<typeof Actions.DeleteRetailerTag.request>) {
  const { callBack, id } = action.payload as Actions.TypeDeleteRetailerTag
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'delete',
      path: `${API_ROUTES.retailerTags}/${id}`,
    })) as TRetailerTagMessage

    yield put(Actions.DeleteRetailerTag.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.DeleteRetailerTag.error(`${data?.detail || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_RETAILER_TAGS_R, getRetailerTagsWorker)
  yield takeEvery(ActionTypes.GET_RETAILER_TAG_BY_ID_R, getRetailerTagById)
  yield takeEvery(ActionTypes.CREATE_NEW_RETAILER_TAG_R, createNewRetailerTag)
  yield takeEvery(ActionTypes.UPDATE_RETAILER_TAG_R, updateRetailerTag)
  yield takeEvery(ActionTypes.DELETE_RETAILER_TAG_R, deleteRetailerTag)
}

export default function* tradePointSaga() {
  yield all([fork(watchRequest)])
}
