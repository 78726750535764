import { Actions } from '../../utils/Action'
import { TDefRequest } from '../../types/actions'
import { ActionTypes } from './types'

/// ///////////////////////////////////////////////////////////////////////
const GetCallsActions = new Actions('GET_CALLS', ActionTypes)

export interface TypeGetCallsR extends TDefRequest {
  query?: {
    limit?: number
    offset?: number
    q?: string
    order?: 'asc' | 'desc'
    filter_date_from?: string
    filter_date_to?: string
    filter_time_from?: string
    filter_time_to?: string
    filter_trade_point_id?: string[]
    filter_tag_id?: string[]
    filter_status?: string[]
  }
}

export const GetCalls = {
  request: (payload?: TypeGetCallsR) => GetCallsActions.request(payload || {}),
  success: (payload: any) => GetCallsActions.success(payload),
  error: (payload: string) => GetCallsActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetCallStatusesActions = new Actions('GET_CALL_STATUSES', ActionTypes)

export interface TypeGetCallStatusesR extends TDefRequest {}

export const GetCallStatuses = {
  request: (payload?: TypeGetCallStatusesR) => GetCallStatusesActions.request(payload || {}),
  success: (payload: any) => GetCallStatusesActions.success(payload),
  error: (payload: string) => GetCallStatusesActions.error(payload),
}
