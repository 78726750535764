import React from 'react'
import { Col, Row } from 'antd'
import { useIntl } from 'react-intl'
import { GreenBlur } from '../GreenBlur'
import { LandingLaptopImage } from '../../../asserts/images/LandingLaptopImage'
import { LandingIphoneImage } from '../../../asserts/images/LandingPhoneImage'

export const MainBlock1 = () => {
  const intl = useIntl()

  return (
    <Row
      justify='center'
      className='pt-100 pt-md-120 pb-50 br-bottom-20 br-bottom-md-0'
      style={{ background: '#0D181E', position: 'relative' }}
    >
      <Col xl={20} xs={22} style={{ zIndex: 100 }}>
        <Row gutter={[20, 0]} align='middle' style={{ marginBottom: 20 }}>
          <Col md={0} xs={8}>
            <div className='iphone-shadow'>
              <LandingIphoneImage width='100%' height='auto' />
            </div>
          </Col>
          <Col md={0} xs={16}>
            <div className='laptop-shadow'>
              <LandingLaptopImage width='150%' height='auto' />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className='landing-text-100 mb-10 mb-md-60'>Remote consultant</p>
          </Col>
        </Row>
        <Row>
          <Col lg={10} md={14} xs={24}>
            <p className='landing-text-28-thin mb-20 mb-md-60'>
              {intl.formatMessage({
                id: 'A new approach to the remote work of consultants and the provision of information services.',
              })}
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={8} xs={24}>
            <button
              type='button'
              className='default-landing-btn mb-20 mb-md-210'
              style={{ width: '100%' }}
              onClick={() => {
                window.location.href = '#contact-us'
              }}
            >
              {intl.formatMessage({ id: 'Book Your Free Demo' })}
            </button>
          </Col>
        </Row>
      </Col>
      <Col xl={20} md={22} xs={24} style={{ zIndex: 100 }}>
        <Row>
          <Col span={24}>
            <div className='main-blur-container' style={{ borderRadius: 20, position: 'relative' }}>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '18%' }}>
                      <div
                        style={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          background: '#fff',
                        }}
                      />
                      <div style={{ width: 'calc(100% - 12px)', height: 1, background: '#fff' }} />
                    </div>
                    <p className='landing-text-16' style={{ marginLeft: 16 }}>
                      {intl.formatMessage({ id: 'INFO' })}
                    </p>
                  </div>
                </Col>
                <Col span={24}>
                  <p className='landing-text-28-thin'>
                    {intl.formatMessage({
                      id: 'Today, the topic of remote work of employees is relevant for many companies',
                    })}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
      <div className='iphone-container' style={{ top: 440, right: 620, zIndex: 10 }}>
        <LandingIphoneImage />
      </div>
      <div className='laptop-container' style={{ top: 210, right: -400, zIndex: 10 }}>
        <LandingLaptopImage />
      </div>
      <GreenBlur style={{ top: 100, right: 0 }} size='big' />
      <GreenBlur className='hidden block-md' style={{ top: 500, left: 0 }} size='big' />

      <GreenBlur className='hidden-md' style={{ top: 240, left: 0 }} />
      <GreenBlur className='hidden-md' style={{ top: 500, right: 0 }} />
      <GreenBlur className='hidden-md' style={{ bottom: 0, left: 0 }} />
    </Row>
  )
}
