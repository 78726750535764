import React from 'react'
import { Col, Row } from 'antd'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { GreenBlur } from '../GreenBlur'
import { DoneIcon } from '../../../asserts/svg/DoneIcon'
import { SumIcon } from '../../../asserts/svg/MainBlock10/SumIcon'
import { SalesIcon } from '../../../asserts/svg/MainBlock10/SalesIcon'
import { OverflowHidden } from '../OverflowHidden'
import { Line } from '../Line'
import { AppStore } from '../../../store/applicationState'

export const MainBlock10 = () => {
  const intl = useIntl()
  const { General } = useSelector((store: AppStore) => store)

  return (
    <Row
      justify='center'
      className='pt-md-100 pb-md-80 pb-60 pt-20 b-20 b-md-0 mt--20 mt-md-0 mb-40 mb-md-0'
      style={{ position: 'relative', background: '#0D181E', zIndex: 2 }}
    >
      <Col xl={20} xs={22} style={{ zIndex: 100 }} id='pricing'>
        <Row style={{ marginBottom: 30 }}>
          <Col md={0} xs={24} className='mb-20'>
            <Line style={{ marginLeft: 0, justifyContent: 'flex-start', width: '35%' }}>
              {intl.formatMessage({ id: 'Price list' })}
            </Line>
          </Col>
          <Col md={15} xs={24}>
            <p className='landing-text-36 text-align-center text-align-md-left'>
              {intl.formatMessage({ id: 'Pricing' })}
            </p>
          </Col>
          <Col md={9} xs={0}>
            <Line>{intl.formatMessage({ id: 'Price list' })}</Line>
          </Col>
        </Row>
      </Col>
      <Col xl={20} md={22} xs={24} style={{ zIndex: 100 }}>
        <Row justify='center'>
          <Col xl={19} lg={20} xs={24}>
            <Row justify='space-around' gutter={[0, 20]}>
              <Col md={11} xs={24} className='pricing-container'>
                <Row style={{ height: '100%' }}>
                  <Col span={24}>
                    <Row>
                      <Col span={24} className='pricing-sub-container' style={{ marginBottom: 34 }}>
                        <Row gutter={[16, 0]} align='middle'>
                          <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                            <SumIcon
                              locale={General.locale}
                              width='100%'
                              height='100%'
                              style={{ aspectRatio: '6/5' }}
                            />
                          </Col>
                          <Col span={16}>
                            <Row gutter={[0, 8]}>
                              <Col span={24}>
                                <p className='landing-text-14' style={{ fontSize: 15, fontWeight: 400 }}>
                                  {intl.formatMessage({ id: 'CLOUD' })}
                                </p>
                              </Col>
                              <Col span={24}>
                                <p className='landing-text-24'>
                                  {' '}
                                  {intl.formatMessage({ id: 'Up to 10 Consultants' })}{' '}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} style={{ padding: 10 }}>
                        <Row align='middle' style={{ marginBottom: 24 }}>
                          <Col span={3}>
                            <DoneIcon style={{ marginTop: 3 }} />
                          </Col>
                          <Col span={17}>
                            <p className='landing-text-22'>{intl.formatMessage({ id: 'Free trial' })} </p>
                          </Col>
                          <Col span={4}>
                            <p className='landing-text-14'>1 {intl.formatMessage({ id: 'Month' })}</p>
                          </Col>
                        </Row>
                        <Row align='middle' style={{ marginBottom: 24 }}>
                          <Col span={3}>
                            <DoneIcon style={{ marginTop: 3 }} />
                          </Col>
                          <Col span={17}>
                            <p className='landing-text-22'>
                              {intl.formatMessage({ id: 'Access to the basic functionality' })}
                            </p>
                          </Col>
                        </Row>
                        <Row align='middle' style={{ marginBottom: 24 }}>
                          <Col span={3}>
                            <DoneIcon style={{ marginTop: 3 }} />
                          </Col>
                          <Col span={17}>
                            <p className='landing-text-22'>
                              {intl.formatMessage({ id: 'Standard calls without a record' })}
                            </p>
                          </Col>
                        </Row>
                        <Row align='middle' style={{ marginBottom: 24 }}>
                          <Col span={3}>
                            <DoneIcon style={{ marginTop: 3 }} />
                          </Col>
                          <Col span={17}>
                            <p className='landing-text-22'>{intl.formatMessage({ id: 'Сall statistics' })}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} style={{ padding: 10 }}>
                    <Row style={{ height: '100%' }} align='bottom'>
                      <Col span={24}>
                        <button
                          type='submit'
                          className='secondary-landing-btn'
                          style={{ width: '100%' }}
                          onClick={() => {
                            window.location.href = '#contact-us'
                          }}
                        >
                          {intl.formatMessage({ id: 'Place your order' })}
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col md={11} xs={24} className='pricing-container' style={{ background: 'rgba(183, 232, 174, 0.2)' }}>
                <Row style={{ height: '100%' }}>
                  <Col span={24}>
                    <Row>
                      <Col span={24} className='pricing-sub-container' style={{ marginBottom: 34 }}>
                        <Row gutter={[16, 0]} align='middle'>
                          <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
                            <SalesIcon
                              locale={General.locale}
                              width='100%'
                              height='100%'
                              style={{ aspectRatio: '6/5' }}
                            />
                          </Col>
                          <Col span={16}>
                            <Row gutter={[0, 8]}>
                              <Col span={24}>
                                <p className='landing-text-14' style={{ fontSize: 15, fontWeight: 400 }}>
                                  {intl.formatMessage({ id: 'ENTERPRISE' })}
                                </p>
                              </Col>
                              <Col span={24}>
                                <p className='landing-text-24'>
                                  {' '}
                                  {intl.formatMessage({ id: 'Unlimited Consultants' })}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} style={{ padding: 10 }}>
                        <Row align='middle' style={{ marginBottom: 24 }}>
                          <Col span={3}>
                            <DoneIcon style={{ marginTop: 3 }} />
                          </Col>
                          <Col span={17}>
                            <p className='landing-text-22'>{intl.formatMessage({ id: 'Free trial' })}</p>
                          </Col>
                          <Col span={4}>
                            <p className='landing-text-14'>1 {intl.formatMessage({ id: 'Month' })}</p>
                          </Col>
                        </Row>
                        <Row align='middle' style={{ marginBottom: 24 }}>
                          <Col span={3}>
                            <DoneIcon style={{ marginTop: 3 }} />
                          </Col>
                          <Col span={17}>
                            <p className='landing-text-22'>
                              {intl.formatMessage({
                                id: 'Access to the basic functionality of the application for unlimited',
                              })}
                            </p>
                          </Col>
                        </Row>
                        <Row align='middle' style={{ marginBottom: 24 }}>
                          <Col span={3}>
                            <DoneIcon style={{ marginTop: 3 }} />
                          </Col>
                          <Col span={17}>
                            <p className='landing-text-22'>{intl.formatMessage({ id: 'Calls with the records' })} </p>
                          </Col>
                        </Row>
                        <Row align='middle' style={{ marginBottom: 24 }}>
                          <Col span={3}>
                            <DoneIcon style={{ marginTop: 3 }} />
                          </Col>
                          <Col span={17}>
                            <p className='landing-text-22'>
                              {intl.formatMessage({ id: 'Сall statistics with access to call records' })}
                            </p>
                          </Col>
                        </Row>
                        <Row align='middle' style={{ marginBottom: 24 }}>
                          <Col span={3}>
                            <DoneIcon style={{ marginTop: 3 }} />
                          </Col>
                          <Col span={17}>
                            <p className='landing-text-22'>
                              {intl.formatMessage({ id: 'Additional functionality based on your needs' })}{' '}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} style={{ padding: 10 }}>
                    <Row style={{ height: '100%' }} align='bottom'>
                      <Col span={24}>
                        <button
                          type='submit'
                          className='secondary-landing-btn'
                          style={{ width: '100%' }}
                          onClick={() => {
                            window.location.href = '#contact-us'
                          }}
                        >
                          {intl.formatMessage({ id: 'Place your order' })}
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <GreenBlur className='hidden block-md' style={{ top: 0, left: 0 }} blur='big' />
      <GreenBlur className='hidden block-md' style={{ top: 0, right: 0 }} blur='big' />
      <OverflowHidden className='b-20 b-md-0'>
        <GreenBlur className='hidden-md' style={{ top: 0, left: 0 }} />
      </OverflowHidden>
      <OverflowHidden>
        <GreenBlur style={{ bottom: 0, right: 0 }} blur='big' />
        <GreenBlur className='hidden block-md' style={{ bottom: 0, left: 0 }} blur='big' />
      </OverflowHidden>
    </Row>
  )
}
