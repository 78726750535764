export interface TSignIn {
  access_token: string
  refresh_token: string
}

export interface TUserRoles {
  isAdmin: boolean
  isManager: boolean
  isConsultant: boolean
}

export interface TSignMessage {
  message: string
}

export interface TAuthState {
  loading: boolean
  data: TSignIn | null
  errors?: string | undefined
  user: TUserRoles | null
  authorized: boolean
}

export enum ActionTypes {
  SIGN_IN_R = '@@auth/POST_SIGN_IN_R',
  SIGN_IN_S = '@@auth/POST_SIGN_IN_S',
  SIGN_IN_E = '@@auth/POST_SIGN_IN_E',

  SIGN_OUT_R = '@@auth/DELETE_SIGN_OUT_R',
  SIGN_OUT_S = '@@auth/DELETE_SIGN_OUT_S',
  SIGN_OUT_E = '@@auth/DELETE_SIGN_OUT_E',

  SET_AUTH = '@@auth/SET_AUTH',
  SET_LOGOUT = '@@auth/SET_LOGOUT',
}
