/* eslint-disable no-console */
import axios from 'axios'
import { API_ROUTES } from './apiRoutes'
import { checkToken, refresh, ReturnType } from './checkToken'
import { API_PATH } from './consts'

export async function callApi(obj: {
  method: 'get' | 'post' | 'put' | 'delete'
  path: string
  refreshToken?: string
  data?: Object | null
  isFormData?: boolean
}): Promise<any> {
  const { method, path, data, refreshToken } = obj
  const subPath = '/api/v1'
  const mustCheckToken = ![
    API_ROUTES.getPosts,
    API_ROUTES.refreshToken,
    API_ROUTES.signIn,
    API_ROUTES.contactUs,
  ].includes(path)

  let userToken: ReturnType = {
    userId: '',
    success: true,
    token: '',
  }

  if (mustCheckToken) {
    userToken = await checkToken()
  }

  if (!userToken.success) window.location.href = '/login#sessionisexpired'

  if (refreshToken) userToken.token = refreshToken

  const request = async (access?: string): Promise<any> => {
    const tryRefreshToken = async () => {
      try {
        const resp = await refresh()
        // if (resp.success) return request(resp.token)
        if (resp.success) return window.location.reload()
        return null
      } catch (e: any) {
        return e
      }
    }

    try {
      const res = await axios.request({
        method,
        headers: {
          Authorization: `Bearer ${userToken.token}`,
          'Content-Type': 'application/json',
        },
        url: API_PATH + subPath + path,
        data: data || null,
      })
      return res.data
    } catch (e: any) {
      if (e?.response?.status === 401 || e?.response?.data?.message === 'invalid access token') {
        if (!refreshToken && !access) return tryRefreshToken()
        localStorage.clear()
        sessionStorage.clear()
        window.location.href = '/login#endofsession'
        return null
      }
      throw e?.response || e
    }
  }

  return request()
}
