import { fork } from 'redux-saga/effects'
import authSaga from './auth/sagas'
import usersSaga from './users/sagas'
import tradePointSaga from './tradePoints/sagas'
import retailerTagSaga from './retailerTags/sagas'
import mySelfSaga from './myself/sagas'
import callSaga from './calls/sagas'
import contactUsSaga from './contact-us/sagas'

export default function* rootSaga() {
  yield fork(authSaga)
  yield fork(usersSaga)
  yield fork(mySelfSaga)
  yield fork(tradePointSaga)
  yield fork(retailerTagSaga)
  yield fork(callSaga)
  yield fork(contactUsSaga)
}
