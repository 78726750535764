export interface TCall {
  id: string
  user_id: string
  retailer_tag__id: string
  trade_point_id: string
  customer_feedback: string
  customer_rating: number
  consultant_feedback: string
  consultant_rating: number
  call_duration: number
  date: string
  time: string
  status: string
  customer_waiting_time: number
  consultant_accept_call_time: number
}

export interface TCallsData {
  calls: TCall[]
  total: number
  call_statuses: string[]
}

export interface TCallsState {
  loading: boolean
  data: TCallsData
  errors?: string | undefined
}

export interface TCallMessage {
  message: string
}

export interface TCallStatusesMessage {
  message: string
}

export enum ActionTypes {
  GET_CALLS_R = '@@calls/GET_CALLS_R',
  GET_CALLS_S = '@@calls/GET_CALLS_S',
  GET_CALLS_E = '@@calls/GET_CALLS_E',

  GET_CALL_STATUSES_R = '@@calls/GET_CALL_STATUSES_R',
  GET_CALL_STATUSES_S = '@@calls/GET_CALL_STATUSES_S',
  GET_CALL_STATUSES_E = '@@calls/GET_CALL_STATUSES_E',
}
