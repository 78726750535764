import React, { CSSProperties } from 'react'

interface MaterialIconProps {
  style?: CSSProperties
  width?: number | string
  height?: number | string
}

export const MaterialIcon: React.FC<MaterialIconProps> = ({ style, width, height }) => {
  return (
    <svg
      width={width || 176}
      height={height || 176}
      style={{ ...style }}
      viewBox='0 0 176 175'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_913_40062)'>
        <rect x='0.5' width='175' height='175' rx='87.5' fill='#0D181E' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M110.454 58.2323C108.863 58.8893 100.074 62.4729 90.9242 66.1958C81.7741 69.9188 73.9146 73.1801 73.4587 73.4433C71.844 74.3758 71.1488 76.8148 72.0112 78.5221C72.3878 79.2676 81.3583 88.4379 81.7111 88.4379C81.8223 88.4379 82.9874 87.377 84.3004 86.0807C86.2052 84.1997 86.8651 83.6757 87.5659 83.4877C90.105 82.8068 92.6253 85.3286 91.9447 87.8692C91.7569 88.5704 91.2332 89.2307 89.3534 91.1367C88.0578 92.4504 86.9976 93.6163 86.9976 93.7276C86.9976 94.0659 96.1465 103.049 96.8555 103.407C97.8424 103.905 99.6759 103.843 100.681 103.277C101.778 102.66 102.108 102.041 104.56 95.9857C108.011 87.4672 110.31 81.8086 114.432 71.6879C116.587 66.3993 118.397 61.8122 118.456 61.4946C118.738 59.9757 117.618 57.8787 116.211 57.2904C114.875 56.7316 113.573 56.9446 110.454 58.2323ZM82.7651 90.5058L77.79 95.4687L76.6589 95.476C76.0367 95.4801 75.3125 95.6029 75.0494 95.7489C74.3164 96.1559 59.1006 111.412 58.7454 112.097C58.5325 112.507 58.4606 113.084 58.5201 113.906C58.5914 114.892 58.7189 115.231 59.2143 115.753C59.5481 116.104 60.0872 116.53 60.4123 116.7C61.0885 117.054 62.4709 117.103 63.231 116.802C63.7992 116.577 79.2697 101.152 79.6932 100.389C79.8379 100.128 79.9596 99.4054 79.9637 98.7827L79.971 97.651L84.9309 92.6729C89.3387 88.2491 89.8909 87.6211 89.8909 87.0323C89.8909 86.6456 89.7188 86.1977 89.4776 85.9564C89.2364 85.7151 88.7888 85.5428 88.4023 85.5428C87.8139 85.5428 87.1863 86.0954 82.7651 90.5058Z'
          fill='#489E38'
        />
        <g filter='url(#filter0_f_913_40062)'>
          <circle cx='23.125' cy='174.625' r='55.625' fill='#489E38' />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_f_913_40062'
          x='-106.5'
          y='45'
          width='259.25'
          height='259.25'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='37' result='effect1_foregroundBlur_913_40062' />
        </filter>
        <clipPath id='clip0_913_40062'>
          <rect x='0.5' width='175' height='175' rx='87.5' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
