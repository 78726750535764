import { Actions } from '../../utils/Action'
import { TDefRequest } from '../../types/actions'
import { ActionTypes } from './types'

/// ///////////////////////////////////////////////////////////////////////
const GetRetailerTagsActions = new Actions('GET_RETAILER_TAGS', ActionTypes)

export interface TypeGetRetailerTagsR extends TDefRequest {
  query?: {
    limit?: number
    offset?: number
    order?: 'asc' | 'desc'
  }
}

export const GetRetailerTags = {
  request: (payload?: TypeGetRetailerTagsR) => GetRetailerTagsActions.request(payload || {}),
  success: (payload: any) => GetRetailerTagsActions.success(payload),
  error: (payload: string) => GetRetailerTagsActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////

const GetRetailerTagByIdActions = new Actions('GET_RETAILER_TAG_BY_ID', ActionTypes)

export interface TypeGetRetailerTaById extends TDefRequest {
  id: string
}

export const GetRetailerTagById = {
  request: (payload?: TypeGetRetailerTaById) => GetRetailerTagByIdActions.request(payload || {}),
  success: (payload: any) => GetRetailerTagByIdActions.success(payload),
  error: (payload: string) => GetRetailerTagByIdActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////

const CreateNewRetailerTagActions = new Actions('CREATE_NEW_RETAILER_TAG', ActionTypes)

export interface TypeCreateNewRetailerTag extends TDefRequest {
  body: {
    name: string
  }
}

export const CreateNewRetailerTag = {
  request: (payload: TypeCreateNewRetailerTag) => CreateNewRetailerTagActions.request(payload),
  success: (payload: any) => CreateNewRetailerTagActions.success(payload),
  error: (payload: string) => CreateNewRetailerTagActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const UpdateRetailerTagActions = new Actions('UPDATE_RETAILER_TAG', ActionTypes)

export interface TypeUpdateRetailerTag extends TDefRequest {
  id: string
  body: {
    name: string
  }
}

export const UpdateRetailerTag = {
  request: (payload: TypeUpdateRetailerTag) => UpdateRetailerTagActions.request(payload),
  success: (payload: any) => UpdateRetailerTagActions.success(payload),
  error: (payload: string) => UpdateRetailerTagActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const DeleteRetailerTagActions = new Actions('DELETE_RETAILER_TAG', ActionTypes)

export interface TypeDeleteRetailerTag extends TDefRequest {
  id: string
}

export const DeleteRetailerTag = {
  request: (payload: TypeDeleteRetailerTag) => DeleteRetailerTagActions.request(payload),
  success: (payload: any) => DeleteRetailerTagActions.success(payload),
  error: (payload: string) => DeleteRetailerTagActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
