import React, { CSSProperties } from 'react'

interface OverflowHiddenProps {
  children: React.ReactNode
  style?: CSSProperties
  className?: string
}

export const OverflowHidden: React.FC<OverflowHiddenProps> = ({ children, className, style }) => {
  return (
    <div
      className={className || ''}
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflow: 'hidden',
        ...style,
      }}
    >
      {children}
    </div>
  )
}
