import React, { CSSProperties } from 'react'

interface EndCallIconProps {
  width?: number
  height?: number
  style?: CSSProperties
}

export const EndCallIcon: React.FC<EndCallIconProps> = ({ width, height, style }) => {
  return (
    <svg
      width={width || 32}
      height={height || 32}
      style={{ background: 'red', borderRadius: '50%', ...style }}
      viewBox='0 0 62 62'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.2126 0.536196C15.725 1.60244 12.0392 2.99248 8.3941 5.74871C2.74847 10.0168 -0.0147542 18.4246 5.92404e-05 31.2917C0.0227961 51.0485 6.20102 59.3658 22.5457 61.6437C25.9518 62.1183 35.679 62.119 39.1543 61.6444C55.9103 59.3569 62 51.2201 62 31.1191C62 8.66961 54.028 0.445471 31.8585 0.024702C27.4142 -0.059866 25.0885 0.0636509 22.2126 0.536196ZM38.3296 26.2671C42.2882 27.4265 45.3528 28.8389 46.9406 30.2358C48.1109 31.2655 48.2215 31.5175 48.2146 33.1398C48.2043 35.5533 47.1549 37.85 46.0621 37.85C44.8915 37.85 41.7721 36.8732 39.8853 35.9161L38.2662 35.0946L38.1628 33.107L38.0595 31.1191L36.1734 30.4926C34.8798 30.0629 33.253 29.8661 30.9973 29.8661C28.7415 29.8661 27.1148 30.0629 25.8212 30.4926L23.9351 31.1191L23.8335 33.2361L23.7322 35.3534L21.7665 36.2332C19.396 37.2947 16.8987 37.9515 15.8363 37.7934C14.0294 37.5248 12.9663 32.3272 14.3753 30.6511C15.9362 28.7937 21.7651 26.2222 26.1743 25.4455C29.3402 24.8881 34.9046 25.264 38.3296 26.2671Z'
        fill='white'
      />
    </svg>
  )
}
