import React from 'react'
import { Col, Row } from 'antd'
import { useIntl } from 'react-intl'
import landingHumanOkay from '../../../asserts/png/landing-human-okay.png'
import landingPeopleMeet from '../../../asserts/png/landing-people-meet.png'
import { GreenBlur } from '../GreenBlur'
import { PatternIcon } from '../../../asserts/svg/PatternIcon'
import { OverflowHidden } from '../OverflowHidden'

export const MainBlock2 = () => {
  const intl = useIntl()

  return (
    <>
      <Row justify='center' style={{ padding: '50px 0', position: 'relative' }}>
        <Col xl={20} xs={22} style={{ zIndex: 100 }}>
          <Row gutter={[32, { xs: 16, sm: 16, md: 84 }]} align='middle' style={{ position: 'relative' }}>
            <Col md={{ span: 12, order: 1 }} xs={{ span: 24, order: 1 }}>
              <img width='100%' loading='lazy' src={landingHumanOkay} alt='landing-human-okay' />
            </Col>
            <Col md={{ span: 12, order: 2 }} xs={{ span: 24, order: 2 }}>
              <p className='landing-text-28-thin-black'>
                {intl.formatMessage({
                  id: 'An absolutely new and promising direction of remote work is online consulting',
                })}
              </p>
            </Col>
            <Col md={{ span: 12, order: 3 }} xs={{ span: 24, order: 4 }}>
              <p className='landing-text-28-thin-black'>
                {intl.formatMessage({ id: 'For the development of this format of service' })}
              </p>
            </Col>
            <Col md={{ span: 12, order: 4 }} xs={{ span: 24, order: 3 }}>
              <img width='100%' loading='lazy' src={landingPeopleMeet} alt='landing-people-meet' />
            </Col>
            <div className='hidden block-md' style={{ position: 'absolute', left: 16, right: 16, bottom: 6 }}>
              <div style={{ display: 'flex', alignItems: 'center', width: '20%' }}>
                <div style={{ width: 12, height: 12, borderRadius: '50%', background: '#489E38' }} />
                <div style={{ width: 'calc(100% - 12px)', height: 1, background: '#489E38' }} />
              </div>
            </div>
          </Row>
        </Col>
        <GreenBlur style={{ top: 0, left: 0 }} size='big' />
        <GreenBlur className='hidden block-md' style={{ top: -40, right: 0 }} />
        <GreenBlur className='hidden-md' style={{ top: '50%', right: 0 }} />
        <OverflowHidden>
          <GreenBlur style={{ bottom: 0, left: 0 }} size='big' />
          <GreenBlur className='hidden-md' style={{ bottom: 0, right: 0 }} />
        </OverflowHidden>
      </Row>
      <Row justify='center' className='br-top-20 br-top-md-0' style={{ background: '#0D181E', position: 'relative' }}>
        <Col md={16} xs={22} style={{ zIndex: 100 }}>
          <p className='landing-text-46 py-40 py-md-80'>
            {intl.formatMessage({ id: 'The solution to such a software challenge' })}
          </p>
        </Col>
        <GreenBlur style={{ top: 0, right: 0, transform: 'translate(60%, 0)' }} blur='big' />
        <OverflowHidden className='hidden block-md'>
          <GreenBlur style={{ top: '50%', left: 0, transform: 'translate(-60%, -50%)' }} blur='big' />
          <PatternIcon style={{ position: 'absolute', right: 0, top: -60 }} />
          <PatternIcon style={{ position: 'absolute', left: 0, top: -60, transform: 'rotateY(180deg)' }} />
        </OverflowHidden>
        <GreenBlur className='hidden-md' style={{ bottom: -20, left: 0 }} />
      </Row>
    </>
  )
}
