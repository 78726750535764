import React from 'react'
import { Button, Col, Row } from 'antd'
import { useSelector } from 'react-redux'
import { ContainerSimple } from '../wrappers/ContainerSimple'
import { AppStore } from '../store/applicationState'

interface DefaultProps {}

export const Default: React.FC<DefaultProps> = () => {
  const { General } = useSelector((store: AppStore) => store)
  return (
    <ContainerSimple>
      <Row align='middle' justify='center' style={{ height: '100%' }}>
        <Col>
          <Button
            size='large'
            onClick={() => {
              window.location.href = General.locale === 'pl' ? 'https://consultant.udtech.eu/login' : '/login'
            }}
          >
            Go to login
          </Button>
        </Col>
      </Row>
    </ContainerSimple>
  )
}
