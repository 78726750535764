import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { message } from 'antd'
import { ActionTypes, TContactUs, TContactUsMessage } from './types'
import * as Actions from './actions'
import { callApi } from '../../utils/callApi'
import { API_ROUTES } from '../../utils/apiRoutes'

function* sendMessageContactUsWorker(action: ReturnType<typeof Actions.SendMessageContactUs.request>) {
  const { callBack, ...body } = action.payload as Actions.TypeSendMessageContactUsR
  let success: boolean = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.contactUs,
      data: { ...body, url: window.location.origin },
    })) as TContactUs | TContactUsMessage

    yield put(Actions.SendMessageContactUs.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.SendMessageContactUs.error(`${data?.message || data}`))
  } finally {
    if (!success) message.error(`${data?.message || data}`)
    if (callBack) callBack(success, data)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.SEND_MESSAGE_CONTACT_US_R, sendMessageContactUsWorker)
}

export default function* contactUsSaga() {
  yield all([fork(watchRequest)])
}
