export const snakeCaseToString = (str: string) => {
  return str.replace(/_/g, ' ').replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
}

export const getQueryFromObj = (query: object) => {
  return `?${Object.entries(query)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => (Array.isArray(value) ? value.map((str) => `${key}=${str}`).join('&') : `${key}=${value}`))
    .join('&')}`
}
