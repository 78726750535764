import React from 'react'
import { useDispatch } from 'react-redux'
import { Menu, Checkbox, Dropdown, Button, message } from 'antd'
import { UpOutlined } from '@ant-design/icons'
import { TTradePoint } from '../../store/tradePoints/types'
import { AddUserToTradePoint, DeleteUserFromTradePoint, GetUserById } from '../../store/users/actions'

interface AssignTradePointProps {
  userId: string
  userTradePoints?: TTradePoint[]
  tradePoints?: TTradePoint[]
  step: number
  edit: boolean
}

export const AssignTradePoint: React.FC<AssignTradePointProps> = ({
  userId,
  userTradePoints,
  tradePoints,
  step,
  edit,
}) => {
  const dispatch = useDispatch()

  const handleButtonClick = (event: any, id: string) => {
    if (event.target.checked) {
      dispatch(
        AddUserToTradePoint.request({
          body: {
            user_id: userId,
            trade_point_id: id,
          },
          callBack: (success, data) => {
            if (success) {
              dispatch(GetUserById.request({ id: userId }))
            } else {
              message.error(data.message)
            }
          },
        })
      )
    } else {
      dispatch(
        DeleteUserFromTradePoint.request({
          body: {
            user_id: userId,
            trade_point_id: id,
          },
          callBack: (success, data) => {
            if (success) {
              dispatch(GetUserById.request({ id: userId }))
            } else {
              message.error(data.message)
            }
          },
        })
      )
    }
  }

  const checkIfTradePointAdded = (tPointId: string) => {
    const tradePoint = userTradePoints?.find((tPoint) => tPoint.id === tPointId)
    return !!tradePoint
  }

  const menus =
    tradePoints?.map((tPoint: TTradePoint) => {
      return {
        label: (
          <Checkbox
            className='ant-checkbox-rtl'
            checked={checkIfTradePointAdded(tPoint.id)}
            onChange={(event) => handleButtonClick(event, tPoint.id)}
            disabled={!edit && (!userId || step === 0 || step === 2)}
          >
            {tPoint.name}
          </Checkbox>
        ),
        key: tPoint.id,
      }
    }) ?? []

  const menu = <Menu items={menus} />

  return (
    <Dropdown
      open
      overlay={menu}
      disabled={!edit && (!userId || step === 0 || step === 2)}
      overlayClassName='consultant-dropdown'
    >
      <Button size='large'>
        Select a shop <UpOutlined />
      </Button>
    </Dropdown>
  )
}
