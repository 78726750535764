import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { message } from "antd"
import { API_ROUTES } from '../../utils/apiRoutes'
import { callApi } from '../../utils/callApi'
import * as Actions from './actions'
import { ActionTypes, TSignIn, TSignMessage } from './types'
import { TOKEN } from '../../utils/consts'

function* postSignInWorker(action: ReturnType<typeof Actions.PostSignIn.request>) {
  const { callBack } = action.payload as Actions.TypePostSignInR
  let success: boolean = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.signIn,
      data: action.payload,
    })) as TSignIn | TSignMessage

    // @ts-ignore
    if (data?.access_token && data?.refresh_token) {
      // @ts-ignore
      localStorage.setItem(TOKEN.access, data.access_token)
      // @ts-ignore
      localStorage.setItem(TOKEN.refresh, data.refresh_token)
    }
    yield put(Actions.PostSignIn.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.PostSignIn.error(`${data?.message || data}`))
  } finally {
    if (!success) message.error(`${data?.message || data}`)
    if (callBack) callBack(success, data)
  }
}

function* deleteSignOutWorker(action: ReturnType<typeof Actions.DeleteSignOut.request>) {
  const { callBack } = action.payload as Actions.TypeDeleteSignOutR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'delete',
      path: API_ROUTES.signOut,
    })) as TSignMessage

    // remove tokens data
    localStorage.clear()
    window.location.href = '/login'
    yield put(Actions.DeleteSignOut.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.DeleteSignOut.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.SIGN_IN_R, postSignInWorker)
  yield takeEvery(ActionTypes.SIGN_OUT_R, deleteSignOutWorker)
}

export default function* authSaga() {
  yield all([fork(watchRequest)])
}
