/* eslint-disable prefer-promise-reject-errors */
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Col, Form, Input, Row, Tabs, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppStore } from '../store/applicationState'
import { RequiredMark } from '../types/actions'
import { Container } from '../wrappers/Container'
import { GetCurrentUserInfo, UpdateMyInfo, UpdateMyPassword } from '../store/myself/actions'

export const MyAccount = () => {
  const dispatch = useDispatch()
  const { MySelf } = useSelector((store: AppStore) => store)

  const [formAccount] = Form.useForm()
  const [formPassword] = Form.useForm()
  const [requiredMarkAccount, setRequiredMarkAccount] = useState<RequiredMark>('optional')
  const [requiredMarkPassword, setRequiredMarkPass] = useState<RequiredMark>('optional')

  useEffect(() => {
    if (MySelf.data)
      formAccount.setFieldsValue({
        firstName: MySelf.data.first_name,
        lastName: MySelf.data.last_name,
        email: MySelf.data.email,
      })
  }, [MySelf.data])

  const onRequiredTypeAccountChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
    setRequiredMarkAccount(requiredMarkValue)
  }
  const onRequiredTypePassChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
    setRequiredMarkPass(requiredMarkValue)
  }

  const onFinishAccount = (values: any) => {
    dispatch(
      UpdateMyInfo.request({
        first_name: values.firstName,
        last_name: values.lastName,
        callBack: (success) => {
          if (success) dispatch(GetCurrentUserInfo.request())
        },
      })
    )
  }

  const onFinishPass = (values: any) => {
    dispatch(
      UpdateMyPassword.request({
        old_password: values.currentPassword,
        new_password: values.newPassword,
        confirm_new_password: values.repeatNewPassword,
      })
    )
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item>Settings</Breadcrumb.Item>
        <Breadcrumb.Item>My Account</Breadcrumb.Item>
      </Breadcrumb>
      <Row style={{ height: '100%' }}>
        <Col lg={8} xs={22}>
          <Tabs defaultActiveKey='1'>
            <Tabs.TabPane tab='My Account' key='1'>
              <Row>
                <Col span={24}>
                  <Typography.Title level={3} className='pageTitle'>
                    My Account
                  </Typography.Title>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form
                    form={formAccount}
                    layout='vertical'
                    onFinish={onFinishAccount}
                    initialValues={{ requiredMarkValue: requiredMarkAccount }}
                    onValuesChange={onRequiredTypeAccountChange}
                    requiredMark={requiredMarkAccount}
                  >
                    <Form.Item
                      name='firstName'
                      label='First Name'
                      required
                      rules={[{ required: true, message: 'Please input your First Name!' }]}
                    >
                      <Input size='large' />
                    </Form.Item>
                    <Form.Item
                      name='lastName'
                      label='Last Name'
                      required
                      rules={[{ required: true, message: 'Please input your Last Name!' }]}
                    >
                      <Input size='large' />
                    </Form.Item>
                    {/* <Form.Item */}
                    {/*   name='email' */}
                    {/*   label='Email' */}
                    {/*   required */}
                    {/*   rules={[ */}
                    {/*     { required: true, message: 'Please input your Email!' }, */}
                    {/*     { type: 'email', message: 'Email is not valid!' }, */}
                    {/*   ]} */}
                    {/* > */}
                    {/*   <Input size='large' /> */}
                    {/* </Form.Item> */}
                    <Form.Item style={{ marginTop: 60 }}>
                      <Button
                        type='primary'
                        size='large'
                        loading={MySelf.loading}
                        style={{ width: '100%' }}
                        htmlType='submit'
                        autoFocus
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab='Change Password' key='2'>
              <Row>
                <Col span={24}>
                  <Typography.Title level={3}>Change Password</Typography.Title>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form
                    form={formPassword}
                    layout='vertical'
                    onFinish={onFinishPass}
                    initialValues={{ requiredMarkValue: requiredMarkPassword }}
                    onValuesChange={onRequiredTypePassChange}
                    requiredMark={requiredMarkPassword}
                  >
                    <Form.Item
                      name='currentPassword'
                      label='Current Password'
                      required
                      rules={[{ required: true, message: 'Please input your Current Password!' }]}
                    >
                      <Input.Password size='large' />
                    </Form.Item>
                    <Form.Item
                      name='newPassword'
                      label='New Password'
                      required
                      rules={[{ required: true, message: 'Please input your New Password!' }]}
                    >
                      <Input.Password size='large' />
                    </Form.Item>
                    <Form.Item
                      name='repeatNewPassword'
                      label='Repeat New Password'
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Please repeat your New Password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue('newPassword') === value) return Promise.resolve()
                            return Promise.reject('The two passwords that you entered do not match!')
                          },
                        }),
                      ]}
                    >
                      <Input.Password size='large' />
                    </Form.Item>
                    <Form.Item style={{ marginTop: 60 }}>
                      <Button
                        type='primary'
                        size='large'
                        loading={MySelf.loading}
                        style={{ width: '100%' }}
                        htmlType='submit'
                        autoFocus
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Container>
  )
}
