import React, { CSSProperties } from 'react'
import landingIphone from '../png/landing-iphone.png'

interface LandingIphoneImageProps {
  width?: number | string
  height?: number | string
  style?: CSSProperties
}

export const LandingIphoneImage: React.FC<LandingIphoneImageProps> = ({ width, height, style }) => {
  return (
    <img
      width={width || 242}
      height={height || 490}
      style={{ ...style }}
      loading='lazy'
      src={landingIphone}
      alt='landing-iphone_image'
    />
  )
}
