import React, { CSSProperties } from 'react'

interface MenuIconProps {
  style?: CSSProperties
  width?: number
  height?: number
}

export const MenuIcon: React.FC<MenuIconProps> = ({ style, width, height }) => {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      style={{ ...style }}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 16.5C2 16.2239 2.22386 16 2.5 16H22C22.2761 16 22.5 16.2239 22.5 16.5C22.5 16.7761 22.2761 17 22 17H2.5C2.22386 17 2 16.7761 2 16.5Z'
        fill='white'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 7.5C2 7.22386 2.22386 7 2.5 7H22C22.2761 7 22.5 7.22386 22.5 7.5C22.5 7.77614 22.2761 8 22 8H2.5C2.22386 8 2 7.77614 2 7.5Z'
        fill='white'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
