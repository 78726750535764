import jwt_decode from 'jwt-decode'
import { API_ROUTES } from './apiRoutes'
import { callApi } from './callApi'
import { TOKEN } from './consts'

export interface accessTokenType {
  at_id: string
  session_uuid: string
  user_uuid: string
  retailer_uuid: string
  permissions: {
    full_access_roles: number[]
  }
  created_at: number
  session_exp_at: number
  exp: number
}

export interface ReturnType {
  userId: string
  success: boolean
  token: string
  roles?: number[]
}

const cash: { [key: string]: accessTokenType } = {}
let refreshStarted = false

const returnFailObj = { userId: '', success: false, token: '' }

const returnFail = (): ReturnType => {
  localStorage.clear()
  return returnFailObj
}

export const getParsedAccessToken = (accessToken: string): accessTokenType => {
  if (cash[accessToken]) return cash[accessToken]
  return jwt_decode(accessToken)
}

const refreshTokenFetch = async (refreshToken: string): Promise<ReturnType> => {
  refreshStarted = true

  try {
    const data = await callApi({
      path: API_ROUTES.refreshToken,
      method: 'post',
      refreshToken,
    })

    if (!data.access_token || !data.refresh_token) return returnFail()

    const parsedNewTokenData = getParsedAccessToken(data.access_token)

    if (!parsedNewTokenData.permissions || !parsedNewTokenData.exp || !parsedNewTokenData.user_uuid) {
      return returnFail()
    }

    cash[data.access_token] = parsedNewTokenData
    localStorage.setItem(TOKEN.access, data.access_token)
    localStorage.setItem(TOKEN.refresh, data.refresh_token)

    return {
      success: true,
      token: data.access_token,
      userId: parsedNewTokenData.user_uuid,
      roles: parsedNewTokenData.permissions.full_access_roles,
    }
  } catch (e: any) {
    return returnFail()
  } finally {
    refreshStarted = false
  }
}

export const refresh = async (): Promise<ReturnType> => {
  localStorage.removeItem(TOKEN.access)
  const refreshToken = localStorage.getItem(TOKEN.refresh)

  if (!refreshToken) return returnFail()

  const decodeToken: { exp?: number; created_at?: number } = jwt_decode(refreshToken)

  if (!decodeToken || !decodeToken.exp) return returnFail()

  const isExpired = Date.now() >= decodeToken.exp * 1000

  if (isExpired) return returnFail()

  if (refreshStarted) {
    return new Promise((resp) => {
      const interval = setInterval(() => {
        if (!refreshStarted) {
          const token = localStorage.getItem(TOKEN.access)
          const parsedNewTokenData = token ? getParsedAccessToken(token) : ({} as accessTokenType)

          if (!parsedNewTokenData?.user_uuid || !parsedNewTokenData?.exp) return returnFail()

          resp(
            token
              ? {
                  token,
                  success: true,
                  userId: parsedNewTokenData.user_uuid,
                  roles: parsedNewTokenData.permissions.full_access_roles,
                }
              : returnFail()
          )
          clearInterval(interval)
        }
        return null
      }, 200)
    })
  }

  return refreshTokenFetch(refreshToken)
}

export async function checkToken(): Promise<ReturnType> {
  const accessToken = localStorage.getItem(TOKEN.access)

  if (!accessToken) return refresh()

  const parsedToken = getParsedAccessToken(accessToken)

  if (!parsedToken.user_uuid || !parsedToken.exp || !parsedToken.permissions) {
    return returnFail()
  }

  const isExpired: boolean = Date.now() >= parsedToken.exp * 1000

  if (isExpired) {
    delete cash[accessToken]
    return refresh()
  }

  if (!cash[accessToken]) cash[accessToken] = parsedToken

  return {
    success: true,
    token: accessToken,
    userId: parsedToken.user_uuid,
    roles: parsedToken.permissions.full_access_roles,
  }
}
