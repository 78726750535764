import React, { CSSProperties } from 'react'

interface MicrophoneIconProps {
  style?: CSSProperties
  width?: number
  height?: number
}

export const MicrophoneIcon: React.FC<MicrophoneIconProps> = ({ style, width, height }) => {
  return (
    <svg
      width={width || 34}
      height={height || 34}
      style={{ ...style }}
      viewBox='0 0 62 62'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.3941 5.74908C12.0392 2.99225 15.725 1.6026 22.2127 0.536191C25.0886 0.0635343 27.4142 -0.0595126 31.8586 0.0244718C54.028 0.44537 62.0001 8.66998 62.0001 31.1192C62.0001 51.2198 55.9103 59.3575 39.1544 61.6446C35.679 62.1192 25.9518 62.1182 22.5457 61.6436C6.20111 59.3663 0.022886 51.0489 5.88996e-05 31.292C-0.0147116 18.4249 2.74847 10.0167 8.3941 5.74908Z'
        fill='#353535'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.5856 16.3986C27.0725 16.9583 25.2076 18.6674 24.4291 20.2074C23.8944 21.2649 23.7269 22.2689 23.6165 25.0724C23.4347 29.7035 23.9602 31.6948 25.8835 33.6597C29.3454 37.1972 34.8566 36.4317 37.3032 32.0732C38.179 30.5127 38.2065 30.3317 38.2093 26.0716C38.2112 23.0998 38.0761 21.3472 37.7916 20.6533C36.321 17.0674 32.079 15.1068 28.5856 16.3986ZM21.1618 31.6001C20.7725 31.9979 21.1225 34.0001 21.819 35.3582C22.4428 36.5741 24.1592 38.1671 25.4237 38.7038C26.0242 38.9585 27.3223 39.2693 28.3089 39.3942L30.1024 39.6211V41.0187V42.4163H27.9094C26.186 42.4163 25.6751 42.5256 25.5245 42.9266C25.1876 43.8232 26.0965 44 31.0381 44C35.9798 44 36.8887 43.8232 36.5518 42.9266C36.3996 42.5212 35.8771 42.4163 34.011 42.4163H31.662V41.0232V39.6301L33.5787 39.4085C36.2945 39.0946 37.7261 38.4135 39.1538 36.7561C40.4635 35.2355 41.1522 33.5112 40.9716 32.2031C40.79 30.889 40.0255 31.3136 39.4766 33.0338C38.7888 35.1896 37.8462 36.4759 36.4248 37.1978C35.4504 37.6925 34.5275 37.7948 31.0381 37.7948C27.2809 37.7948 26.6873 37.7183 25.5279 37.085C23.9562 36.226 23.1368 35.1128 22.5414 33.0261C22.0713 31.378 21.7273 31.0223 21.1618 31.6001Z'
        fill='white'
      />
    </svg>
  )
}
