import React from 'react'
import { Col, Row } from 'antd'
import { useIntl } from 'react-intl'
import { GreenBlur } from '../GreenBlur'
import { Line } from '../Line'
import { OverflowHidden } from '../OverflowHidden'

export const MainBlock9 = () => {
  const intl = useIntl()

  return (
    <Row
      justify='center'
      className='pb-80 pt-20 py-md-80 br-top-20'
      style={{ background: '#0D181E', position: 'relative', overflow: 'hidden' }}
    >
      <Col xl={20} xs={22} style={{ zIndex: 100 }}>
        <Row className='mb-md-32 mb-20'>
          <Col md={0} xs={24} className='mb-20'>
            <Line style={{ marginLeft: 0, justifyContent: 'flex-start', width: '35%' }}>
              {intl.formatMessage({ id: 'Future' })}
            </Line>
          </Col>
          <Col md={0} xs={24} className='mb-10'>
            <p className='landing-text-36 text-align-center'>
              {intl.formatMessage({ id: 'Additional functionality and features' })}
            </p>
          </Col>
          <Col md={0} xs={24} style={{ opacity: 0.8 }}>
            <p className='landing-text-20-thin text-align-center'>
              {intl.formatMessage({ id: 'That should be implemented' })}
            </p>
          </Col>
          <Col md={15} xs={0}>
            <p className='landing-text-36'>
              {intl.formatMessage({ id: 'Additional functionality and features that should be implemented' })}
            </p>
          </Col>
          <Col md={9} xs={0}>
            <Line>{intl.formatMessage({ id: 'Future' })}</Line>
          </Col>
        </Row>
        <Row gutter={[32, { md: 32, sm: 20, xs: 20 }]}>
          <Col md={8} xs={24}>
            <div className='green-text-container'>
              <p className='landing-text-20' style={{ textAlign: 'center' }}>
                {intl.formatMessage({ id: 'If necessary, we can provide the client' })}
              </p>
            </div>
          </Col>
          <Col md={8} xs={24}>
            <div className='green-text-container'>
              <p className='landing-text-20' style={{ textAlign: 'center' }}>
                {intl.formatMessage({ id: 'The possibility to record consultation calls' })}
              </p>
            </div>
          </Col>
          <Col md={8} xs={24}>
            <div className='green-text-container'>
              <p className='landing-text-20' style={{ textAlign: 'center' }}>
                {intl.formatMessage({ id: 'The ability to monitor the &quot;off-line&quot;' })}
              </p>
            </div>
          </Col>
          <Col md={16} xs={24}>
            <div className='green-text-container'>
              <p className='landing-text-20' style={{ textAlign: 'center' }}>
                {intl.formatMessage({ id: 'Option of scanning product barcodes' })}
              </p>
            </div>
          </Col>
          <Col md={8} xs={24}>
            <div className='green-text-container'>
              <p className='landing-text-20' style={{ textAlign: 'center' }}>
                {intl.formatMessage({ id: 'Feedback feature for the calls for both clients and consultants' })}
              </p>
            </div>
          </Col>
        </Row>
      </Col>
      <GreenBlur style={{ top: 0, left: 0 }} blur='big' />
      <OverflowHidden>
        <GreenBlur className='hidden-md' style={{ bottom: 40, right: -30 }} />
      </OverflowHidden>
    </Row>
  )
}
