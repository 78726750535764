import React, { CSSProperties } from 'react'

interface DoneBigIconProps {
  style?: CSSProperties
  width?: number
  height?: number
}

export const DoneBigIcon: React.FC<DoneBigIconProps> = ({ style, width, height }) => {
  return (
    <svg
      width={width || 148}
      height={height || 148}
      style={{ ...style }}
      viewBox='0 0 148 148'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='74' cy='74' r='74' fill='#489E38' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M102.909 49.8662C104.47 48.3073 107.006 48.3141 108.568 49.8731C110.133 51.4357 110.14 53.9789 108.574 55.5416L66.5025 97.5426C66.0024 98.0419 65.4023 98.3806 64.7663 98.5591C63.2608 99.3348 61.3647 99.0969 60.1038 97.8382L40.1744 77.9423C38.6092 76.3796 38.616 73.8364 40.1813 72.2738C41.7429 70.7149 44.2787 70.708 45.8403 72.2669L63.1552 89.5527L102.909 49.8662Z'
        fill='white'
      />
    </svg>
  )
}
