import React, { CSSProperties } from 'react'

interface MicrophoneMutedIconProps {
  style?: CSSProperties
  width?: number
  height?: number
}

export const MicrophoneMutedIcon: React.FC<MicrophoneMutedIconProps> = ({ style, width, height }) => {
  return (
    <svg
      width={width || 18}
      height={height || 18}
      style={{ ...style }}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='18' height='18' rx='9' fill='white' fillOpacity='0.46' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.03316 4.18071C4.94754 4.41401 4.73396 4.1213 8.7898 9.3281C12.1154 13.5972 12.6583 14.1891 12.6583 13.5448C12.6583 13.278 5.46103 4 5.25411 4C5.16909 4 5.06967 4.08128 5.03316 4.18071ZM8.02052 4.28759C7.69349 4.45315 7.29749 4.73432 7.1404 4.91256L6.855 5.23658L8.0889 6.82457C10.6617 10.1355 11.2234 10.8362 11.3047 10.8362C11.3508 10.8362 11.5127 10.5705 11.6645 10.2458C12.0359 9.45164 12.1287 7.24542 11.8352 6.18992C11.3498 4.44458 9.51651 3.5304 8.02052 4.28759ZM6.2962 8.14123C6.2962 8.71124 6.37849 9.46506 6.47969 9.82104C6.7859 10.8989 7.63011 11.6845 8.70989 11.8961C9.21672 11.9954 10.0159 11.8711 9.96056 11.7015C9.8967 11.5052 6.47969 7.12063 6.38539 7.11379C6.33627 7.11019 6.2962 7.57259 6.2962 8.14123ZM5.36958 10.1215C5.27944 10.2349 5.27504 10.4497 5.35507 10.8222C5.50955 11.5405 5.72135 11.9394 6.1969 12.407C6.75891 12.9597 7.25373 13.1893 8.077 13.2791L8.79349 13.3574V13.8315V14.3055L7.93133 14.342C7.10033 14.3772 7.06917 14.3897 7.06917 14.6893V15H9.15025H11.2313V14.6893C11.2313 14.3897 11.2002 14.3772 10.3692 14.342L9.507 14.3055V13.8311V13.3568L10.1735 13.2848C10.9526 13.2008 11.0085 13.1626 10.8601 12.815C10.7555 12.5703 10.6497 12.5529 9.1468 12.5341C7.78125 12.5171 7.47563 12.4762 7.07595 12.257C6.49194 11.9368 6.21176 11.5511 5.94741 10.7038C5.72883 10.003 5.5815 9.85448 5.36958 10.1215ZM12.5399 10.1215C12.5116 10.207 12.4027 10.5285 12.2979 10.8362C11.9792 11.7721 11.9712 11.8255 12.1226 12.0162C12.3117 12.2543 12.4045 12.1857 12.6627 11.617C12.9705 10.9389 13.0843 10.3145 12.9344 10.1257C12.7736 9.92321 12.6063 9.92147 12.5399 10.1215Z'
        fill='#FF3B30'
      />
    </svg>
  )
}
