export interface TContactUs {}

export interface TContactUsMessage {
  message: string
}

export interface TContactUsState {
  loading: boolean
  data: TContactUs | null
  errors?: string | undefined
}

export enum ActionTypes {
  SEND_MESSAGE_CONTACT_US_R = '@@contact-us/SEND_MESSAGE_CONTACT_US_R',
  SEND_MESSAGE_CONTACT_US_S = '@@contact-us/SEND_MESSAGE_CONTACT_US_S',
  SEND_MESSAGE_CONTACT_US_E = '@@contact-us/SEND_MESSAGE_CONTACT_US_E',
}
