export const ROUTES = {
  home: '/',
  makeCall: '/make-call/:tradePointId/:retailerTagId',
  login: '/login',
  dashboard: '/dashboard',
  tradePoints: '/shops/list',
  createTradePoint: '/shops/create',
  editTradePoint: '/shops/edit/:id',
  retailerTags: '/departments/list',
  createRetailerTags: '/departments/create',
  editRetailerTag: '/departments/edit/:id',
  users: '/consultants/list',
  createUser: '/consultants/add',
  editUser: '/consultants/edit/:id',
  createQRCode: '/create-qr-code',
  settings: '/settings',
  settingsMyAccount: '/settings/my-account',
  callStatistics: '/call-statistics'
}
