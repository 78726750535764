import React, { CSSProperties } from 'react'

interface DoneIconProps {
  style?: CSSProperties
  width?: number
  height?: number
}

export const DoneIcon: React.FC<DoneIconProps> = ({ style, width, height }) => {
  return (
    <svg
      width={width || 28}
      height={height || 28}
      style={{ ...style }}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 27C21.1797 27 27 21.1797 27 14C27 6.8203 21.1797 1 14 1C6.8203 1 1 6.8203 1 14C1 21.1797 6.8203 27 14 27Z'
        stroke='#489E38'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.6643 10L14.0683 17.4627C13.8165 17.7976 13.4319 18.0068 13.014 18.0364C12.5961 18.0659 12.1859 17.9129 11.8895 17.6168L9 14.7273'
        stroke='#489E38'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
