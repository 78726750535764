import React, { CSSProperties } from 'react'

interface EllipseBackgroundIconProps {
  style?: CSSProperties
  width?: number | string
  height?: number | string
  className?: string
}

export const EllipseBackgroundIcon: React.FC<EllipseBackgroundIconProps> = ({ style, width, height, className }) => {
  return (
    <svg
      width={width || '100%'}
      height={height}
      style={{ ...style }}
      className={className || ''}
      viewBox='0 0 1440 582'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1440 0H0V201.508C-55.6387 237.092 -87 277.356 -87 320C-87 464.699 274.082 582 719.5 582C1164.92 582 1526 464.699 1526 320C1526 277.612 1495.01 237.575 1440 202.15V0Z'
        fill='#0D181E'
      />
    </svg>
  )
}
