import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { message } from 'antd'
import { ActionTypes, TUserMessage } from './types'
import { callApi } from '../../utils/callApi'
import { API_ROUTES } from '../../utils/apiRoutes'
import * as Actions from './actions'

function* getUsersWorker(action: ReturnType<typeof Actions.GetUsers.request>) {
  const { callBack, ...body } = action.payload as Actions.TypeUsersR
  let success: boolean = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.consultants,
      data: body,
    })) as TUserMessage

    yield put(Actions.GetUsers.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetUsers.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getUserByIdWorker(action: ReturnType<typeof Actions.GetUserById.request>) {
  const { callBack, id } = action.payload as Actions.TypeUserByIdR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.users}/${id}`,
    })) as TUserMessage

    yield put(Actions.GetUserById.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetUserById.error(`${data?.detail || data}`))
  } finally {
    if (!success) message.error(`${data?.message || data}`)
    if (callBack) callBack(success, data)
  }
}

function* createNewUserWorker(action: ReturnType<typeof Actions.CreateNewUser.request>) {
  const { body, callBack } = action.payload as Actions.TypeCreateNewUser
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.users,
      data: body,
    })) as TUserMessage

    yield put(Actions.CreateNewUser.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.CreateNewUser.error(`${data?.detail || data}`))
  } finally {
    if (!success) message.error(`${data?.message || data}`)
    if (callBack) callBack(success, data)
  }
}

function* addUserToTradePointWorker(action: ReturnType<typeof Actions.AddUserToTradePoint.request>) {
  const { body, callBack } = action.payload as Actions.TypeUserToTradePoint
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.userToTradePoints,
      data: body,
    })) as TUserMessage

    yield put(Actions.AddUserToTradePoint.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.AddUserToTradePoint.error(`${data?.detail || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* deleteUserFromTradePointWorker(action: ReturnType<typeof Actions.DeleteUserFromTradePoint.request>) {
  const { body, callBack } = action.payload as Actions.TypeUserToTradePoint
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'delete',
      path: API_ROUTES.userToTradePoints,
      data: body,
    })) as TUserMessage

    yield put(Actions.DeleteUserFromTradePoint.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.DeleteUserFromTradePoint.error(`${data?.detail || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* addRetailerTagToUserWorker(action: ReturnType<typeof Actions.AddRetailerTagToUser.request>) {
  const { body, callBack } = action.payload as Actions.TypeRetailerTagToUser
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.retailerTagToUser,
      data: body,
    })) as TUserMessage

    yield put(Actions.AddRetailerTagToUser.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.AddRetailerTagToUser.error(`${data?.detail || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* deleteRetailerTagFromUserWorker(action: ReturnType<typeof Actions.DeleteRetailerTagFromUser.request>) {
  const { body, callBack } = action.payload as Actions.TypeRetailerTagToUser
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'delete',
      path: API_ROUTES.retailerTagToUser,
      data: body,
    })) as TUserMessage

    yield put(Actions.DeleteRetailerTagFromUser.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.DeleteRetailerTagFromUser.error(`${data?.detail || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* updateUserDataWorker(action: ReturnType<typeof Actions.UpdateUserData.request>) {
  const { callBack, user_id, ...body } = action.payload as Actions.TypeUpdateUserDataR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'put',
      path: `${API_ROUTES.updateUsers}/${user_id}`,
      data: body,
    })) as TUserMessage

    yield put(Actions.UpdateUserData.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.UpdateUserData.error(`${data?.detail || data}`))
  } finally {
    if (success) message.success(`User data was updated`)
    else message.error(`${data?.message || data}`)
    if (callBack) callBack(success, data)
  }
}

function* updateUserPasswordWorker(action: ReturnType<typeof Actions.UpdateUserPassword.request>) {
  const { callBack, user_id, ...body } = action.payload as Actions.TypeUpdateUserPasswordR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'put',
      path: `${API_ROUTES.updateUsers}/${user_id}/change-password`,
      data: body,
    })) as TUserMessage

    yield put(Actions.UpdateUserPassword.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.UpdateUserPassword.error(`${data?.detail || data}`))
  } finally {
    if (success) message.success(`User password was updated`)
    else message.error(`${data?.message || data}`)
    if (callBack) callBack(success, data)
  }
}

function* deleteUser(action: ReturnType<typeof Actions.DeleteUser.request>) {
  const { callBack, id } = action.payload as Actions.TypeDeleteUser
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'delete',
      path: `${API_ROUTES.users}/${id}`,
    })) as TUserMessage

    yield put(Actions.DeleteUser.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.DeleteUser.error(`${data?.detail || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_USERS_R, getUsersWorker)
  yield takeEvery(ActionTypes.GET_USER_BY_ID_R, getUserByIdWorker)
  yield takeEvery(ActionTypes.CREATE_NEW_USER_R, createNewUserWorker)
  yield takeEvery(ActionTypes.ADD_USER_TO_TRADE_POINT_R, addUserToTradePointWorker)
  yield takeEvery(ActionTypes.DELETE_USER_FROM_TRADE_POINT_R, deleteUserFromTradePointWorker)
  yield takeEvery(ActionTypes.ADD_RETAILER_TAG_TO_USER_R, addRetailerTagToUserWorker)
  yield takeEvery(ActionTypes.DELETE_RETAILER_TAG_FROM_USER_R, deleteRetailerTagFromUserWorker)
  yield takeEvery(ActionTypes.UPDATE_USER_DATA_R, updateUserDataWorker)
  yield takeEvery(ActionTypes.UPDATE_USER_PASSWORD_R, updateUserPasswordWorker)
  yield takeEvery(ActionTypes.DELETE_USER_R, deleteUser)
}

export default function* usersSaga() {
  yield all([fork(watchRequest)])
}
