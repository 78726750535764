import enLang from './entries/en-US'
import frLang from './entries/fr_FR'
import plLang from './entries/pl_PL'

const AppLocale = {
  en: enLang,
  pl: plLang,
  fr: frLang,
}

export default AppLocale
