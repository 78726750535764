import { Reducer } from 'redux'
import { ActionTypes, TTradePointState } from './types'

export const initialState: TTradePointState = {
  loading: false,
  data: {
    total: 0,
    trade_points: [],
    tradePointForEdit: null,
  },
}

const reducer: Reducer<TTradePointState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_TRADE_POINTS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_TRADE_POINTS_S:
      return { ...state, loading: false, data: action.payload }
    case ActionTypes.GET_TRADE_POINTS_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.GET_TRADE_POINT_BY_ID_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_TRADE_POINT_BY_ID_S:
      return { ...state, loading: false, data: { tradePointForEdit: action.payload } }
    case ActionTypes.GET_TRADE_POINT_BY_ID_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.CREATE_NEW_TRADE_POINT_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.CREATE_NEW_TRADE_POINT_S:
      return { ...state, loading: false, data: action.payload }
    case ActionTypes.CREATE_NEW_TRADE_POINT_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.UPDATE_TRADE_POINT_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.UPDATE_TRADE_POINT_S:
      return { ...state, loading: false, data: action.payload }
    case ActionTypes.UPDATE_TRADE_POINT_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.DELETE_TRADE_POINT_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.DELETE_TRADE_POINT_S:
      return { ...state, loading: false }
    case ActionTypes.DELETE_TRADE_POINT_E:
      return { ...state, loading: false, errors: action.payload }

    default:
      return state
  }
}

export { reducer as TradePointReducer }
