import React from 'react'
import { MicrophoneMutedIcon } from '../../asserts/svg/MicrophoneMutedIcon'

interface MiniCameraProps {
  muted?: boolean
  turned?: boolean
  videoRef: any
}

export const MiniCamera: React.FC<MiniCameraProps> = ({ muted, turned, videoRef }) => {
  return (
    <div
      style={{
        background: '#D9D9D9',
        display: 'flex',
        maxHeight: 'calc(10vh + 10vw)',
        maxWidth: 'calc(10vh + 10vw)',
        position: 'relative',
        borderRadius: 10,
      }}
    >
      <video
        width='100%'
        height='100%'
        ref={videoRef}
        style={
          turned
            ? {
                objectFit: 'cover',
                borderRadius: 10,
                transform: 'rotateY(180deg)',
              }
            : { objectFit: 'cover', borderRadius: 10 }
        }
        autoPlay
        playsInline
        muted
      />
      {muted && (
        <div style={{ position: 'absolute', top: 3, right: 3 }}>
          <MicrophoneMutedIcon />
        </div>
      )}
    </div>
  )
}
