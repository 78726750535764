import { Reducer } from 'redux'
import { ActionTypes, TRetailerTagsState } from './types'

export const initialState: TRetailerTagsState = {
  loading: false,
  data: {
    total: 0,
    RetailerTagInformation: [],
    retailerTagForEdit: null,
  },
}

const reducer: Reducer<TRetailerTagsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_RETAILER_TAGS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_RETAILER_TAGS_S:
      return { ...state, loading: false, data: { ...state.data, ...action.payload } }
    case ActionTypes.GET_RETAILER_TAGS_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.GET_RETAILER_TAG_BY_ID_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_RETAILER_TAG_BY_ID_S:
      return { ...state, loading: false, data: { ...state.data, retailerTagForEdit: action.payload } }
    case ActionTypes.GET_RETAILER_TAG_BY_ID_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.CREATE_NEW_RETAILER_TAG_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.CREATE_NEW_RETAILER_TAG_S:
      return { ...state, loading: false, data: action.payload }
    case ActionTypes.CREATE_NEW_RETAILER_TAG_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.UPDATE_RETAILER_TAG_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.UPDATE_RETAILER_TAG_S:
      return { ...state, loading: false, data: action.payload }
    case ActionTypes.UPDATE_RETAILER_TAG_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.DELETE_RETAILER_TAG_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.DELETE_RETAILER_TAG_S:
      return { ...state, loading: false, data: action.payload }
    case ActionTypes.DELETE_RETAILER_TAG_E:
      return { ...state, loading: false, errors: action.payload }

    default:
      return state
  }
}

export { reducer as RetailerTagReducer }
