import { Reducer } from 'redux'
import { ActionTypes, TCallsState } from './types'

export const initialState: TCallsState = {
  loading: false,
  data: {
    total: 0,
    calls: [],
    call_statuses: [],
  },
}

const reducer: Reducer<TCallsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CALLS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_CALLS_S:
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
      }
    case ActionTypes.GET_CALLS_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.GET_CALL_STATUSES_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_CALL_STATUSES_S:
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
      }
    case ActionTypes.GET_CALL_STATUSES_E:
      return { ...state, loading: false, errors: action.payload }

    default:
      return state
  }
}

export { reducer as CallReducer }
