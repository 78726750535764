import React, { CSSProperties } from 'react'

interface ScanBarcodeIconProps {
  style?: CSSProperties
  width?: number
  height?: number
}

export const ScanBarcodeIcon: React.FC<ScanBarcodeIconProps> = ({ style, width, height }) => {
  return (
    <svg
      width={width || 32}
      height={height || 32}
      style={{ ...style }}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M6 8.25V17.25' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 8.25V12.75' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M18 8.25V17.25' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15 8.25V12.75' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 8.25V12.75' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M9 15.75H15' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1.5 3.75V0.75H4.5' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M22.5 3.75V0.75H19.5' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1.5 20.25V23.25H4.5' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M22.5 20.25V23.25H19.5' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}
