import { Reducer } from 'redux'
import { ActionTypes, TGeneralState } from './types'

export const initialState: TGeneralState = {
  locale: window.location.host === 'konsultant.udtech.pl' ? 'pl' : 'en',
  // locale: 'pl',
}

const reducer: Reducer<TGeneralState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_LOCALE:
      return { ...state, locale: action.payload }

    default:
      return state
  }
}

export { reducer as GeneralReducer }
