import { Actions } from '../../utils/Action'
import { TDefRequest } from '../../types/actions'
import { ActionTypes } from './types'

/// ///////////////////////////////////////////////////////////////////////
const SendMessageContactUsActions = new Actions('SEND_MESSAGE_CONTACT_US', ActionTypes)

export interface TypeSendMessageContactUsR extends TDefRequest {
  name: string
  email: string
  phone?: string
  company_name?: string
  language: 'EN' | 'PL'
}

export const SendMessageContactUs = {
  request: (payload: TypeSendMessageContactUsR) => SendMessageContactUsActions.request(payload),
  success: (payload: any) => SendMessageContactUsActions.success(payload),
  error: (payload: string) => SendMessageContactUsActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
