import React, { CSSProperties } from 'react'

interface SpeakerIconProps {
  style?: CSSProperties
  width?: number
  height?: number
}

export const SpeakerIcon: React.FC<SpeakerIconProps> = ({ style, width, height }) => {
  return (
    <svg
      width={width || 36}
      height={height || 36}
      style={{ ...style }}
      viewBox='0 0 26 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.82201 0.6321C4.47028 1.31179 1.68333 3.47084 0.590389 7.78383C0.163527 9.46822 -0.00100655 11.1002 4.62959e-06 13.6407C0.00173808 18.3326 0.863841 21.429 2.70707 23.3649C4.07505 24.8015 5.5742 25.556 8.16079 26.1094C9.87589 26.4764 13.7097 26.6175 15.7903 26.3903C23.2486 25.576 25.9968 22.1442 26 13.6407C26.0033 4.9502 23.3045 1.45103 15.9613 0.624752C14.4545 0.4553 11.1836 0.459175 9.82201 0.6321ZM17.8487 8.16743C20.4566 10.6382 20.6016 15.1873 18.1742 18.3734C17.4989 19.2597 16.9397 19.6749 16.719 19.4537C16.6148 19.3494 16.8042 19.0454 17.3358 18.4635C19.979 15.5705 19.9979 11.0085 17.377 8.48582C16.6686 7.80396 16.5036 7.42207 16.9175 7.42207C16.9969 7.42207 17.4161 7.75748 17.8487 8.16743ZM16.4109 9.91247C18.133 11.5027 18.4101 14.2256 17.0561 16.2521C16.5328 17.0353 15.6627 17.6721 15.4319 17.4409C15.3199 17.3287 15.5028 17.0622 16.0773 16.5004C17.0553 15.5438 17.3976 14.592 17.3059 13.0843C17.2338 11.9013 16.8936 11.164 16.0272 10.3137C15.4252 9.72304 15.2542 9.30326 15.6155 9.30326C15.69 9.30326 16.048 9.57733 16.4109 9.91247ZM12.3397 9.82001C12.5333 10.0141 12.5666 10.5543 12.5666 13.4998C12.5666 16.4453 12.5333 16.9855 12.3397 17.1795C12.2148 17.3045 12.0541 17.4069 11.9826 17.4069C11.911 17.4069 11.3102 16.9923 10.6473 16.4857C9.11852 15.3171 9.06363 15.2901 7.9795 15.1662C6.69516 15.0195 6.45479 14.6914 6.52312 13.1785C6.58884 11.7241 6.69502 11.6215 8.30409 11.4581C9.54206 11.3324 9.57615 11.3183 10.711 10.4616C11.3441 9.98365 11.9185 9.59267 11.9874 9.59267C12.0563 9.59267 12.2148 9.69499 12.3397 9.82001ZM15.3149 11.5882C16.0116 12.286 16.192 13.1871 15.827 14.1444C15.5476 14.8772 14.9572 15.5257 14.5694 15.5257C14.1896 15.5257 14.2335 15.3543 14.7366 14.8714C15.6408 14.0038 15.6619 12.6971 14.7856 11.8446C14.3137 11.3855 14.2401 11.0397 14.6146 11.0397C14.6986 11.0397 15.0138 11.2865 15.3149 11.5882Z'
        fill='white'
      />
      <rect x='5.45166' y='6.37109' width='15.0968' height='13.8387' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0798 7.28293C16.9375 7.41836 17.0012 7.51091 17.8579 8.41267C18.81 9.41497 19.4233 10.4722 19.6983 11.5858C19.9267 12.5108 19.925 14.127 19.6945 15.1197C19.4622 16.1196 18.7641 17.5166 18.1284 18.253C17.333 19.1747 17.0031 19.5981 17.0031 19.6975C17.0031 19.7487 17.1279 19.7906 17.2803 19.7906C17.7509 19.7906 18.8871 18.4962 19.5507 17.2044C21.0744 14.2376 20.8415 11.0387 18.9282 8.65619C18.5039 8.12769 17.4307 7.20996 17.2372 7.20996C17.1928 7.20996 17.1219 7.24278 17.0798 7.28293ZM15.6235 9.33795C15.6235 9.42536 15.9361 9.83144 16.3183 10.2405C16.7003 10.6495 17.153 11.2549 17.3242 11.5858C17.6101 12.1386 17.6354 12.2943 17.6354 13.5003C17.6354 15.1058 17.4912 15.4646 16.3837 16.6128C15.6052 17.4201 15.4602 17.7121 15.8376 17.7121C16.3015 17.7121 17.2688 16.785 17.7435 15.8857C18.8511 13.7876 18.4948 11.5903 16.7759 9.91753C16.0502 9.21127 15.6235 8.99663 15.6235 9.33795ZM10.6624 10.2566C9.22864 11.2491 9.27198 11.2311 7.86996 11.4184C6.53934 11.5961 6.26883 11.69 6.07005 12.0434C5.86966 12.3998 5.7977 13.9985 5.96083 14.4691C6.13501 14.9711 6.49979 15.1601 7.57438 15.3051C8.57275 15.4399 8.9411 15.6159 10.3277 16.6208C11.7551 17.6554 11.8841 17.7089 12.2081 17.4005C12.4596 17.1613 12.4619 17.1258 12.4619 13.5634C12.4619 10.1812 12.449 9.95418 12.2429 9.73758C12.1225 9.6109 11.9612 9.5073 11.8844 9.5073C11.8076 9.5073 11.2577 9.84446 10.6624 10.2566ZM14.4739 11.1833C14.4739 11.2627 14.6473 11.5212 14.8593 11.7576C15.0713 11.9941 15.33 12.3451 15.4342 12.5376C15.8302 13.2699 15.5641 14.3716 14.8332 15.0249C14.6356 15.2016 14.4739 15.4109 14.4739 15.4899C14.4739 15.7618 15.0017 15.6354 15.3586 15.278C16.2953 14.34 16.5295 13.07 15.9415 12.117C15.7691 11.8376 15.4679 11.4806 15.2721 11.3238C14.8918 11.0194 14.4739 10.9458 14.4739 11.1833Z'
        fill='#0C0C0C'
      />
    </svg>
  )
}
