import React from 'react'
import { Col, Row } from 'antd'
import { useIntl } from 'react-intl'
import landingStatistics from '../../../asserts/png/landing-statistics.png'
import { GreenBlur } from '../GreenBlur'
import { OverflowHidden } from '../OverflowHidden'
import { Line } from '../Line'

export const MainBlock8 = () => {
  const intl = useIntl()

  return (
    <Row justify='center' className='py-md-100 py-16 mb-30 mb-md-0' style={{ position: 'relative' }}>
      <Col xl={20} xs={22} style={{ zIndex: 100 }}>
        <Row className='mb-md-32 mb-20'>
          <Col md={0} xs={24} className='mb-10'>
            <Line style={{ marginLeft: 0, justifyContent: 'flex-start', width: '28%' }}>
              {intl.formatMessage({ id: 'Now' })}
            </Line>
          </Col>
          <Col md={16} xs={24}>
            <p className='landing-text-36-black text-align-center text-align-md-left'>
              {intl.formatMessage({ id: 'Currently, the released version of the application' })}
            </p>
          </Col>
          <Col md={8} xs={0}>
            <Line>{intl.formatMessage({ id: 'Now' })}</Line>
          </Col>
        </Row>
        <Row gutter={[36, 0]}>
          <Col md={12} xs={0}>
            <Row gutter={[0, 60]} style={{ height: '100%' }}>
              <Col span={24}>
                <img
                  width='100%'
                  className='b-20'
                  style={{
                    border: '8px solid #0D181E',
                    boxShadow: '0px 18px 70px -36px rgba(0, 0, 0, 0.45)',
                  }}
                  loading='lazy'
                  src={landingStatistics}
                  alt='landing-statistics'
                />
              </Col>
              <Col lg={12} xs={24} style={{ marginTop: 'auto' }}>
                <button
                  type='button'
                  className='default-landing-btn'
                  style={{ width: '100%' }}
                  onClick={() => {
                    window.location.href = '#contact-us'
                  }}
                >
                  {intl.formatMessage({ id: 'Book Your Free Demo' })}
                </button>
              </Col>
            </Row>
          </Col>
          <Col
            md={12}
            xs={0}
            className='flex-md'
            style={{ flexDirection: 'column', justifyContent: 'space-between', gap: 12 }}
          >
            <Row>
              <Col span={24}>
                <div className='dark-text-container' style={{ position: 'relative', overflow: 'hidden' }}>
                  <p className='landing-text-20'>
                    {intl.formatMessage({ id: 'The ability to add stores, departments' })}
                  </p>
                  <GreenBlur style={{ height: 60, width: 60, filter: 'blur(25px)', top: -30, left: -30 }} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className='dark-text-container' style={{ position: 'relative', overflow: 'hidden' }}>
                  <p className='landing-text-20'>
                    {intl.formatMessage({ id: 'Option of generating QR codes for specific stores and departments' })}
                  </p>
                  <GreenBlur style={{ height: 60, width: 60, filter: 'blur(25px)', top: -30, left: -30 }} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className='dark-text-container' style={{ position: 'relative', overflow: 'hidden' }}>
                  <p className='landing-text-20'>
                    {intl.formatMessage({ id: 'The possibility of making video calls' })}
                  </p>
                  <GreenBlur style={{ height: 60, width: 60, filter: 'blur(25px)', top: -30, left: -30 }} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className='dark-text-container' style={{ position: 'relative', overflow: 'hidden' }}>
                  <p className='landing-text-20'>{intl.formatMessage({ id: 'Display of call statistics' })}</p>
                  <GreenBlur style={{ height: 60, width: 60, filter: 'blur(25px)', top: -30, left: -30 }} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col md={0} xs={24}>
        <Row
          justify='center'
          style={{ background: '#0D181E', position: 'relative', overflow: 'hidden' }}
          className='pb-56 pt-16 br-top-20'
        >
          <Col span={20}>
            <p className='landing-text-20-thin text-align-center'>
              {intl.formatMessage({ id: 'The ability to add stores, departments' })}
            </p>
          </Col>
          <GreenBlur style={{ bottom: '100%', left: 0, filter: 'blur(25px)' }} />
          <GreenBlur style={{ bottom: '100%', right: 0, filter: 'blur(25px)' }} />
        </Row>
        <Row
          justify='center'
          style={{ background: '#0D181E', position: 'relative', overflow: 'hidden' }}
          className='pb-56 pt-16 br-top-20 mt--40'
        >
          <Col span={20}>
            <p className='landing-text-20-thin text-align-center'>
              {intl.formatMessage({ id: 'Option of generating QR codes for specific stores and departments' })}
            </p>
          </Col>
          <GreenBlur style={{ bottom: '100%', left: 0, filter: 'blur(25px)' }} />
          <GreenBlur style={{ bottom: '100%', right: 0, filter: 'blur(25px)' }} />
        </Row>
        <Row
          justify='center'
          style={{ background: '#0D181E', position: 'relative', overflow: 'hidden' }}
          className='pb-56 pt-16 br-top-20 mt--40'
        >
          <Col span={20}>
            <p className='landing-text-20-thin text-align-center'>
              {intl.formatMessage({ id: 'The possibility of making video calls' })}
            </p>
          </Col>
          <GreenBlur style={{ top: '-50%', left: 0, filter: 'blur(25px)' }} />
          <GreenBlur style={{ top: '-50%', right: 0, filter: 'blur(25px)' }} />
        </Row>
        <Row
          justify='center'
          style={{ background: '#0D181E', position: 'relative', overflow: 'hidden' }}
          className='pb-36 pt-16 br-top-20 mt--40'
        >
          <Col span={20}>
            <p className='landing-text-20-thin text-align-center'>
              {intl.formatMessage({ id: 'Display of call statistics' })}
            </p>
          </Col>
          <GreenBlur style={{ top: '-50%', left: 0, filter: 'blur(25px)' }} />
          <GreenBlur style={{ top: '-50%', right: 0, filter: 'blur(25px)' }} />
        </Row>
        <Row className='br-bottom-20' style={{ background: '#0D181E' }} justify='center' gutter={[0, 16]}>
          <Col span={22}>
            <img
              width='100%'
              className='br-16'
              style={{
                boxShadow: '0px 17.5651px 70.2605px -35.1303px rgba(0, 0, 0, 0.45)',
                border: '5px solid rgba(255, 255, 255, 0.1)',
              }}
              loading='lazy'
              src={landingStatistics}
              alt='landing-statistics'
            />
          </Col>
          <Col span={22}>
            <button
              type='button'
              className='default-landing-btn mb-20'
              style={{ width: '100%' }}
              onClick={() => {
                window.location.href = '#contact-us'
              }}
            >
              {intl.formatMessage({ id: 'Book Your Free Demo' })}
            </button>
          </Col>
        </Row>
      </Col>
      <GreenBlur style={{ top: 0, right: 0 }} blur='big' />
      <OverflowHidden className='hidden block-md'>
        <GreenBlur style={{ bottom: -60, left: 0 }} blur='big' />
      </OverflowHidden>
    </Row>
  )
}
