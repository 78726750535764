import { Actions } from '../../utils/Action'
import { TDefRequest } from '../../types/actions'
import { ActionTypes } from './types'
import { ReturnType } from '../../utils/checkToken'

/// ///////////////////////////////////////////////////////////////////////
const SignInActions = new Actions('SIGN_IN', ActionTypes)

export interface TypePostSignInR extends TDefRequest {
  email: string
  password: string
}

export const PostSignIn = {
  request: (payload: TypePostSignInR) => SignInActions.request(payload),
  success: (payload: any) => SignInActions.success(payload),
  error: (payload: string) => SignInActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const SignOutActions = new Actions('SIGN_OUT', ActionTypes)

export interface TypeDeleteSignOutR extends TDefRequest {}

export const DeleteSignOut = {
  request: (payload?: TypeDeleteSignOutR) => SignOutActions.request(payload || {}),
  success: (payload: any) => SignOutActions.success(payload),
  error: (payload: string) => SignOutActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
export const SetIsAuth = (payload: ReturnType) => ({ type: ActionTypes.SET_AUTH, payload })
