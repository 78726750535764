import { Actions } from '../../utils/Action'
import { TDefRequest } from '../../types/actions'
import { ActionTypes } from './types'

/// ///////////////////////////////////////////////////////////////////////
const GetTradePointsActions = new Actions('GET_TRADE_POINTS', ActionTypes)

export interface TypeGetTradePointsR extends TDefRequest {
  query?: {
    limit?: number
    offset?: number
    q?: string
    order?: 'asc' | 'desc'
  }
}

export const GetTradePoints = {
  request: (payload?: TypeGetTradePointsR) => GetTradePointsActions.request(payload || {}),
  success: (payload: any) => GetTradePointsActions.success(payload),
  error: (payload: string) => GetTradePointsActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetTradePointByIdActions = new Actions('GET_TRADE_POINT_BY_ID', ActionTypes)

export interface TypeGetTradePointById extends TDefRequest {
  id: string
}

export const GetTradePointById = {
  request: (payload?: TypeGetTradePointById) => GetTradePointByIdActions.request(payload || {}),
  success: (payload: any) => GetTradePointByIdActions.success(payload),
  error: (payload: string) => GetTradePointByIdActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////

const CreateNewTradePointActions = new Actions('CREATE_NEW_TRADE_POINT', ActionTypes)

export interface TypeCreateNewTradePoint extends TDefRequest {
  body: {
    name: string
    address: string
    phone: string
    description?: string
  }
}

export const CreateNewTradePoint = {
  request: (payload: TypeCreateNewTradePoint) => CreateNewTradePointActions.request(payload),
  success: (payload: any) => CreateNewTradePointActions.success(payload),
  error: (payload: string) => CreateNewTradePointActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const UpdateTradePointActions = new Actions('UPDATE_TRADE_POINT', ActionTypes)

export interface TypeUpdateTradePoint extends TDefRequest {
  id: string
  body: {
    name: string
    address: string
    phone: string
    description?: string
  }
}

export const UpdateTradePoint = {
  request: (payload: TypeUpdateTradePoint) => UpdateTradePointActions.request(payload),
  success: (payload: any) => UpdateTradePointActions.success(payload),
  error: (payload: string) => UpdateTradePointActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const DeleteTradePointActions = new Actions('DELETE_TRADE_POINT', ActionTypes)

export interface TypeDeleteTradePoint extends TDefRequest {
  id: string
}

export const DeleteTradePoint = {
  request: (payload: TypeDeleteTradePoint) => DeleteTradePointActions.request(payload),
  success: (payload: any) => DeleteTradePointActions.success(payload),
  error: (payload: string) => DeleteTradePointActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
