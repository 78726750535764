import React from 'react'
import { useDispatch } from 'react-redux'
import { Menu, Checkbox, Dropdown, Button, message } from 'antd'
import { UpOutlined } from '@ant-design/icons'
import { TRetailerTag } from '../../store/retailerTags/types'
import { AddRetailerTagToUser, DeleteRetailerTagFromUser, GetUserById } from '../../store/users/actions'

interface AssignRetailerTagProps {
  userId: string
  userRetailerTags?: TRetailerTag[]
  retailerTags?: TRetailerTag[]
  step: number
  edit: boolean
}

export const AssignRetailerTag: React.FC<AssignRetailerTagProps> = ({
  userId,
  userRetailerTags,
  retailerTags,
  step,
  edit,
}) => {
  const dispatch = useDispatch()

  const handleButtonClick = (event: any, id: string) => {
    if (event.target.checked) {
      dispatch(
        AddRetailerTagToUser.request({
          body: {
            user_id: userId,
            retailer_tag_id: id,
          },
          callBack: (success, data) => {
            if (success) {
              dispatch(GetUserById.request({ id: userId }))
            } else {
              message.error(data.message)
            }
          },
        })
      )
    } else {
      dispatch(
        DeleteRetailerTagFromUser.request({
          body: {
            user_id: userId,
            retailer_tag_id: id,
          },
          callBack: (success, data) => {
            if (success) {
              dispatch(GetUserById.request({ id: userId }))
            } else {
              message.error(data.message)
            }
          },
        })
      )
    }
  }

  const checkIfRetailerTagAdded = (id: string) => {
    const retailerTag = userRetailerTags?.find((rTag) => rTag.id === id)
    return !!retailerTag
  }

  const menus =
    retailerTags?.map((rTag: TRetailerTag) => {
      return {
        label: (
          <Checkbox
            className='ant-checkbox-rtl'
            checked={checkIfRetailerTagAdded(rTag.id)}
            disabled={!edit && (!userId || step === 0 || step === 1)}
            onChange={(event) => handleButtonClick(event, rTag.id)}
          >
            {rTag.name}
          </Checkbox>
        ),
        key: rTag.id,
      }
    }) ?? []

  const menu = <Menu items={menus} />

  return (
    <Dropdown
      open
      overlay={menu}
      disabled={!edit && (!userId || step === 0 || step === 1)}
      overlayClassName='consultant-dropdown'
    >
      <Button size='large'>
        Select a department <UpOutlined />
      </Button>
    </Dropdown>
  )
}
