import React, { useState } from 'react'
import { Button, Col, Form, Input, Row, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { AppStore } from '../store/applicationState'
import { checkToken, ReturnType } from '../utils/checkToken'
import { PostSignIn, SetIsAuth } from '../store/auth/actions'
import { ROUTES } from '../utils/routes'
import { ContainerSimple } from '../wrappers/ContainerSimple'
import { RequiredMark } from '../types/actions'

interface LoginProps {}

export const Login: React.FC<LoginProps> = () => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()

  // const location = useLocation()

  const { Auth } = useSelector((store: AppStore) => store)

  const [form] = Form.useForm()
  const [requiredMark, setRequiredMarkType] = useState<RequiredMark>('optional')

  const onRequiredTypeChange = ({ requiredMarkValue }: { requiredMarkValue: RequiredMark }) => {
    setRequiredMarkType(requiredMarkValue)
  }

  const callBack = async (success: boolean, data: any) => {
    if (success && data?.access_token) {
      // const decodeToken = jwt_decode(data?.access_token) as accessTokenType
      const userToken: ReturnType = await checkToken()
      dispatch(SetIsAuth(userToken))

      history.replace(ROUTES.home)
      // @ts-ignore
      // const prevLocation = location?.state?.prevLocation
      // if (prevLocation && prevLocation !== '/' && prevLocation.search('/login') === -1)
      //   return history.replace(prevLocation)
      //
      // if (decodeToken?.user_uuid) history.replace(ROUTES.home)
      // else history.replace(ROUTES.login)
    }
    return null
  }

  const onFinish = async (values: any) => {
    const userToken: ReturnType = await checkToken()
    if (userToken.success) window.location.reload()
    else dispatch(PostSignIn.request({ ...values, callBack }))
  }

  return (
    <ContainerSimple>
      <Row align='middle' style={{ height: '100%' }}>
        <Col span={24}>
          <Row justify='center'>
            <Col span={8}>
              <Typography.Title level={1}> {intl.formatMessage({ id: 'Sign in' })}</Typography.Title>
            </Col>
          </Row>
          <Row justify='center'>
            <Col span={8}>
              <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
                initialValues={{ requiredMarkValue: requiredMark }}
                onValuesChange={onRequiredTypeChange}
                requiredMark={requiredMark}
              >
                <Form.Item
                  name='email'
                  label='Email'
                  required
                  rules={[
                    { required: true, message: 'Please input your Email!' },
                    { type: 'email', message: 'Email is not valid!' },
                  ]}
                >
                  <Input size='large' />
                </Form.Item>
                <Form.Item name='password' rules={[{ required: true, message: 'Please input your password!' }]}>
                  <Input.Password size='large' />
                </Form.Item>
                <Form.Item style={{ marginTop: 60 }}>
                  <Button
                    type='primary'
                    size='large'
                    loading={Auth.loading}
                    style={{ width: '100%' }}
                    htmlType='submit'
                    autoFocus
                  >
                    {intl.formatMessage({ id: 'Sign in' })}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </ContainerSimple>
  )
}
