export const API_PATH = process.env.REACT_APP_API_PATH || ''
export const TOKEN = {
  access: process.env.REACT_APP_ACCESS_TOKEN || 'ACCESS_TOKEN',
  refresh: process.env.REACT_APP_REFRESH_TOKEN || 'REFRESH_TOKEN',
}

export const ONLINE = 'NLN_NLN'

export const STUN2 = [
  {
    urls: 'stun:openrelay.metered.ca:80',
  },
  {
    urls: 'turn:openrelay.metered.ca:80',
    username: 'openrelayproject',
    credential: 'openrelayproject',
  },
  {
    urls: 'turn:openrelay.metered.ca:443',
    username: 'openrelayproject',
    credential: 'openrelayproject',
  },
  {
    urls: 'turn:openrelay.metered.ca:443?transport=tcp',
    username: 'openrelayproject',
    credential: 'openrelayproject',
  },
]

export const STUN3 = [
  {
    urls: 'stun:stun.l.google.com:19302',
  },
]

export const STUN = [
  {
    url: 'stun:fr-turn1.xirsys.com',
    urls: 'stun:fr-turn1.xirsys.com',
  },
  {
    url: 'turn:fr-turn1.xirsys.com:80?transport=udp',
    username: 'KBecSzucnd_YXO0UyCUF1vz9tQALvMKgbrUgSC9JzIJGfWrjkd1muL58DzxV66bAAAAAAGM4LHREYW5pbA',
    urls: 'turn:fr-turn1.xirsys.com:80?transport=udp',
    credential: 'fe881972-4180-11ed-9699-0242ac120004=',
  },
  {
    url: 'turn:fr-turn1.xirsys.com:3478?transport=udp',
    username: 'KBecSzucnd_YXO0UyCUF1vz9tQALvMKgbrUgSC9JzIJGfWrjkd1muL58DzxV66bAAAAAAGM4LHREYW5pbA',
    urls: 'turn:fr-turn1.xirsys.com:3478?transport=udp',
    credential: 'fe881972-4180-11ed-9699-0242ac120004=',
  },
  {
    url: 'turn:fr-turn1.xirsys.com:80?transport=tcp',
    username: 'KBecSzucnd_YXO0UyCUF1vz9tQALvMKgbrUgSC9JzIJGfWrjkd1muL58DzxV66bAAAAAAGM4LHREYW5pbA',
    urls: 'turn:fr-turn1.xirsys.com:80?transport=tcp',
    credential: 'fe881972-4180-11ed-9699-0242ac120004=',
  },
  {
    url: 'turn:fr-turn1.xirsys.com:3478?transport=tcp',
    username: 'KBecSzucnd_YXO0UyCUF1vz9tQALvMKgbrUgSC9JzIJGfWrjkd1muL58DzxV66bAAAAAAGM4LHREYW5pbA',
    urls: 'turn:fr-turn1.xirsys.com:3478?transport=tcp',
    credential: 'fe881972-4180-11ed-9699-0242ac120004=',
  },
  {
    url: 'turns:fr-turn1.xirsys.com:443?transport=tcp',
    username: 'KBecSzucnd_YXO0UyCUF1vz9tQALvMKgbrUgSC9JzIJGfWrjkd1muL58DzxV66bAAAAAAGM4LHREYW5pbA',
    urls: 'turns:fr-turn1.xirsys.com:443?transport=tcp',
    credential: 'fe881972-4180-11ed-9699-0242ac120004=',
  },
  {
    url: 'turns:fr-turn1.xirsys.com:5349?transport=tcp',
    username: 'KBecSzucnd_YXO0UyCUF1vz9tQALvMKgbrUgSC9JzIJGfWrjkd1muL58DzxV66bAAAAAAGM4LHREYW5pbA',
    urls: 'turns:fr-turn1.xirsys.com:5349?transport=tcp',
    credential: 'fe881972-4180-11ed-9699-0242ac120004=',
  },
]
