import { Reducer } from 'redux'
import { ActionTypes, TAuthState } from './types'

export const initialState: TAuthState = {
  loading: false,
  data: null,
  authorized: false,
  user: null,
}

const reducer: Reducer<TAuthState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SIGN_IN_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.SIGN_IN_S:
      return {
        ...state,
        loading: false,
        authorized: !!action.payload?.access_token,
        errors: undefined,
        data: action.payload,
      }
    case ActionTypes.SIGN_IN_E:
      return { ...state, loading: false, authorized: false, errors: action.payload }

    case ActionTypes.SIGN_OUT_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.SIGN_OUT_S:
      return { ...state, loading: false, errors: undefined }
    case ActionTypes.SIGN_OUT_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.SET_AUTH:
      return {
        ...state,
        user: {
          isAdmin: action.payload?.roles.includes(1),
          isManager: action.payload?.roles.includes(2),
          isConsultant: action.payload?.roles.includes(3),
        },
        authorized: true,
      }
    case ActionTypes.SET_LOGOUT:
      return { ...state, authorized: false }
    default:
      return state
  }
}

export { reducer as AuthReducer }
