import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { message } from 'antd'
import { ActionTypes, TUser, TUserMessage } from './types'
import { callApi } from '../../utils/callApi'
import { API_ROUTES } from '../../utils/apiRoutes'
import * as Actions from './actions'
import { ONLINE } from '../../utils/consts'

function* getCurrentUserInfoWorker(action: ReturnType<typeof Actions.GetCurrentUserInfo.request>) {
  const { callBack } = action.payload as Actions.TypeGetCurrentUserInfoR
  let success: boolean = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: API_ROUTES.getCurrentUser,
    })) as TUser | TUserMessage

    yield put(Actions.GetCurrentUserInfo.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetCurrentUserInfo.error(`${data?.message || data}`))
  } finally {
    if (!success) message.error(`${data?.message || data}`)
    if (callBack) callBack(success, data)
  }
}

function* setOnlineWorker(action: any) {
  const online = action.payload as boolean
  sessionStorage.setItem(ONLINE, String(online))
  yield true
}

function* updateMyPasswordWorker(action: ReturnType<typeof Actions.UpdateMyPassword.request>) {
  const { callBack, ...body } = action.payload as Actions.TypeUpdateMyPasswordR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'put',
      path: API_ROUTES.updateMyPassword,
      data: body,
    })) as TUserMessage

    yield put(Actions.UpdateMyPassword.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.UpdateMyPassword.error(`${data?.detail || data}`))
  } finally {
    if (success) message.success(`Your password was updated`)
    else message.error(`${data?.message || data}`)
    if (callBack) callBack(success, data)
  }
}

function* updateMyInfoWorker(action: ReturnType<typeof Actions.UpdateMyInfo.request>) {
  const { callBack, ...body } = action.payload as Actions.TypeUpdateMyInfoR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'put',
      path: API_ROUTES.updateMyInfo,
      data: body,
    })) as TUserMessage

    yield put(Actions.UpdateMyInfo.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.UpdateMyInfo.error(`${data?.detail || data}`))
  } finally {
    if (success) message.success(`Your info was updated`)
    else message.error(`${data?.message || data}`)
    if (callBack) callBack(success, data)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_CURRENT_USER_INFO_R, getCurrentUserInfoWorker)
  yield takeEvery(ActionTypes.SET_ONLINE, setOnlineWorker)
  yield takeEvery(ActionTypes.UPDATE_MY_PASSWORD_R, updateMyPasswordWorker)
  yield takeEvery(ActionTypes.UPDATE_MY_INFO_R, updateMyInfoWorker)
}

export default function* mySelfSaga() {
  yield all([fork(watchRequest)])
}
