import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { message } from 'antd'
import { ActionTypes, TCallMessage, TCallStatusesMessage } from './types'
import { callApi } from '../../utils/callApi'
import { API_ROUTES } from '../../utils/apiRoutes'
import * as Actions from './actions'
import { getQueryFromObj } from '../../utils/helpers'

function* getCallsWorker(action: ReturnType<typeof Actions.GetCalls.request>) {
  const { callBack, query } = action.payload as Actions.TypeGetCallsR
  let success: boolean = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.calls}${getQueryFromObj(query || {})}`,
    })) as TCallMessage

    yield put(Actions.GetCalls.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetCalls.error(`${data?.message || data}`))
  } finally {
    if (!success) message.error(`${data?.message || data}`)
    if (callBack) callBack(success, data)
  }
}

function* getCallStatusesWorker(action: ReturnType<typeof Actions.GetCallStatuses.request>) {
  const { callBack } = action.payload as Actions.TypeGetCallStatusesR
  let success: boolean = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: API_ROUTES.callStatuses,
    })) as TCallStatusesMessage

    yield put(Actions.GetCallStatuses.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetCallStatuses.error(`${data?.message || data}`))
  } finally {
    if (!success) message.error(`${data?.message || data}`)
    if (callBack) callBack(success, data)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_CALLS_R, getCallsWorker)
  yield takeEvery(ActionTypes.GET_CALL_STATUSES_R, getCallStatusesWorker)
}

export default function* callSaga() {
  yield all([fork(watchRequest)])
}
