import React from 'react'
import { Col, Row } from 'antd'
import { useIntl } from 'react-intl'
import { HouseholdIcon } from '../../../asserts/svg/HouseholdIcon'
import { ToolsIcon } from '../../../asserts/svg/ToolsIcon'
import { ClothingIcon } from '../../../asserts/svg/ClothingIcon'
import { MaterialIcon } from '../../../asserts/svg/MaterialIcon'
import { GreenBlur } from '../GreenBlur'

export const MainBlock3 = () => {
  const intl = useIntl()

  return (
    <Row justify='center' className='br-top-20 br-top-md-0 py-50' style={{ position: 'relative' }}>
      <Col xl={20} xs={22} style={{ zIndex: 100 }}>
        <Row justify='center' style={{ marginBottom: 24 }}>
          <Col span={20}>
            <p className='landing-text-46' style={{ color: '#000' }}>
              {intl.formatMessage({ id: 'Kinds of goods for which our software can be used' })}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={24} className='background-md-gray background-none p-md-40 b-20'>
            <Row justify='center'>
              <Col md={22} xs={24}>
                <Row gutter={[12, 12]}>
                  <Col md={{ span: 6, order: 1 }} xs={{ span: 12, order: 1 }} style={{ textAlign: 'center' }}>
                    <Row justify='center'>
                      <HouseholdIcon style={{ maxWidth: 176 }} width='100%' height='100%' />
                    </Row>
                    <p
                      style={{ maxWidth: 180, margin: '12px auto' }}
                      className='landing-text-28-thin-black wordspace-1000-xs'
                    >
                      {intl.formatMessage({ id: 'Household appliances' })}
                    </p>
                  </Col>
                  <Col md={{ span: 6, order: 2 }} xs={{ span: 12, order: 4 }} style={{ textAlign: 'center' }}>
                    <Row justify='center'>
                      <ToolsIcon style={{ maxWidth: 176 }} width='100%' height='100%' />
                    </Row>
                    <p style={{ maxWidth: 180, margin: '12px auto' }} className='landing-text-28-thin-black'>
                      {intl.formatMessage({ id: 'Tools' })}
                    </p>
                  </Col>
                  <Col md={{ span: 6, order: 3 }} xs={{ span: 12, order: 3 }} style={{ textAlign: 'center' }}>
                    <Row justify='center'>
                      <ClothingIcon style={{ maxWidth: 176 }} width='100%' height='100%' />
                    </Row>
                    <p style={{ maxWidth: 180, margin: '12px auto' }} className='landing-text-28-thin-black'>
                      {intl.formatMessage({ id: 'Clothing' })}
                    </p>
                  </Col>
                  <Col md={{ span: 6, order: 4 }} xs={{ span: 12, order: 2 }} style={{ textAlign: 'center' }}>
                    <Row justify='center'>
                      <MaterialIcon style={{ maxWidth: 176 }} width='100%' height='100%' />
                    </Row>
                    <p
                      style={{ maxWidth: 180, margin: '12px auto' }}
                      className='landing-text-28-thin-black wordspace-1000-xs'
                    >
                      {intl.formatMessage({ id: 'Construction materials' })}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <GreenBlur className='hidden-md' style={{ top: 0, right: -20 }} />
      <GreenBlur className='hidden-md' style={{ bottom: -20, left: 0 }} />
    </Row>
  )
}
