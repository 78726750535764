import React, { CSSProperties, useEffect, useState } from 'react'

interface GreenBlurProps {
  size?: 'big' | 'small'
  blur?: 'big' | 'small'
  style?: CSSProperties
  className?: string
}

// @ts-ignore
const isFirefox = typeof InstallTrigger !== 'undefined'

export const GreenBlur: React.FC<GreenBlurProps> = ({ style, size, blur, className }) => {
  const [SIZE, setSIZE] = useState(272)
  const [BLUR, setBLUR] = useState('blur(262px)')

  const init = () => {
    if (window.innerWidth < 768) {
      setSIZE(74)
      setBLUR('blur(74px)')
    } else {
      if (size === 'small' || !size) setSIZE(255)
      else setSIZE(272)
      if (blur === 'small' || !blur) setBLUR('blur(188px)')
      else setBLUR('blur(262px)')
    }
  }

  useEffect(() => {
    init()
    const onResize = () => init()

    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <div
      className={className || ''}
      style={{
        width: SIZE,
        height: SIZE,
        filter: BLUR,
        WebkitFilter: BLUR,
        msFilter: BLUR,
        position: 'absolute',
        background: isFirefox ? 'rgba(21,148,25,0.3)' : 'rgb(21, 148, 25)',
        zIndex: 1,
        ...style,
      }}
    />
  )
}
