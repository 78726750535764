import { TTradePoint } from '../tradePoints/types'
import { TRetailerTag } from '../retailerTags/types'

export interface TUser {
  id: string
  email: string
  first_name: string
  last_name: string
  phone: string
  disabled: false
  roles: { title: string }[]
  trade_points: {
    id: string
    Name: string
  }[]
  retailer_tags: {
    id: string
    Name: string
  }[]
  retailer: {
    id: string
    name: string
  }
}

export interface TUserQRCode {
  retailer_tag_id: string
  trade_point_id: string
  call_started?: boolean
}

export interface TUserToTradePoints {
  user_id: string
  trade_point_id: string
}

export interface TRetailerTagToUser {
  user_id: string
  retailer_tag_id: string
}

export interface TUserMessage {
  message: string
}

export interface TUsersState {
  loading: boolean
  data: TUser | null
  consultantsData: {
    total: number
    users: TUser[] | null
    trade_points?: TTradePoint[]
    retailer_tags?: TRetailerTag[]
  }
  errors?: string | undefined
}

export enum ActionTypes {
  GET_USERS_R = '@@user/GET_USERS_R',
  GET_USERS_S = '@@user/GET_USERS_S',
  GET_USERS_E = '@@user/GET_USERS_E',

  GET_USER_BY_ID_R = '@@clients/GET_USER_BY_ID_R',
  GET_USER_BY_ID_S = '@@clients/GET_USER_BY_ID_S',
  GET_USER_BY_ID_E = '@@clients/GET_USER_BY_ID_E',

  CREATE_NEW_USER_R = '@@user/CREATE_NEW_USER_R',
  CREATE_NEW_USER_S = '@@user/CREATE_NEW_USER_S',
  CREATE_NEW_USER_E = '@@user/CREATE_NEW_USER_E',

  ADD_USER_TO_TRADE_POINT_R = '@@user/ADD_USER_TO_TRADE_POINT_R',
  ADD_USER_TO_TRADE_POINT_S = '@@user/ADD_USER_TO_TRADE_POINT_S',
  ADD_USER_TO_TRADE_POINT_E = '@@user/ADD_USER_TO_TRADE_POINT_E',

  DELETE_USER_FROM_TRADE_POINT_R = '@@user/DELETE_USER_FROM_TRADE_POINT_R',
  DELETE_USER_FROM_TRADE_POINT_S = '@@user/DELETE_USER_FROM_TRADE_POINT_S',
  DELETE_USER_FROM_TRADE_POINT_E = '@@user/DELETE_USER_FROM_TRADE_POINT_E',

  ADD_RETAILER_TAG_TO_USER_R = '@@user/ADD_RETAILER_TAG_TO_USER_R',
  ADD_RETAILER_TAG_TO_USER_S = '@@user/ADD_RETAILER_TAG_TO_USER_S',
  ADD_RETAILER_TAG_TO_USER_E = '@@user/ADD_RETAILER_TAG_TO_USER_E',

  DELETE_RETAILER_TAG_FROM_USER_R = '@@user/DELETE_RETAILER_TAG_FROM_USER_R',
  DELETE_RETAILER_TAG_FROM_USER_S = '@@user/DELETE_RETAILER_TAG_FROM_USER_S',
  DELETE_RETAILER_TAG_FROM_USER_E = '@@user/DELETE_RETAILER_TAG_FROM_USER_E',

  UPDATE_USER_DATA_R = '@@user/UPDATE_USER_DATA_R',
  UPDATE_USER_DATA_S = '@@user/UPDATE_USER_DATA_S',
  UPDATE_USER_DATA_E = '@@user/UPDATE_USER_DATA_E',

  UPDATE_USER_PASSWORD_R = '@@users/UPDATE_USER_PASSWORD_R',
  UPDATE_USER_PASSWORD_S = '@@users/UPDATE_USER_PASSWORD_S',
  UPDATE_USER_PASSWORD_E = '@@users/UPDATE_USER_PASSWORD_E',

  DELETE_USER_R = '@@users/DELETE_USER_R',
  DELETE_USER_S = '@@users/DELETE_USER_S',
  DELETE_USER_E = '@@users/DELETE_USER_E',
}
