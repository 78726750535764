import React from 'react'
import { Col, Layout, Row } from 'antd'

interface ContainerProps {
  children: React.ReactNode
}

export const Container: React.FC<ContainerProps> = ({ children }) => {
  return (
    <Layout>
      <Layout.Content style={{ overflow: 'hidden' }}>
        <Row className='container-main'>
          <Col span={24}>{children}</Col>
        </Row>
      </Layout.Content>
    </Layout>
  )
}
