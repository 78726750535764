import React, { CSSProperties } from 'react'

interface MissedCallIconProps {
  style?: CSSProperties
  width?: number
  height?: number
}

export const MissedCallIcon: React.FC<MissedCallIconProps> = ({ style, width, height }) => {
  return (
    <svg
      width={width || 280}
      height={height || 280}
      viewBox='0 0 399 364'
      style={{ ...style }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M284.266 6.57129C252.826 10.135 226.579 13.071 225.942 13.0943C225.303 13.1184 233.35 21.7118 243.823 32.192L262.864 51.2462L248.815 65.4409C241.087 73.248 226.701 87.8355 216.846 97.8568L198.927 116.078L143.122 66.4738C109.552 36.633 86.0358 16.2878 84.1016 15.4097C79.3877 13.2685 71.8437 13.4264 66.8879 15.769C56.8551 20.5125 52.6576 32.1469 57.1988 42.6271C58.9075 46.5695 63.1275 50.4886 123.23 103.942C158.544 135.349 188.795 161.745 190.453 162.6C194.856 164.87 200.759 165.271 205.802 163.643C209.75 162.369 212.362 159.925 251.02 121.36L292.047 80.429L311.071 99.4411C321.533 109.897 330.222 118.294 330.378 118.099C330.662 117.746 343.435 2.73231 343.395 0.88826C343.383 0.353945 342.937 -0.0442639 342.402 0.0039567C341.867 0.0521773 315.706 3.00763 284.266 6.57129ZM182.382 199.553C175.273 199.958 158.39 202.151 149.717 203.796C119.556 209.515 90.6439 220.692 66.639 235.913C39.0651 253.396 15.2282 278.47 2.82791 303.034C-0.0613911 308.758 -0.227049 311.967 2.14116 316.331C3.15845 318.206 13.2099 328.652 26.1523 341.287C50.6596 365.212 50.945 365.41 57.5465 363.016C61.9866 361.406 131.576 310.655 132.914 308.051C133.504 306.902 135.97 297.053 138.391 286.165C140.813 275.278 143.343 265.211 144.015 263.796C146.645 258.254 151.005 256.218 166.05 253.505C190.965 249.011 216.945 249.571 240.878 255.119C248.773 256.95 252.118 258.946 254.45 263.216C255.422 264.997 257.967 274.636 260.486 286.074C262.883 296.952 265.45 306.886 266.194 308.149C267.913 311.071 337.994 362.047 341.938 363.243C348.459 365.222 348.752 365.009 373.594 340.32C386.174 327.817 396.913 316.504 397.509 315.125C399.219 311.179 398.817 308.529 395.514 301.969C385.837 282.746 367.584 261.765 346.874 246.058C335.187 237.196 326.474 231.841 312.264 224.79C273.503 205.555 228.943 196.896 182.382 199.553Z'
        fill='#FF4A4A'
      />
    </svg>
  )
}
