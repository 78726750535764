export interface TRetailerTag {
  name: string
  id: string
}

export interface TRetailerTagsData {
  RetailerTagInformation: TRetailerTag[]
  total: number
  retailerTagForEdit?: TRetailerTag | null
}

export interface TRetailerTagsState {
  loading: boolean
  data: TRetailerTagsData
  errors?: string | undefined
}

export interface TRetailerTagMessage {
  message: string
}

export enum ActionTypes {
  GET_RETAILER_TAGS_R = '@@retailerTags/GET_TRADE_POINTS_R',
  GET_RETAILER_TAGS_S = '@@retailerTags/GET_TRADE_POINTS_S',
  GET_RETAILER_TAGS_E = '@@retailerTags/GET_TRADE_POINTS_E',

  GET_RETAILER_TAG_BY_ID_R = '@@retailerTags/GET_RETAILER_TAG_BY_ID_R',
  GET_RETAILER_TAG_BY_ID_S = '@@retailerTags/GET_RETAILER_TAG_BY_ID_S',
  GET_RETAILER_TAG_BY_ID_E = '@@retailerTags/GET_RETAILER_TAG_BY_ID_E',

  CREATE_NEW_RETAILER_TAG_R = '@@retailerTags/CREATE_NEW_RETAILER_TAG_R',
  CREATE_NEW_RETAILER_TAG_S = '@@retailerTags/CREATE_NEW_RETAILER_TAG_S',
  CREATE_NEW_RETAILER_TAG_E = '@@retailerTags/CREATE_NEW_RETAILER_TAG_E',

  UPDATE_RETAILER_TAG_R = '@@retailerTags/UPDATE_RETAILER_TAG_R',
  UPDATE_RETAILER_TAG_S = '@@retailerTags/UPDATE_RETAILER_TAG_S',
  UPDATE_RETAILER_TAG_E = '@@retailerTags/UPDATE_RETAILER_TAG_E',

  DELETE_RETAILER_TAG_R = '@@retailerTags/DELETE_RETAILER_TAG_R',
  DELETE_RETAILER_TAG_S = '@@retailerTags/DELETE_RETAILER_TAG_S',
  DELETE_RETAILER_TAG_E = '@@retailerTags/DELETE_RETAILER_TAG_E',
}
