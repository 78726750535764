export interface TUser {
  id: string
  email: string
  first_name: string
  last_name: string
  disabled: boolean
  retailer: {
    id: string
    name: string
    with_video_record: null
  }
  roles: { title: string }[]
  trade_points: {
    id: string
    Name: string
    address: string
    phone: string
  }[]
  retailer_tags: {
    id: string
    Name: string
  }[]
}

export interface TUserQRCode {
  retailer_tag_id: string
  trade_point_id: string
  call_started?: boolean
}

export interface TUserToTradePoints {
  user_id: string
  trade_point_id: string
}

export interface TUserMessage {
  message: string
}

export interface TMySelfState {
  loading: boolean
  data: TUser | null
  qrCodeData: TUserQRCode | null
  isOnline: boolean
  errors?: string | undefined
}

export enum ActionTypes {
  GET_CURRENT_USER_INFO_R = '@@user/GET_CURRENT_USER_INFO_R',
  GET_CURRENT_USER_INFO_S = '@@user/GET_CURRENT_USER_INFO_S',
  GET_CURRENT_USER_INFO_E = '@@user/GET_CURRENT_USER_INFO_E',

  UPDATE_MY_PASSWORD_R = '@@user/UPDATE_MY_PASSWORD_R',
  UPDATE_MY_PASSWORD_S = '@@user/UPDATE_MY_PASSWORD_S',
  UPDATE_MY_PASSWORD_E = '@@user/UPDATE_MY_PASSWORD_E',

  UPDATE_MY_INFO_R = '@@user/UPDATE_MY_INFO_R',
  UPDATE_MY_INFO_S = '@@user/UPDATE_MY_INFO_S',
  UPDATE_MY_INFO_E = '@@user/UPDATE_MY_INFO_E',

  SET_ONLINE = '@@user/SET_ONLINE',
  SET_QR_CODE_DATA = '@@user/SET_QR_CODE_DATA',
}
