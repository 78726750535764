/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dropdown, Menu, message, Modal, Row, Space, Table, TableColumnsType } from 'antd'
import { Container } from '../../wrappers/Container'
import { TUser } from '../../store/users/types'
import { snakeCaseToString } from '../../utils/helpers'
import { DeleteUser, GetUsers } from '../../store/users/actions'

interface ConsultantsTableProps {
  users: TUser[]
  onChangePass: (data: TUser) => void
  loading: boolean
  pagination: any
  currentPage: number
  total: number
  onPageClick: (page: number, pageSize: number) => void
}

export const ConsultantsTable: React.FC<ConsultantsTableProps> = ({
  users,
  onChangePass,
  loading,
  pagination,
  currentPage,
  total,
  onPageClick,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [modalData, setModalData] = useState<TUser | null>(null)

  const editUser = (user: TUser) => {
    history.push({ pathname: `/consultants/edit/${user.id}` })
  }

  const columns: TableColumnsType<any> = [
    {
      title: '#',
      key: 'index',
      render: (_, __, index) => pagination.current.limit * (currentPage - 1) + index + 1,
    },
    {
      title: 'Consultant',
      render: (_, record) => (
        <Row onClick={() => editUser(record)} style={{ cursor: 'pointer' }}>
          {snakeCaseToString(record.first_name)} {snakeCaseToString(record.last_name)}
        </Row>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Department',
      render: (values) => {
        const names = values?.retailer_tags.map((val: { Name: string }) => snakeCaseToString(val.Name))
        return names.join(', ').toString()
      },
    },
    {
      title: 'Shop',
      render: (values) => {
        const names = values?.trade_points.map((val: { Name: string }) => snakeCaseToString(val.Name))
        return names.join(', ').toString()
      },
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      render: (_, data) => (
        <Space size='middle'>
          <Dropdown
            overlay={(
              <Menu
                items={[
                  {
                    key: '1',
                    label: 'Reset Password',
                    style: { width: 140, padding: '5px 10px' },
                    onClick: () => onChangePass(data),
                  },
                  {
                    key: '2',
                    label: 'Edit',
                    style: { width: 140, padding: '5px 10px' },
                    onClick: () => editUser(data),
                  },
                  {
                    key: '3',
                    label: 'Delete',
                    style: { width: 140, padding: '5px 10px' },
                    onClick: () => setModalData(data),
                  },
                ]}
              />
            )}
          >
            <a>...</a>
          </Dropdown>
        </Space>
      ),
    },
  ]

  const removeUser = (user: TUser) => {
    dispatch(
      DeleteUser.request({
        id: user.id,
        callBack: (success) => {
          if (success) {
            message.success('Consultant removed')
            dispatch(GetUsers.request())
          }
        },
      })
    )
  }

  const handleOk = (data: TUser | null) => {
    if (data) removeUser(data)
    setModalData(null)
  }

  const handleCancel = () => setModalData(null)

  return (
    <Container>
      {users && (
        <Table
          loading={loading}
          dataSource={users}
          rowKey='id'
          columns={columns}
          pagination={{
            onChange: onPageClick,
            defaultCurrent: 1,
            total,
            pageSize: pagination.current.limit,
            current: currentPage,
          }}
          onRow={(record) => {
            return {
              onDoubleClick: () => editUser(record),
            }
          }}
        />
      )}
      <Modal title='Delete consultant' open={!!modalData} onOk={() => handleOk(modalData)} onCancel={handleCancel}>
        {modalData && (
          <div>
            Are you sure to delete{' '}
            <b>
              {modalData?.first_name} {modalData.last_name}
            </b>
            ?
          </div>
        )}
      </Modal>
    </Container>
  )
}
