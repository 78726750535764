import React, { CSSProperties } from 'react'

interface BackgroundProps {
  className?: string
  style?: CSSProperties
}

export const Background: React.FC<BackgroundProps> = ({ className, style }) => {
  return (
    <div
      className={className || ''}
      style={{
        background: '#0D181E',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        ...style,
      }}
    />
  )
}
