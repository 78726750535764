import React from 'react'
import { Col, Row } from 'antd'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { GreenBlur } from '../GreenBlur'
import { Icon1 } from '../../../asserts/svg/MainBlock4/Icon1'
import { Icon2 } from '../../../asserts/svg/MainBlock4/Icon2'
import { Icon3 } from '../../../asserts/svg/MainBlock4/Icon3'
import { Icon4 } from '../../../asserts/svg/MainBlock4/Icon4'
import { Icon5 } from '../../../asserts/svg/MainBlock4/Icon5'
import { Line } from '../Line'
import { AppStore } from '../../../store/applicationState'

const GREEN_BLUR_STYLES = {
  top: -86,
  right: -96,
  width: 143,
  height: 143,
  filter: 'blur(50px)',
}

const GREEN_POINTS_STYLES = {
  width: 14,
  minWidth: 14,
  height: 14,
  borderRadius: '50%',
  background: '#489E38',
}

export const MainBlock4 = () => {
  const intl = useIntl()
  const { General } = useSelector((store: AppStore) => store)

  return (
    <Row
      justify='center'
      className='pt-16 pb-50 py-md-80 br-top-20 br-top-md-0'
      style={{ position: 'relative', overflow: 'hidden' }}
    >
      <Col xl={20} xs={22} style={{ zIndex: 100 }}>
        <Row className='mb-md-32 mb-20' gutter={[0, 24]}>
          <Col md={0} xs={24}>
            <Line style={{ width: '55%', marginLeft: 0 }}>{intl.formatMessage({ id: 'Best service' })}</Line>
          </Col>
          <Col md={16} xs={24}>
            <p className='landing-text-46 text-align-md-left' style={{ color: '#000' }}>
              {intl.formatMessage({ id: 'Advantages of online consulting using the “Remote consultant” system:' })}
            </p>
          </Col>
          <Col md={8} xs={0}>
            <Line style={{ width: '55%' }}>{intl.formatMessage({ id: 'The best service' })}</Line>
          </Col>
        </Row>
      </Col>
      <Col xl={20} md={22} xs={24}>
        <Row
          gutter={[
            { xs: 16, sm: 16, md: 32 },
            { xs: 16, sm: 16, md: 32 },
          ]}
        >
          <Col md={4} xs={0}>
            <Row>
              <Col span={14} className='step-container'>
                <div style={GREEN_POINTS_STYLES} />
                <div style={{ width: 'calc(100% - 38px)', height: 2, background: '#489E38' }} />
                <p className='landing-text-16-green' style={{ marginLeft: 12, fontSize: 26, fontWeight: 700 }}>
                  1
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={20} xs={24}>
            <Row align='middle' justify='space-between' gutter={[0, 16]} className='info-container'>
              <Col sm={11} xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                <Icon1 width='100%' height='100%' style={{ aspectRatio: '9/4' }} />
              </Col>
              <Col sm={12} xs={24}>
                <p className='landing-text-20 text-align-center text-align-md-left'>
                  {intl.formatMessage({ id: 'Saving on the number of staff' })}
                </p>
              </Col>
              <GreenBlur className='hidden block-md' style={GREEN_BLUR_STYLES} />
            </Row>
          </Col>
          <Col md={4} xs={0}>
            <Row>
              <Col span={14} className='step-container'>
                <div style={GREEN_POINTS_STYLES} />
                <div style={{ width: 'calc(100% - 38px)', height: 2, background: '#489E38' }} />
                <p className='landing-text-16-green' style={{ marginLeft: 12, fontSize: 26, fontWeight: 700 }}>
                  2
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={20} xs={24}>
            <Row align='middle' justify='space-between' gutter={[0, 16]} className='info-container'>
              <Col sm={11} xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                <Icon2 locale={General.locale} width='100%' height='100%' style={{ aspectRatio: '9/4' }} />
              </Col>
              <Col sm={12} xs={24}>
                <p className='landing-text-20 text-align-center text-align-md-left'>
                  {intl.formatMessage({ id: 'Possibility of hiring one consultant who speaks a foreign language' })}
                </p>
              </Col>
              <GreenBlur className='hidden block-md' style={GREEN_BLUR_STYLES} />
            </Row>
          </Col>
          <Col md={4} xs={0}>
            <Row>
              <Col span={14} className='step-container'>
                <div style={GREEN_POINTS_STYLES} />
                <div style={{ width: 'calc(100% - 38px)', height: 2, background: '#489E38' }} />
                <p className='landing-text-16-green' style={{ marginLeft: 12, fontSize: 26, fontWeight: 700 }}>
                  3
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={20} xs={24}>
            <Row align='middle' justify='space-between' gutter={[0, 16]} className='info-container'>
              <Col sm={11} xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                <Icon3 width='100%' height='100%' style={{ aspectRatio: '9/4' }} />
              </Col>
              <Col sm={12} xs={24}>
                <p className='landing-text-20 text-align-center text-align-md-left'>
                  {intl.formatMessage({ id: 'The ability to continue to effectively advise customers/visitors' })}
                </p>
              </Col>
              <GreenBlur className='hidden block-md' style={GREEN_BLUR_STYLES} />
            </Row>
          </Col>
          <Col md={4} xs={0}>
            <Row>
              <Col span={14} className='step-container'>
                <div style={GREEN_POINTS_STYLES} />
                <div style={{ width: 'calc(100% - 38px)', height: 2, background: '#489E38' }} />
                <p className='landing-text-16-green' style={{ marginLeft: 12, fontSize: 26, fontWeight: 700 }}>
                  4
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={20} xs={24}>
            <Row align='middle' justify='space-between' gutter={[0, 16]} className='info-container'>
              <Col sm={11} xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                <Icon4 width='100%' height='100%' style={{ aspectRatio: '9/4' }} />
              </Col>
              <Col sm={12} xs={24}>
                <p className='landing-text-20 text-align-center text-align-md-left'>
                  {intl.formatMessage({ id: 'Simplicity and ease of use for both customers and consultants.' })}
                </p>
              </Col>
              <GreenBlur className='hidden block-md' style={GREEN_BLUR_STYLES} />
            </Row>
          </Col>
          <Col md={4} xs={0}>
            <Row>
              <Col span={14} className='step-container'>
                <div style={GREEN_POINTS_STYLES} />
                <div style={{ width: 'calc(100% - 38px)', height: 2, background: '#489E38' }} />
                <p className='landing-text-16-green' style={{ marginLeft: 12, fontSize: 26, fontWeight: 700 }}>
                  5
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={20} xs={24}>
            <Row align='middle' justify='space-between' gutter={[0, 16]} className='info-container'>
              <Col sm={11} xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                <Icon5 locale={General.locale} width='100%' height='100%' style={{ aspectRatio: '9/4' }} />
              </Col>
              <Col sm={12} xs={24}>
                <p className='landing-text-20 text-align-center text-align-md-left'>
                  {intl.formatMessage({ id: 'The ability for the customer to use web application' })}
                </p>
              </Col>
              <GreenBlur className='hidden block-md' style={GREEN_BLUR_STYLES} />
            </Row>
          </Col>
        </Row>
      </Col>
      <GreenBlur className='hidden block-md' style={{ top: 0, right: 0 }} blur='big' />
      <GreenBlur style={{ top: '60%', left: 0 }} blur='big' />
    </Row>
  )
}
