import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Table,
  TableColumnsType,
  TimePicker,
  Typography,
} from 'antd'
import moment from 'moment'
import { DownloadOutlined } from '@ant-design/icons'
import { Container } from '../wrappers/Container'
import { AppStore } from '../store/applicationState'
import { snakeCaseToString } from '../utils/helpers'
import { GetCalls, GetCallStatuses } from '../store/calls/actions'
import { TTradePoint } from '../store/tradePoints/types'
import { TRetailerTag } from '../store/retailerTags/types'
import { GetRetailerTags } from '../store/retailerTags/actions'
import { GetTradePoints } from '../store/tradePoints/actions'
import { callApi } from '../utils/callApi'
import { downloadFile } from '../utils/utils'

interface CallStatisticsViewProps {}

export const CallStatisticsView: React.FC<CallStatisticsViewProps> = () => {
  const dispatch = useDispatch()
  const { Calls, TradePoints, RetailerTags } = useSelector((store: AppStore) => store)

  const [form] = Form.useForm()
  const pagination = useRef<{ start: number; limit: number }>({ start: 0, limit: 10 })
  const currentPage = Math.round(pagination.current.start / pagination.current.limit + 1)

  const columns: TableColumnsType<any> = [
    {
      title: '#',
      key: 'index',
      render: (_, __, index) => pagination.current.limit * (currentPage - 1) + index + 1,
    },
    {
      title: 'Consultant',
      render: (_, record) => (
        <Row>
          {record && record.user && (
            <div>
              {snakeCaseToString(record?.user?.first_name)} {snakeCaseToString(record?.user?.last_name)}
            </div>
          )}
        </Row>
      ),
    },
    {
      title: 'Date / Time',
      render: (_, record) => {
        const date = moment(`${record?.date}T${record?.time}`)
        return moment(date).add(moment().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: 'Call duration',
      dataIndex: 'call_duration',
      key: 'call_duration',
      render: (value) => {
        const minutes = Math.floor(value / 60)
        const seconds = value % 60
        return `${minutes} min, ${seconds} sec`
      },
    },
    {
      title: 'Customer waiting time',
      dataIndex: 'customer_waiting_time',
      key: 'customer_waiting_time',
      render: (value) => {
        const minutes = Math.floor(value / 60)
        const seconds = value % 60
        return `${minutes} min, ${seconds} sec`
      },
    },
    {
      title: 'Consultant accept call time',
      dataIndex: 'consultant_accept_call_time',
      key: 'consultant_accept_call_time',
      render: (value) => {
        const minutes = Math.floor(value / 60)
        const seconds = value % 60
        return `${minutes} min, ${seconds} sec`
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Shop',
      render: (_, record) => (record?.trade_point?.name ? snakeCaseToString(record?.trade_point?.name) : null),
    },
    {
      title: 'Department',
      render: (_, record) => (record?.retailer_tag?.name ? snakeCaseToString(record?.retailer_tag?.name) : null),
    },
    {
      title: 'Call Record',
      dataIndex: 'file_file_attachment',
      key: 'file_file_attachment',
      render: (file) => {
        if (file?.id)
          return (
            <Button
              shape='circle'
              onClick={() => {
                callApi({ method: 'get', path: `/admin/file-attachments/${file.id}` })
                  .then((f) => downloadFile('video/mp4', f, file.id))
                  .catch(({ data }) => message.error(data.message))
              }}
              icon={<DownloadOutlined />}
            />
          )
        return null
      },
    },
  ]

  useEffect(() => {
    form.submit()
    dispatch(GetRetailerTags.request())
    dispatch(GetTradePoints.request())
    dispatch(GetCallStatuses.request())
  }, [])

  const onPageClick = (page: number, pageSize: number) => {
    pagination.current.start = (page - 1) * pagination.current.limit
    pagination.current.limit = pageSize
    form.submit()
  }

  const onValuesChange = () => {
    pagination.current.start = 0
    form.submit()
  }

  const onFinish = (values: any) => {
    dispatch(
      GetCalls.request({
        query: {
          limit: pagination.current.limit,
          offset: pagination.current.start,
          q: values.search,
          filter_status: values.status,
          filter_trade_point_id: values.tradePoints,
          filter_tag_id: values.retailerTags,
          filter_date_from: values.rangeDate ? moment(values.rangeDate[0]).utc().format().split('T')[0] : undefined,
          filter_date_to: values.rangeDate ? moment(values.rangeDate[1]).utc().format().split('T')[0] : undefined,
          filter_time_from: values.rangeTime
            ? moment(values.rangeTime[0]).utc().format().split('T')[1].split('Z')[0]
            : undefined,
          filter_time_to: values.rangeTime
            ? moment(values.rangeTime[1]).utc().format().split('T')[1].split('Z')[0]
            : undefined,
        },
      })
    )
  }

  const onReset = () => {
    form.resetFields()
    form.submit()
  }

  return (
    <Container>
      <Row className='pageTitle'>
        <Typography.Title level={3}>Call statistics</Typography.Title>
        <div className='results'>
          {Calls?.data?.total} {Calls?.data?.total === 1 ? 'result' : 'results'}
        </div>
      </Row>
      <Row>
        <Col>
          <Typography.Title level={5}>Filter</Typography.Title>
        </Col>
      </Row>
      <Form
        form={form}
        className='gx-form-inline-label-up gx-form-row0 '
        size='large'
        onFinish={(values) => onFinish(values)}
        onValuesChange={(data) => (data?.search ? null : onValuesChange())}
      >
        <Row justify='space-between' gutter={[6, 12]}>
          <Col lg={5} xs={24}>
            <Form.Item name='search' initialValue='' noStyle>
              <Input.Search
                placeholder='Search'
                allowClear
                enterButton='Search'
                size='middle'
                onSearch={onValuesChange}
              />
            </Form.Item>
          </Col>
          <Col lg={4} md={11} xs={24}>
            <Form.Item name='rangeDate' noStyle>
              <DatePicker.RangePicker size='middle' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col lg={4} md={11} xs={24}>
            <Form.Item name='rangeTime' noStyle>
              <TimePicker.RangePicker format='HH:mm' size='middle' style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col lg={3} md={7} xs={24}>
            <Form.Item name='status' noStyle>
              <Select placeholder='Status' bordered={false} showArrow mode='multiple' style={{ width: '100%' }}>
                {Calls.data.call_statuses?.map((option) => (
                  <Select.Option key={option} value={option}>
                    {snakeCaseToString(option)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={2} md={7} xs={24}>
            <Form.Item name='tradePoints' noStyle>
              <Select placeholder='Shop' bordered={false} showArrow mode='multiple' style={{ width: '100%' }}>
                {TradePoints.data.trade_points?.map((option: TTradePoint) => (
                  <Select.Option key={option.id} value={option.id}>
                    {snakeCaseToString(option.name)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={3} md={7} xs={24}>
            <Form.Item name='retailerTags' noStyle>
              <Select placeholder='Department' bordered={false} showArrow mode='multiple' style={{ width: '100%' }}>
                {RetailerTags?.data?.RetailerTagInformation?.map((option: TRetailerTag) => (
                  <Select.Option key={option.id} value={option.id}>
                    {snakeCaseToString(option.name)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={1} xs={24} className='reset-btn-container'>
            <Form.Item noStyle>
              <Button size='small' type='text' onClick={onReset}>
                Reset
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        dataSource={Calls?.data?.calls}
        style={{ marginTop: '20px' }}
        loading={Calls.loading}
        rowKey='id'
        columns={columns}
        pagination={{
          onChange: onPageClick,
          defaultCurrent: 1,
          total: Calls?.data.calls.length ? Calls?.data?.total : 0,
          pageSize: pagination.current.limit,
          current: currentPage,
        }}
      />
    </Container>
  )
}
