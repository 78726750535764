/* eslint-disable no-console */
/* eslint-disable */

import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { STUN2 } from '../utils/consts'
import { SetQRCodeData } from '../store/myself/actions'

interface CallViaBackProps {
  onWebrtcSend: (sdp: string) => void
  onWebrtcReceive: (sdp: string) => void
  onWebrtcCandidate: (e: RTCPeerConnectionIceEvent) => void
  setConnecting: (value: boolean) => void
  setCallIsFinished: (value: boolean) => void
  setCallIsStarted: (value: boolean) => void
  setCallTime: React.Dispatch<React.SetStateAction<number>>
  sendCallDuration: (value: number) => void
  localMediaStream: React.MutableRefObject<MediaStream | null>
}

export const useCallViaBack = ({
  onWebrtcSend,
  onWebrtcReceive,
  onWebrtcCandidate,
  setConnecting,
  setCallIsFinished,
  setCallIsStarted,
  setCallTime,
  sendCallDuration,
  localMediaStream,
}: CallViaBackProps) => {
  const dispatch = useDispatch()

  const peerConnections = useRef<{ [key: string]: RTCPeerConnection }>({})

  const remoteVideoBackRef = useRef<HTMLVideoElement>(null)
  const remoteSoundBackRef = useRef<HTMLAudioElement>(null)

  const getTime = (): Promise<number> =>
    new Promise((resolve) =>
      setCallTime((prev) => {
        resolve(prev)
        return prev
      })
    )

  const finishCall = () => {
    dispatch(SetQRCodeData(null))
    setCallIsFinished(true)
    setCallIsStarted(false)
    // eslint-disable-next-line no-restricted-syntax
    for (const [, value] of Object.entries(peerConnections.current)) value.close()
    localMediaStream.current?.getTracks().forEach((track) => track.stop())

    getTime().then((val) => {
      sendCallDuration(val)
      setCallTime(0)
    })
  }

  const onWebRTCConnectionChange = (pc: any) => {
    console.log('pc.target.connectionState', pc.target.connectionState)
    switch (pc.target.connectionState) {
      case 'new':
      case 'connected':
      case 'failed':
        break
      case 'disconnected':
      case 'closed':
        finishCall()
        break
    }
  }

  // const initSender = async () => {
  //   try {
  //     console.log('SENDER init')
  //     peerConnections.current.sender = new RTCPeerConnection({ iceServers: STUN3 })
  //
  //     console.log('SENDER start add track')
  //     localMediaStream.current?.getTracks().forEach((track) => {
  //       peerConnections.current.sender.addTrack(track, localMediaStream.current!)
  //     })
  //     console.log('SENDER end add track')
  //
  //     peerConnections.current.sender.onconnectionstatechange = onWebRTCConnectionChange
  //
  //     const desc = await peerConnections.current.sender.createOffer()
  //     await peerConnections.current.sender.setLocalDescription(desc)
  //     console.log('SENDER offer created')
  //
  //     peerConnections.current.sender.onicecandidate = async (event) => {
  //       if (!event.candidate) {
  //         console.log('event.candidate', event.candidate)
  //         onWebrtcSend(btoa(JSON.stringify(peerConnections.current.sender.localDescription)))
  //       }
  //     }
  //   } catch (e) {
  //     console.warn(e)
  //   }
  // }
  //
  // const initReceiver = async () => {
  //   try {
  //     console.log('RECEIVER init')
  //     peerConnections.current.receiver = new RTCPeerConnection({ iceServers: STUN3 })
  //
  //     console.log('RECEIVER start ontrack')
  //     let tracksNumber: number = 0
  //     peerConnections.current.receiver.ontrack = ({ streams: [remoteStream] }: RTCTrackEvent) => {
  //       console.log('ONTRACK!!!!!!')
  //       tracksNumber += 1
  //       if (tracksNumber === 2) {
  //         console.log('remoteStream', remoteStream)
  //         // video & audio tracks received
  //         tracksNumber = 0
  //         if (remoteVideoBackRef.current) remoteVideoBackRef.current.srcObject = remoteStream
  //         if (remoteSoundBackRef.current) remoteSoundBackRef.current.srcObject = remoteStream
  //         setConnecting(false)
  //       }
  //     }
  //     console.log('RECEIVER end ontrack')
  //
  //     peerConnections.current.receiver.onconnectionstatechange = onWebRTCConnectionChange
  //
  //     peerConnections.current.receiver.addTransceiver('video', { direction: 'sendrecv' })
  //     peerConnections.current.receiver.addTransceiver('audio', { direction: 'sendrecv' })
  //
  //     const offer: RTCSessionDescriptionInit = await peerConnections.current.receiver.createOffer()
  //     await peerConnections.current.receiver.setLocalDescription(offer)
  //     console.log('RECEIVER offer created')
  //
  //     peerConnections.current.receiver.onicecandidate = async (event: RTCPeerConnectionIceEvent) => {
  //       if (!event.candidate) {
  //         console.log('event.candidate', event.candidate)
  //         onWebrtcReceive(btoa(JSON.stringify(peerConnections.current.receiver.localDescription)))
  //       }
  //     }
  //   } catch (e) {
  //     console.warn(e)
  //   }
  // }

  const addNewBackPeer = async ({ peerID }: { peerID: string }) => {
    try {
      peerConnections.current[peerID] = new RTCPeerConnection({ iceServers: STUN2 })

      peerConnections.current[peerID].ontrack = function ({ track, streams: [remoteStream] }: RTCTrackEvent) {
        if (track.kind === 'audio') return

        console.log('remoteStream', remoteStream)
        // video & audio tracks received
        if (remoteVideoBackRef.current) remoteVideoBackRef.current.srcObject = remoteStream
        if (remoteSoundBackRef.current) remoteSoundBackRef.current.srcObject = remoteStream
        setConnecting(false)

        remoteStream.onremovetrack = () => {
          if (remoteVideoBackRef.current) remoteVideoBackRef.current.srcObject = null
        }
      }
      console.log('addNewBackPeer')

      localMediaStream.current?.getTracks().forEach((track) => {
        console.log('getTracks')

        peerConnections.current[peerID].addTrack(track, localMediaStream.current!)
      })

      peerConnections.current[peerID].onicecandidate = (e) => {
        console.log('onicecandidate')
        if (!e.candidate) return
        console.log('onicecandidate -324334')
        // onWebrtcCandidate(e)
      }

      const offer: RTCSessionDescriptionInit = await peerConnections.current[peerID].createOffer()
      await peerConnections.current[peerID].setLocalDescription(offer)
      onWebrtcSend(btoa(JSON.stringify(peerConnections.current[peerID].localDescription)))

      return true
    } catch (e) {
      return console.error('onAddPeer', e)
    }
  }

  const sendRemoteMediaViaBack = async ({ sdp }: any, send: boolean) => {
    try {
      if (send) {
        await peerConnections.current.sender.setRemoteDescription(JSON.parse(atob(sdp)))
        console.log('SENDER set remote desc')
      } else {
        await peerConnections.current.receiver.setRemoteDescription(JSON.parse(atob(sdp)))
        console.log('RECEIVER set remote desc')
      }
    } catch (e) {
      console.warn('onSetRemoteMediaViaBack', e)
    }
  }

  return {
    // initSender,
    // initReceiver,
    addNewBackPeer,
    sendRemoteMediaViaBack,
    finishCall,
    remoteVideoBackRef,
    remoteSoundBackRef,
    peerConnections,
  }
}
