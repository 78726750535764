/* eslint-disable no-console */
import { useRef } from 'react'
// @ts-ignore
import callSound from '../asserts/sound/call-sound.wav'

export const useCallSound = () => {
  const sound = useRef(new Audio(callSound))
  const timer = useRef<any>(null)

  sound.current.loop = true

  const playSound = async () => {
    try {
      sound.current.currentTime = 0
      // await sound.current.play()
    } catch (e: any) {
      console.warn(e)
      timer.current = setTimeout(() => playSound(), 1000)
    }
  }

  const stopSound = async () => {
    try {
      clearTimeout(timer.current)
      await sound.current.pause()
    } catch (e: any) {
      console.warn(e)
      setTimeout(() => stopSound(), 1000)
    }
  }

  return {
    playSound,
    stopSound,
  }
}
