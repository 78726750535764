import React, { CSSProperties } from 'react'

interface MicrophoneEndIconProps {
  style?: CSSProperties
  width?: number
  height?: number
}

export const MicrophoneEndIcon: React.FC<MicrophoneEndIconProps> = ({ style, width, height }) => {
  return (
    <svg
      width={width || 34}
      height={height || 34}
      style={{ ...style }}
      viewBox='0 0 62 62'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.3941 5.74908C12.0392 2.99225 15.725 1.6026 22.2127 0.536191C25.0886 0.0635343 27.4142 -0.0595126 31.8586 0.0244718C54.028 0.44537 62.0001 8.66998 62.0001 31.1192C62.0001 51.2198 55.9103 59.3575 39.1544 61.6446C35.679 62.1192 25.9518 62.1182 22.5457 61.6436C6.20111 59.3663 0.022886 51.0489 5.88996e-05 31.292C-0.0147116 18.4249 2.74847 10.0167 8.3941 5.74908Z'
        fill='#FF3B30'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.087 17.4436C19.8623 18.0162 19.3016 17.2977 29.9482 30.0781C38.6778 40.5568 40.1032 42.0096 40.1032 40.4281C40.1032 39.7733 21.2102 17 20.667 17C20.4439 17 20.1829 17.1995 20.087 17.4436ZM27.9289 17.7059C27.0704 18.1123 26.0309 18.8024 25.6186 19.2399L24.8694 20.0352L28.1084 23.933C34.862 32.0599 36.3363 33.7797 36.5498 33.7797C36.6709 33.7797 37.0958 33.1277 37.4944 32.3305C38.4693 30.3813 38.7128 24.966 37.9424 22.3753C36.6681 18.0912 31.8559 15.8473 27.9289 17.7059ZM23.4025 27.1648C23.4025 28.564 23.6185 30.4142 23.8842 31.288C24.688 33.9337 26.904 35.8619 29.7385 36.3814C31.0689 36.6252 33.1666 36.3201 33.0215 35.9036C32.8538 35.4219 23.8842 24.6597 23.6366 24.6429C23.5077 24.6341 23.4025 25.7691 23.4025 27.1648ZM20.9702 32.0254C20.7335 32.3039 20.722 32.8311 20.9321 33.7454C21.3376 35.5085 21.8935 36.4876 23.1419 37.6353C24.6171 38.992 25.916 39.5555 28.0771 39.7761L29.9579 39.9683V41.132V42.2952L27.6947 42.3849C25.5134 42.4712 25.4316 42.502 25.4316 43.2373V44H30.8944H36.3572V43.2373C36.3572 42.502 36.2754 42.4712 34.094 42.3849L31.8309 42.2952V41.1309V39.9668L33.5805 39.7901C35.6255 39.5838 35.7722 39.4899 35.3826 38.6369C35.1083 38.0362 34.8304 37.9934 30.8853 37.9474C27.3008 37.9056 26.4985 37.8052 25.4494 37.2671C23.9163 36.4812 23.1809 35.5345 22.4869 33.4547C21.9132 31.7346 21.5264 31.3701 20.9702 32.0254ZM39.7922 32.0254C39.718 32.2353 39.432 33.0246 39.157 33.7797C38.3204 36.077 38.2995 36.2081 38.6969 36.6761C39.1932 37.2606 39.4367 37.0923 40.1147 35.6962C40.9226 34.0319 41.2213 32.4992 40.8277 32.0358C40.4056 31.5388 39.9664 31.5345 39.7922 32.0254Z'
        fill='white'
      />
    </svg>
  )
}
