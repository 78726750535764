import React, { useEffect, useState } from 'react'
import { Button, Col, message, Row, Switch, Tag, Tooltip, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { AppStore } from '../store/applicationState'
import { HumanImage } from '../asserts/images/HumanImage'
import { CallImage } from '../asserts/images/CallImage'
import { useSocketMakeCall } from '../hooks/useSocketMakeCall'
import { MissedCallIcon } from '../asserts/svg/MissedCallIcon'
import { EndCallIcon } from '../asserts/svg/EndCallIcon'
import { MiniCamera } from '../components/makeCall/MiniCamera'
import { ContainerSimple } from '../wrappers/ContainerSimple'
import { SetOnline } from '../store/myself/actions'

interface ConsultantProps {}

export const Consultant: React.FC<ConsultantProps> = () => {
  const dispatch = useDispatch()
  const { MySelf } = useSelector((store: AppStore) => store)

  const {
    onOnline,
    onAcceptCall,
    onCancelCall,
    onOffline,

    finishCall,
    isCalling,
    isOnline,
    callIsStarted,
    callTime,
    timeout,
    connecting,

    localVideoRef,
    remoteVideoRef,
  } = useSocketMakeCall()

  const [loading, setLoading] = useState(false)

  const setIsOnline = (value: boolean) => dispatch(SetOnline(value))

  useEffect(() => {
    if (MySelf.isOnline) {
      setTimeout(() => {
        setLoading(true)
        onOnline((code) => setTimeout(() => setLoading(code !== 200), 500))
      }, 500)
    } else {
      setLoading(false)
      onOffline()
    }
  }, [MySelf.isOnline, onOnline, onOffline])

  useEffect(() => {
    if (timeout) setIsOnline(false)
  }, [timeout])

  useEffect(() => {
    if (connecting) {
      const hide = message.loading('Connecting...', 0)
      return () => hide()
    }
  }, [connecting])

  useEffect(() => {
    return () => {
      setIsOnline(false)
    }
  }, [])

  if (timeout) {
    return (
      <ContainerSimple>
        <Row align='middle' style={{ height: '100%' }}>
          <Col span={24}>
            <Row justify='center'>
              <Col>
                <MissedCallIcon />
              </Col>
            </Row>
            <Row justify='center'>
              <Col style={{ textAlign: 'center' }}>
                <Typography.Title level={2}>Missed Call</Typography.Title>
                <Typography.Text style={{ fontSize: 20 }}>We have put you in offline mode.</Typography.Text>
                <br />
                <Typography.Text style={{ fontSize: 20 }}>
                  To start receiving calls, confirm that you are online by toggling the toggle switch
                </Typography.Text>
              </Col>
            </Row>
            <Row justify='center'>
              <Col style={{ marginTop: 30 }}>
                <Switch className='medium-switch' checked={MySelf.isOnline} loading={loading} onChange={setIsOnline} />
              </Col>
            </Row>
          </Col>
        </Row>
      </ContainerSimple>
    )
  }

  if (isCalling) {
    return (
      <ContainerSimple>
        <Row align='middle' style={{ height: '100%' }}>
          <Col span={24}>
            <Row justify='center' gutter={48}>
              <Col>
                <Typography.Title level={5} style={{ marginBottom: 3 }}>
                  Shop
                </Typography.Title>
                <Tag color='#E6E6E6' style={{ padding: '4px 14px', fontSize: '14px', width: '100%', color: '#000' }}>
                  <Typography.Text>
                    {MySelf.data?.trade_points.find((trade) => trade.id === MySelf.qrCodeData?.trade_point_id)?.Name}
                  </Typography.Text>
                </Tag>
              </Col>
              <Col>
                <Typography.Title level={5} style={{ marginBottom: 3 }}>
                  Department
                </Typography.Title>
                <Tag color='#E6E6E6' style={{ padding: '4px 14px', fontSize: '14px', width: '100%', color: '#000' }}>
                  <Typography.Text>
                    {MySelf.data?.retailer_tags.find((tag) => tag.id === MySelf.qrCodeData?.retailer_tag_id)?.Name}
                  </Typography.Text>
                </Tag>
              </Col>
            </Row>
            <Row justify='center' style={{ marginTop: 50 }}>
              <Col>
                <CallImage height={260} width={260} />
              </Col>
            </Row>
            <Row justify='center' style={{ marginTop: 40 }} gutter={[24, 0]}>
              <Col xl={3} md={5}>
                <Button disabled={connecting} style={{ width: '100%' }} size='large' onClick={() => onCancelCall()}>
                  Cancel
                </Button>
              </Col>
              <Col xl={3} md={5}>
                <Button
                  disabled={connecting}
                  style={{ width: '100%' }}
                  type='primary'
                  size='large'
                  onClick={() => onAcceptCall()}
                >
                  Take a call
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </ContainerSimple>
    )
  }

  if (callIsStarted) {
    return (
      <ContainerSimple style={{ background: '#000' }}>
        <div style={{ width: '100%', height: '100%', position: 'relative', maxHeight: '100vh' }}>
          <video width='100%' height='100%' ref={remoteVideoRef} autoPlay playsInline />
          <div style={{ position: 'absolute', bottom: 40, right: 20 }}>
            <MiniCamera videoRef={localVideoRef} turned />
          </div>
          <div style={{ position: 'absolute', right: 30, top: 30 }}>
            <Tag color='#000' style={{ padding: '6px 12px', fontSize: '20px' }}>
              {new Date(callTime * 1000).toUTCString().split(' ')[4]}
            </Tag>
          </div>
          <div style={{ position: 'absolute', left: '50%', bottom: 30, transform: 'translateX(-50%)' }}>
            <Tooltip title='End the call'>
              <Button size='large' type='primary' shape='circle' style={{ padding: '3px' }} onClick={finishCall}>
                <EndCallIcon />
              </Button>
            </Tooltip>
          </div>
        </div>
      </ContainerSimple>
    )
  }

  return (
    <ContainerSimple>
      {!isOnline || loading ? (
        <>
          <Row justify='center' style={{ marginTop: 100 }}>
            <Col>
              <Typography.Title level={1} style={{ textAlign: 'center' }}>
                Get started.
              </Typography.Title>
              <Typography.Text style={{ fontSize: 20 }}>Turn on online status so you can receive calls</Typography.Text>
            </Col>
          </Row>
          <Row justify='center'>
            <Col style={{ marginTop: 80 }}>
              <Switch className='big-switch' checked={MySelf.isOnline} loading={loading} onChange={setIsOnline} />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row justify='center'>
            <Col style={{ marginTop: 100 }}>
              <HumanImage width={260} height={260} />
            </Col>
          </Row>
          <Row justify='center'>
            <Col style={{ marginTop: 40 }}>
              <Typography.Title level={1}>Expect a call</Typography.Title>
            </Col>
          </Row>
        </>
      )}
    </ContainerSimple>
  )
}
