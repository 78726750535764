import { Reducer } from 'redux'
import { ActionTypes, TUsersState } from './types'

export const initialState: TUsersState = {
  loading: false,
  data: null,
  consultantsData: {
    total: 0,
    users: [],
  },
}

const reducer: Reducer<TUsersState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_USERS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_USERS_S:
      return {
        ...state,
        loading: false,
        consultantsData: { ...state.consultantsData, ...action.payload },
      }
    case ActionTypes.GET_USERS_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.GET_USER_BY_ID_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_USER_BY_ID_S:
      return { ...state, loading: false, consultantsData: action.payload }
    case ActionTypes.GET_USER_BY_ID_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.CREATE_NEW_USER_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.CREATE_NEW_USER_S:
      return { ...state, loading: false, data: action.payload }
    case ActionTypes.CREATE_NEW_USER_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.ADD_USER_TO_TRADE_POINT_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.ADD_USER_TO_TRADE_POINT_S:
      return { ...state, loading: false, data: action.payload }
    case ActionTypes.ADD_USER_TO_TRADE_POINT_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.DELETE_USER_FROM_TRADE_POINT_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.DELETE_USER_FROM_TRADE_POINT_S:
      return { ...state, loading: false, data: action.payload }
    case ActionTypes.DELETE_USER_FROM_TRADE_POINT_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.ADD_RETAILER_TAG_TO_USER_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.ADD_RETAILER_TAG_TO_USER_S:
      return { ...state, loading: false, data: action.payload }
    case ActionTypes.ADD_RETAILER_TAG_TO_USER_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.DELETE_RETAILER_TAG_FROM_USER_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.DELETE_RETAILER_TAG_FROM_USER_S:
      return { ...state, loading: false, data: action.payload }
    case ActionTypes.DELETE_RETAILER_TAG_FROM_USER_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.UPDATE_USER_DATA_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.UPDATE_USER_DATA_S:
      return { ...state, loading: false }
    case ActionTypes.UPDATE_USER_DATA_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.UPDATE_USER_PASSWORD_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.UPDATE_USER_PASSWORD_S:
      return { ...state, loading: false }
    case ActionTypes.UPDATE_USER_PASSWORD_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.DELETE_USER_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.DELETE_USER_S:
      return { ...state, loading: false }
    case ActionTypes.DELETE_USER_E:
      return { ...state, loading: false, errors: action.payload }

    default:
      return state
  }
}

export { reducer as UsersReducer }
