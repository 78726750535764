import React from 'react'
import { Col, Row } from 'antd'
import { useIntl } from 'react-intl'
import { EllipseBackgroundIcon } from '../../../asserts/svg/MainBlock5/EllipseBackgroundIcon'
import { GreenBlur } from '../GreenBlur'
import implementation1 from '../../../asserts/png/landing-implementation1.png'
import implementation2 from '../../../asserts/png/landing-implementation2.png'

export const MainBlock5 = () => {
  const intl = useIntl()

  return (
    <Row
      justify='center'
      className='py-50 pt-md-100 pb-md-80 b-20 b-md-0 background-black background-md-none'
      style={{ position: 'relative' }}
    >
      <Col xl={20} xs={22} style={{ zIndex: 100 }} id='about'>
        <Row justify='center' className='mb-md-32 mb-20'>
          <Col md={16} xs={24}>
            <p className='landing-text-46'>
              {intl.formatMessage({ id: '“Remote consultant” can be implemented in two alternatives:' })}
            </p>
          </Col>
        </Row>
      </Col>
      <Col xl={20} md={22} xs={24} style={{ zIndex: 100 }}>
        <Row gutter={[32, 16]}>
          <Col md={12} xs={24}>
            <div className='implementation-container'>
              <div style={{ marginBottom: 14, position: 'relative' }}>
                <img
                  width='100%'
                  className='b-20'
                  loading='lazy'
                  src={implementation1}
                  alt='landing-implementation1_image'
                />
                <div className='step2-container' style={{ left: 0, top: 0 }}>
                  <p className='landing-text-16-green' style={{ fontSize: 34, fontWeight: 700, color: '#fff' }}>
                    1
                  </p>
                </div>
              </div>
              <p className='landing-text-20-thin text-md-black'>
                {intl.formatMessage({ id: 'The first is with special physical device' })}
              </p>
            </div>
          </Col>
          <Col md={12} xs={24}>
            <div className='implementation-container'>
              <div style={{ marginBottom: 14, position: 'relative' }}>
                <img
                  width='100%'
                  className='b-20'
                  loading='lazy'
                  src={implementation2}
                  alt='landing-implementation1_image'
                />
                <div className='step2-container' style={{ right: 0, top: 0 }}>
                  <p className='landing-text-16-green' style={{ fontSize: 34, fontWeight: 700, color: '#fff' }}>
                    2
                  </p>
                </div>
              </div>
              <p className='landing-text-20-thin text-md-black'>
                {intl.formatMessage({ id: 'Second - customers and visitors' })}
              </p>
            </div>
          </Col>
        </Row>
      </Col>

      <EllipseBackgroundIcon className='hidden block-md' style={{ position: 'absolute', top: 0, left: 0, right: 0 }} />
      <GreenBlur className='hidden block-md' style={{ top: 70, left: -30 }} blur='big' />
      <GreenBlur style={{ top: 70, right: -30 }} blur='big' />
      <GreenBlur className='hidden-md' style={{ top: '55%', right: 0 }} />
    </Row>
  )
}
