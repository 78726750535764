import React, { useEffect, useState } from 'react'
import { checkCamera } from '../utils/utils'

interface CheckCameraProps {
  children: any
}

export const CheckCamera: React.FC<CheckCameraProps> = ({ children }) => {
  const [cameraIsAllowed, setCameraIsAllowed] = useState(false)

  useEffect(() => {
    ;(() => {
      checkCamera().then((res) => setCameraIsAllowed(res))
    })()
  }, [])

  return cameraIsAllowed ? children : null
}
