/* eslint-disable no-console */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { message as antdMessage } from 'antd'
import { useSocketMain } from './useSocketMain'
import { SocketActions } from '../utils/Action'
import { getCapturedVideo } from '../utils/utils'
import { SetQRCodeData } from '../store/myself/actions'
import { useCallSound } from './useCallSound'
import { useCallViaBack } from './useCallViaBack'
import { useCallViaFront } from './useCallViaFront'

export const useSocketMakeCall = (tradePointID?: string, retailerTagId?: string) => {
  const { ws, connected } = useSocketMain(tradePointID, retailerTagId)
  const dispatch = useDispatch()
  const { playSound, stopSound } = useCallSound()

  const localMediaStream = useRef<MediaStream | null>(null)

  const localVideoRef = useRef<HTMLVideoElement>(null)

  const callIsViaBack = useRef<boolean>(false)

  const socketEvents = useRef<any[]>([])
  const [runSocketEvent, setRunSocketEvent] = useState<boolean>(true)
  const [add, setAdd] = useState(0)

  const [timeout, setTimeoutState] = useState(false)
  const [isOnline, setIsOnline] = useState(false)
  const [isCalling, setIsCalling] = useState(false)
  const [callIsStarted, setCallIsStarted] = useState(false)
  const [callTime, setCallTime] = useState(0)
  const [microphone, setMicrophone] = useState(true)
  const [isBackCamera, setIsBackCamera] = useState(false)
  const [connecting, setConnecting] = useState(false)
  const [callIsFinished, setCallIsFinished] = useState(false)

  const onRelaySdp = (peerID: string, sessionDescription: RTCSessionDescriptionInit) => {
    ws?.send(
      JSON.stringify({
        p: SocketActions.relay_sdp,
        message: 'relay-sdp',
        data: { peerID, sessionDescription },
      })
    )
  }

  const onRelayIce = (peerID: string, iceCandidate: RTCIceCandidate) => {
    ws?.send(
      JSON.stringify({
        p: SocketActions.relay_ice,
        message: 'relay-ice',
        data: { peerID, iceCandidate },
      })
    )
  }

  const onWebrtcCandidate = (e: RTCPeerConnectionIceEvent) => {
    ws?.send(JSON.stringify({ event: 'ice_candidate', data: JSON.stringify(e.candidate) }))
  }

  const onWebrtcSend = (sdp: string) => {
    ws?.send(
      JSON.stringify({
        p: SocketActions.webrtc_send,
        message: 'webrtc',
        data: { sdp },
      })
    )
  }
  const onWebrtcReceive = (sdp: string) => {
    ws?.send(
      JSON.stringify({
        p: SocketActions.webrtc_receive,
        message: 'webrtc',
        data: { is_sender: false, sdp },
      })
    )
  }

  const sendCallDuration = (duration: number) => {
    ws?.send(
      JSON.stringify({
        p: SocketActions.finish_call,
        message: 'finish_call',
        data: { rating: 5, message: 'asdw', call_duration: duration },
      })
    )
    // set consultant online
    if (!tradePointID || !retailerTagId)
      ws?.send(JSON.stringify({ p: SocketActions.find_companion, message: 'find_companion' }))
  }

  const {
    finishCall: finishCallB,
    // initReceiver,
    // initSender,
    addNewBackPeer,
    sendRemoteMediaViaBack,
    remoteVideoBackRef,
    remoteSoundBackRef,
    peerConnections: peerConnectionsB,
  } = useCallViaBack({
    localMediaStream,
    onWebrtcReceive,
    onWebrtcSend,
    onWebrtcCandidate,
    sendCallDuration,
    setCallIsFinished,
    setCallIsStarted,
    setCallTime,
    setConnecting,
  })

  const {
    finishCall: finishCallF,
    addNewPeer,
    onIceCandidate,
    sendRemoteMediaViaFront,
    remoteSoundFrontRef,
    remoteVideoFrontRef,
    peerConnections: peerConnectionsF,
  } = useCallViaFront({
    localMediaStream,
    onRelayIce,
    onRelaySdp,
    sendCallDuration,
    setCallIsFinished,
    setCallIsStarted,
    setCallTime,
    setConnecting,
  })

  const setLocalVideo = () => {
    if (localVideoRef.current && localMediaStream.current) {
      localVideoRef.current.srcObject = localMediaStream.current
      localVideoRef.current.volume = 0
    } else {
      console.log('else setLocalVideo')
      setTimeout(() => setLocalVideo(), 1000)
    }
  }

  const onMessage = async (evt: MessageEvent) => {
    const { message, data, p } = JSON.parse(evt.data)
    switch (message) {
      case 'find_customer_resp':
        setIsCalling(true)
        await playSound()
        setCallIsFinished(false)
        dispatch(SetQRCodeData(data))
        break
      case 'find_consultant_resp':
        setIsCalling(true)
        break
      case 'consultant_cancel_call':
      case 'customer_cancel_call':
        setIsCalling(false)
        await stopSound()
        break
      case 'call_timeout_resp':
        setIsCalling(false)
        await stopSound()
        setTimeoutState(true)
        dispatch(SetQRCodeData(null))
        break
      case 'add-peer':
        await stopSound()
        setConnecting(true)
        localMediaStream.current = await getCapturedVideo()
        if (callIsViaBack.current) {
          // await initSender()
          // await initReceiver()
          await addNewBackPeer(data)
        } else await addNewPeer(data)
        break
      case 'session-description':
        setIsCalling(false)
        setCallIsStarted(true)
        await sendRemoteMediaViaFront(data)
        setLocalVideo()
        break
      case 'ice-candidate':
        setConnecting(false)
        await onIceCandidate(data)
        break
      case 'is_calls_video_record':
        callIsViaBack.current = data.with_video_record
        break
      case 'webrtc_resp':
        if (data.msg) break
        setIsCalling(false)
        setCallIsStarted(true)
        await sendRemoteMediaViaBack(data, p === SocketActions.webrtc_send)
        setLocalVideo()
        break
      // case 'offer':
      //   let offer = JSON.parse(data.msg.data)
      //   if (!offer) return console.log('failed to parse answer')
      //   pc.setRemoteDescription(offer)
      //   pc.createAnswer().then((answer) => {
      //     pc.setLocalDescription(answer)
      //     ws.send(JSON.stringify({ event: 'answer', data: JSON.stringify(answer) }))
      //   })
      //   break
    }
    setRunSocketEvent(true)
  }

  const addEvent = (evt: MessageEvent) => {
    const { message } = JSON.parse(evt.data)

    switch (message) {
      case 'find_customer_resp':
      case 'find_consultant_resp':
      case 'consultant_cancel_call':
      case 'customer_cancel_call':
      case 'call_timeout_resp':
      case 'add-peer':
      case 'session-description':
      case 'ice-candidate':
      case 'is_calls_video_record':
      case 'webrtc_resp':
        if (!socketEvents.current.length) setRunSocketEvent(true)
        socketEvents.current.push(async () => onMessage(evt))
        setAdd((prev) => prev + 1)
        break
    }
  }

  if (ws) ws.onmessage = addEvent

  useEffect(() => {
    if (socketEvents.current.length && runSocketEvent) {
      setRunSocketEvent(false)
      socketEvents.current[0]()
      socketEvents.current.shift()
    }
  }, [add, runSocketEvent])

  useEffect(() => {
    if (callIsStarted && !connecting) {
      const interval = setInterval(() => setCallTime((prev) => prev + 1), 1000)
      return () => clearInterval(interval)
    }
  }, [callIsStarted, connecting])

  // ///////////// socket handlers ///////////////
  const onOnline = useCallback(
    (callBack?: (code: number) => void) => {
      if (connected) {
        ws?.send(JSON.stringify({ p: SocketActions.find_companion, message: 'find_companion' }))

        const listener = (evt: MessageEvent) => {
          const { p, code } = JSON.parse(evt.data)
          if (p === SocketActions.find_companion) {
            setIsOnline(true)
            setTimeoutState(false)
            if (callBack) callBack(code)
          }
        }
        ws?.addEventListener('message', listener)
        return () => ws?.removeEventListener('message', listener)
      }
      return () => {}
    },
    [connected]
  )

  const onOffline = useCallback(
    (callBack?: (code: number) => void) => {
      if (connected) {
        ws?.send(JSON.stringify({ p: SocketActions.unregister, message: 'unregister' }))

        const listener = (evt: MessageEvent) => {
          const { p, code, error } = JSON.parse(evt.data)
          if (p === SocketActions.unregister) {
            if (code === 200) {
              setCallIsFinished(true)
              setIsOnline(false)
              if (callBack) callBack(code)
            } else antdMessage.error(error)
          }
        }
        ws?.addEventListener('message', listener)
        return () => ws?.removeEventListener('message', listener)
      }
      return () => {}
    },
    [connected]
  )

  const onAcceptCall = useCallback(
    (callBack?: (code: number) => void) => {
      if (connected) {
        ws?.send(JSON.stringify({ p: SocketActions.accept_call, message: 'accept_call' }))
        dispatch(SetQRCodeData({ call_started: true }))
        const listener = (evt: MessageEvent) => {
          const { p, code } = JSON.parse(evt.data)
          if (p === SocketActions.accept_call) {
            if (code === 200) {
              stopSound()
              if (callBack) callBack(code)
            }
          }
        }
        ws?.addEventListener('message', listener)
        return () => ws?.removeEventListener('message', listener)
      }
      return () => {}
    },
    [connected]
  )

  const onCancelCall = useCallback(
    (callBack?: (code: number) => void) => {
      if (connected) {
        dispatch(SetQRCodeData(null))
        ws?.send(JSON.stringify({ p: SocketActions.cancel_call, message: 'cancel_call' }))
        const listener = (evt: MessageEvent) => {
          const { p, code, error } = JSON.parse(evt.data)
          if (p === SocketActions.cancel_call) {
            if (code === 200) {
              stopSound()
              setIsCalling(false)
              if (callBack) callBack(code)
            } else antdMessage.error(error)
          }
        }
        ws?.addEventListener('message', listener)
        return () => ws?.removeEventListener('message', listener)
      }
      return () => {}
    },
    [connected]
  )

  // ///////////// ------- ///////////////
  const turnMicrophone = () => {
    if (localMediaStream.current) {
      localMediaStream.current.getAudioTracks()[0].enabled = !localMediaStream.current.getAudioTracks()[0].enabled
      setMicrophone(localMediaStream.current.getAudioTracks()[0].enabled)
    }
  }

  const changeCamera = () => {
    if (localMediaStream.current) {
      getCapturedVideo(!isBackCamera).then((res) => {
        res.getVideoTracks().forEach((track) => {
          localMediaStream.current?.removeTrack(localMediaStream.current.getVideoTracks()[0])
          localMediaStream.current?.addTrack(track)
          // eslint-disable-next-line no-restricted-syntax
          for (const [, value] of Object.entries({ ...peerConnectionsB.current, ...peerConnectionsF.current })) {
            const sender = value.getSenders().find((s) => s.track?.kind === track.kind)
            sender?.replaceTrack(track).then()
          }
        })
        setIsBackCamera(!isBackCamera)
      })
    }
  }

  return {
    isCalling,
    isOnline,
    timeout,
    callIsStarted,
    callTime,
    microphone,
    connecting,
    callIsFinished,
    isBackCamera,

    onOnline,
    onOffline,
    onAcceptCall,
    onCancelCall,
    finishCall: callIsViaBack.current ? finishCallB : finishCallF,
    turnMicrophone,
    changeCamera,

    localVideoRef,
    remoteVideoRef: callIsViaBack.current ? remoteVideoBackRef : remoteVideoFrontRef,
    remoteSoundRef: callIsViaBack.current ? remoteSoundBackRef : remoteSoundFrontRef,
  }
}
