import React, { useState } from 'react'
import { Col, Row } from 'antd'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { TypeSendMessageContactUsR } from '../store/contact-us/actions'
import { DoneBigIcon } from '../asserts/svg/DoneBigIcon'
import { AppStore } from '../store/applicationState'

interface FooterProps {
  onContactUs: (data: TypeSendMessageContactUsR) => void
  loading?: boolean
}

export const Footer: React.FC<FooterProps> = ({ onContactUs, loading }) => {
  const intl = useIntl()
  const { General } = useSelector((store: AppStore) => store)

  const [submittedSuccess, setSubmittedSuccess] = useState(false)
  const [footerIsFocused, setFooterIsFocused] = useState(false)

  const onFinish = (e: any) => {
    e.preventDefault()
    const formProps = Object.fromEntries(new FormData(e.target))
    onContactUs({
      name: formProps.fname as string,
      email: formProps.femail as string,
      phone: (formProps.fphone as string) || undefined,
      company_name: (formProps.fcompanyname as string) || undefined,
      language: General.locale === 'pl' ? 'PL' : 'EN',
      callBack: (success) => setSubmittedSuccess(success),
    })
  }

  return (
    <footer className='br-top-20 br-top-md-0'>
      <Row justify='center' className='pt-60 pb-40 py-md-80'>
        <Col md={11} xs={22} id='contact-us'>
          <Row gutter={[{ lg: 78, xs: 28 }, 0]} justify='center'>
            <Col md={20} xs={0}>
              <p className='landing-text-36' style={{ textAlign: 'left', marginBottom: 16 }}>
                {intl.formatMessage({ id: 'Learn about the benefits' })}
              </p>
              <p className='landing-text-20-thin' style={{ marginBottom: 78, opacity: 0.8 }}>
                {intl.formatMessage({ id: 'Apply for free access' })}
              </p>
              <p className='landing-text-20-thin' style={{ marginBottom: 16, opacity: 0.8 }}>
                {intl.formatMessage({ id: 'CONTACTS' })}
              </p>
              <p className='landing-text-36' style={{ textAlign: 'left', marginBottom: 28 }}>
                +48 608 558 676
              </p>
              <p className='landing-text-36' style={{ textAlign: 'left' }}>
                rc@udtech.co
              </p>
            </Col>
            <Col md={0} xs={24}>
              <p className='landing-text-36 text-align-center' style={{ marginBottom: 16 }}>
                {intl.formatMessage({ id: 'Learn about the benefits' })}
              </p>
              <p className='landing-text-20-thin' style={{ textAlign: 'center', opacity: 0.8, marginBottom: 38 }}>
                {intl.formatMessage({ id: 'Apply for free access' })}
              </p>
            </Col>
          </Row>
        </Col>
        <Col md={12} xs={24}>
          <Row justify='center'>
            {submittedSuccess ? (
              <Col md={20} xs={24} className='background-success p-16 p-md-0'>
                <Row justify='center' gutter={[0, 12]} align='middle' style={{ height: '100%' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <DoneBigIcon />
                  </Col>
                  <Col span={24}>
                    <p className='landing-text-36 text-align-center'>
                      {intl.formatMessage({ id: 'Your application has been sent successfully!' })}
                    </p>
                    <p className='landing-text-36 text-align-center'>
                      {intl.formatMessage({ id: 'Check your mail.' })}
                    </p>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col md={20} xs={24} className='background-md-footer p-16 p-md-0'>
                <form onSubmit={onFinish}>
                  <Row>
                    <Col span={24}>
                      <label className='landing-input' htmlFor='fname'>
                        {intl.formatMessage({ id: 'Name' })}
                        <input required type='text' name='fname' id='fname' />
                      </label>
                      <br />
                      <br />
                      <label className='landing-input' htmlFor='femail'>
                        {intl.formatMessage({ id: 'Email' })}
                        <input required type='email' name='femail' id='femail' />
                      </label>
                      <br />
                      <br />
                      <label className='landing-input' htmlFor='fphone'>
                        {intl.formatMessage({ id: 'Phone (optional)' })}
                        <input
                          type='tel'
                          pattern='^\+(?:[0-9]●?){6,14}[0-9]$|^$'
                          minLength={9}
                          maxLength={14}
                          placeholder={footerIsFocused ? '+48 000 000 000' : ''}
                          onFocus={() => setFooterIsFocused(true)}
                          onBlur={() => setFooterIsFocused(false)}
                          onInvalid={(e) => {
                            // @ts-ignore
                            e.target.setCustomValidity('Please, enter the phone in an international format.')
                          }}
                          name='fphone'
                          id='fphone'
                        />
                      </label>
                      <br />
                      <br />
                      <label className='landing-input' htmlFor='fcompanyname'>
                        {intl.formatMessage({ id: 'Company name (optional)' })}
                        <input type='text' name='fcompanyname' id='fcompanyname' />
                      </label>
                      <br />
                      <br />
                    </Col>
                    <Col md={12} xs={24}>
                      <button
                        type='submit'
                        disabled={loading}
                        className='default-landing-btn'
                        style={{ width: '100%' }}
                      >
                        {intl.formatMessage({ id: 'Send' })}
                      </button>
                    </Col>
                  </Row>
                </form>
              </Col>
            )}
          </Row>
        </Col>
        <Col md={0} xs={22}>
          <Row>
            <Col span={24} style={{ marginTop: 50 }}>
              <p className='landing-text-20-thin' style={{ marginBottom: 16, opacity: 0.8 }}>
                {intl.formatMessage({ id: 'CONTACTS' })}
              </p>
              <p className='landing-text-36' style={{ textAlign: 'left', marginBottom: 28 }}>
                +48 608 558 676
              </p>
              <p className='landing-text-36' style={{ textAlign: 'left' }}>
                rc@udtech.co
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </footer>
  )
}
