import { Reducer } from 'redux'
import { ActionTypes, TContactUsState } from './types'

export const initialState: TContactUsState = {
  loading: false,
  data: null,
}

const reducer: Reducer<TContactUsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SEND_MESSAGE_CONTACT_US_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.SEND_MESSAGE_CONTACT_US_S:
      return { ...state, loading: false, data: action.payload }
    case ActionTypes.SEND_MESSAGE_CONTACT_US_E:
      return { ...state, loading: false, errors: action.payload }

    default:
      return state
  }
}

export { reducer as ContactUsReducer }
