import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Row, Typography } from 'antd'
import { Container } from '../wrappers/Container'
import { RetailerTagsList } from '../components/retailerTags/RetailerTagsList'
import { GetRetailerTags } from '../store/retailerTags/actions'
import { AppStore } from '../store/applicationState'
import { ROUTES } from '../utils/routes'

interface RetailerTagsListViewProps {}

export const RetailerTagsListView: React.FC<RetailerTagsListViewProps> = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { RetailerTags } = useSelector((store: AppStore) => store)

  const pagination = useRef<{ start: number; limit: number }>({ start: 0, limit: 18 })
  const currentPage = Math.round(pagination.current.start / pagination.current.limit + 1)

  useEffect(() => {
    dispatch(
      GetRetailerTags.request({
        query: {
          limit: pagination.current.limit,
          offset: pagination.current.start,
        },
      })
    )
  }, [])

  const onPageClick = (page: number, pageSize: number) => {
    pagination.current.start = (page - 1) * pagination.current.limit
    pagination.current.limit = pageSize
    dispatch(
      GetRetailerTags.request({
        query: {
          limit: pagination.current.limit,
          offset: pagination.current.start,
        },
      })
    )
  }

  return (
    <Container>
      <Row className='pageTitle'>
        <Typography.Title level={3}>Departments</Typography.Title>
        <div className='results'>
          {RetailerTags?.data?.total} {RetailerTags?.data?.total === 1 ? 'result' : 'results'}
        </div>
      </Row>
      <Button
        size='large'
        style={{ marginTop: '20px', marginBottom: '20px' }}
        type='primary'
        onClick={() => history.push(ROUTES.createRetailerTags)}
      >
        Create department +
      </Button>
      <RetailerTagsList
        retailerTagsData={RetailerTags?.data}
        currentPage={currentPage}
        onPageClick={onPageClick}
        pagination={pagination}
      />
    </Container>
  )
}
