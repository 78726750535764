import React, { CSSProperties } from 'react'
import landingLaptop from '../png/landing-laptop.png'

interface LandingLaptopImageProps {
  width?: number | string
  height?: number | string
  style?: CSSProperties
}

export const LandingLaptopImage: React.FC<LandingLaptopImageProps> = ({ width, height, style }) => {
  return (
    <img
      width={width || 890}
      height={height || 555}
      style={{ ...style }}
      loading='lazy'
      src={landingLaptop}
      alt='landing-laptop_image'
    />
  )
}
