import React, { CSSProperties } from 'react'

interface ClothingIconProps {
  style?: CSSProperties
  width?: number | string
  height?: number | string
}

export const ClothingIcon: React.FC<ClothingIconProps> = ({ style, width, height }) => {
  return (
    <svg
      width={width || 176}
      height={height || 176}
      style={{ ...style }}
      viewBox='0 0 176 175'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_913_40056)'>
        <rect x='0.5' width='175' height='175' rx='87.5' fill='#0D181E' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M77.0522 60.0732C75.5916 60.3885 72.9053 61.0804 71.6276 61.4703C70.6001 61.7838 68.5175 62.6928 64.236 64.6967C60.9574 66.2311 58.1755 67.5756 58.0541 67.6843C57.7628 67.9452 57.5 68.4739 57.5 68.7989C57.5 69.1255 62.6563 79.5422 63.005 79.92C63.4918 80.4474 63.8293 80.4097 66.8955 79.4845C68.4659 79.0107 69.784 78.6231 69.8248 78.6231C69.8656 78.6231 69.9019 86.2824 69.9056 95.6439C69.9107 108.504 69.9472 112.766 70.0546 113.078C70.133 113.305 70.3484 113.606 70.5335 113.745L70.8702 114H87.9515C106.492 114 105.408 114.039 105.873 113.342C106.07 113.047 106.084 112.004 106.114 95.8259C106.131 86.3643 106.178 78.6231 106.218 78.6231C106.258 78.6231 107.575 79.0107 109.145 79.4845C112.285 80.4319 112.583 80.4595 113.087 79.8501C113.243 79.6616 114.539 77.1432 115.968 74.2535C118.831 68.4637 118.814 68.5176 118.097 67.81C117.615 67.3333 106.426 62.1176 104.72 61.5741C102.025 60.7153 98.5525 59.9102 98.0082 60.0179C97.4946 60.1195 97.1122 60.5758 96.8944 61.3474C96.0718 64.2603 93.9774 66.6603 91.3586 67.6909C87.9607 69.028 84.1051 68.2074 81.571 65.6074C80.4353 64.4422 79.8327 63.3537 79.0234 61.0054C78.726 60.1424 78.1008 59.8468 77.0522 60.0732ZM82.4904 61.4655C83.1827 63.1519 84.7265 64.4979 86.5302 64.9874C87.2946 65.195 88.7463 65.195 89.5107 64.9874C91.1418 64.5447 92.6407 63.3276 93.3508 61.8695C93.5588 61.4422 93.7125 61.0758 93.6924 61.0553C93.6723 61.0348 93.0183 61.1411 92.239 61.2915C91.0211 61.5266 90.4286 61.565 88.0205 61.565C85.624 61.565 85.0169 61.526 83.8235 61.2954C83.0561 61.1473 82.4017 61.0259 82.3692 61.0259C82.3366 61.0259 82.3912 61.2237 82.4904 61.4655Z'
          fill='#489E38'
        />
        <g filter='url(#filter0_f_913_40056)'>
          <circle cx='23.125' cy='174.625' r='55.625' fill='#489E38' />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_f_913_40056'
          x='-106.5'
          y='45'
          width='259.25'
          height='259.25'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='37' result='effect1_foregroundBlur_913_40056' />
        </filter>
        <clipPath id='clip0_913_40056'>
          <rect x='0.5' width='175' height='175' rx='87.5' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
