import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Form, Input, Col, message, Breadcrumb, Typography } from 'antd'
import { Container } from '../wrappers/Container'
import { GetRetailerTags, CreateNewRetailerTag, GetRetailerTagById, UpdateRetailerTag } from '../store/retailerTags/actions'
import { ROUTES } from '../utils/routes'
import { AppStore } from '../store/applicationState'

interface RetailerTagViewProps {}

export const RetailerTagView: React.FC<RetailerTagViewProps> = () => {
  const history = useHistory()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { id } = useParams<{ id?: string }>()
  const { RetailerTags } = useSelector((store: AppStore) => store)

  useEffect(() => {
    if (id) {
      dispatch(
        GetRetailerTagById.request({
          id,
          callBack: (success, data) => {
            if (success) {
              form.setFieldsValue({
                name: data?.name,
              })
            } else {
              message.error(data.message)
            }
          },
        })
      )
    }
  }, [])

  const onSubmitForm = (values: any) => {
    if (values) {
      dispatch(
        CreateNewRetailerTag.request({
          body: {
            name: values.name,
          },
          callBack: (success, err) => {
            if (success) {
              dispatch(GetRetailerTags.request())
              history.push(ROUTES.retailerTags)
            } else {
              message.error(err.message)
            }
          },
        })
      )
    }
    return null
  }

  const onUpdate = (values: any) => {
    if (values && id) {
      dispatch(
        UpdateRetailerTag.request({
          id,
          body: {
            name: values.name,
          },
          callBack: (success, err) => {
            if (success) {
              dispatch(GetRetailerTags.request())
              message.success('Department updated')
              history.push(ROUTES.retailerTags)
            } else {
              message.error(err.message)
            }
          },
        })
      )
    }
    return null
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item>Departments</Breadcrumb.Item>
        <Breadcrumb.Item>{id ? 'Edit department' : 'Create a department'}</Breadcrumb.Item>
      </Breadcrumb>
      <Typography.Title level={3} className='pageTitle'>
        {id ? 'Edit department' : 'Create a department'}
      </Typography.Title>
      <Form
        form={form}
        className='gx-form-inline-label-up gx-form-row0 '
        size='large'
        onFinish={(values) => {
          if (id) {
            onUpdate(values)
          } else {
            onSubmitForm(values)
          }
        }}
      >
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true, message: 'Please enter department name' }]}
            initialValue={RetailerTags?.data?.retailerTagForEdit?.name}
            label='Name department'
            name='name'
            labelCol={{ span: 24 }}
          >
            <Input maxLength={80} placeholder='Name' />
          </Form.Item>
          <Form.Item>
            <Button size='large' type='primary' style={{ width: '100%' }} onClick={() => form.submit()}>
              Save
            </Button>
          </Form.Item>
        </Col>
      </Form>
    </Container>
  )
}
