import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Typography, Row } from 'antd'
import { Container } from '../wrappers/Container'
import { TradePointsTable } from '../components/tradePoints/TradePointsTable'
import { GetTradePoints } from '../store/tradePoints/actions'
import { AppStore } from '../store/applicationState'
import { ROUTES } from '../utils/routes'

interface TradePointsListViewProps {}

export const TradePointsListView: React.FC<TradePointsListViewProps> = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { TradePoints } = useSelector((store: AppStore) => store)

  const pagination = useRef<{ start: number; limit: number }>({ start: 0, limit: 10 })
  const currentPage = Math.round(pagination.current.start / pagination.current.limit + 1)

  useEffect(() => {
    dispatch(
      GetTradePoints.request({
        query: { offset: pagination.current.start, limit: pagination.current.limit },
      })
    )
  }, [])

  const onPageClick = (page: number, pageSize: number) => {
    pagination.current.start = (page - 1) * pagination.current.limit
    pagination.current.limit = pageSize
    dispatch(
      GetTradePoints.request({
        query: {
          offset: pagination.current.start,
          limit: pagination.current.limit,
        },
      })
    )
  }

  return (
    <Container>
      <Row className='pageTitle'>
        <Typography.Title level={3}>Shops</Typography.Title>
        <div className='results'>
          {TradePoints?.data?.total} {TradePoints?.data?.total === 1 ? 'result' : 'results'}
        </div>
      </Row>
      <Button
        size='large'
        style={{ marginTop: '20px', marginBottom: '20px' }}
        type='primary'
        onClick={() => history.push(ROUTES.createTradePoint)}
      >
        Create shop +
      </Button>
      <TradePointsTable
        tradePoints={TradePoints?.data?.trade_points || []}
        pagination={pagination}
        onPageClick={onPageClick}
        currentPage={currentPage}
        total={TradePoints?.data.total}
      />
    </Container>
  )
}
