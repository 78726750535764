import React, { CSSProperties } from 'react'

interface PatternIconProps {
  style?: CSSProperties
  width?: number
  height?: number
  className?: string
}

export const PatternIcon: React.FC<PatternIconProps> = ({ style, width, height, className }) => {
  return (
    <svg
      width={width || 407}
      height={height || 572}
      style={{ ...style }}
      className={className || ''}
      viewBox='0 0 407 572'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M169.027 172.48L168.967 172.44L169.027 172.4V172.48Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M184.508 172.44L184.158 172.64V172.24L184.508 172.44Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M161.75 185.66L161.2 185.98V185.34L161.75 185.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M192.359 186.43L191.029 185.66L192.359 184.89V186.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M207.277 159.22L207.127 159.31V159.13L207.277 159.22Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M169.539 199.8L167.959 198.88L169.539 197.97V199.8Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M185.66 198.88L183.59 200.08V197.69L185.66 198.88Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M208.348 185.66L206.598 186.68V184.65L208.348 185.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M215.199 173.1L214.049 172.44L215.199 171.78V173.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M146.168 185.76L145.998 185.66L146.168 185.57V185.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M146.648 213.04L145.028 212.1L146.648 211.17V213.04Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M162.938 212.1L160.598 213.46V210.75L162.938 212.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M193.109 214.18L189.519 212.1L193.109 210.03V214.18Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M210 212.1L205.77 214.55V209.66L210 212.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M215.891 200.74L212.671 198.88L215.891 197.03V200.74Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M231.359 172.44L229.439 173.55V171.33L231.359 172.44Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M238.188 160.04L236.758 159.22L238.188 158.4V160.04Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M230.23 146L230.01 146.12V145.87L230.23 146Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M138.797 198.88L138.317 199.16V198.6L138.797 198.88Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M139.859 225.33L137.789 226.53V224.13L139.859 225.33Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M170.309 227.58L166.409 225.33L170.309 223.07V227.58Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M187.43 225.33L182.7 228.05V222.6L187.43 225.33Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M216.848 228.85L210.738 225.33L216.848 221.8V228.85Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M232.699 198.88L228.769 201.15V196.61L232.699 198.88Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M238.789 187.53L235.559 185.66L238.789 183.8V187.53Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M254.578 159.22L252.178 160.6V157.83L254.578 159.22Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M123.219 198.89L123.209 198.88H123.219V198.89Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M147.258 240.54L143.808 238.55L147.258 236.56V240.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M164.418 238.55L159.858 241.18V235.91L164.418 238.55Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M194.027 242.22L187.667 238.55L194.027 234.87V242.22Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M211.859 238.55L204.839 242.6V234.49L211.859 238.55Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M234.668 225.33L227.788 229.3V221.35L234.668 225.33Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M239.648 215.46L233.838 212.1L239.648 208.75V215.46Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M255.848 185.66L251.548 188.15V183.17L255.848 185.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M261.957 174.78L257.917 172.44L261.957 170.1V174.78Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M261.32 147.23L259.19 146L261.32 144.77V147.23Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M253.449 132.78L252.749 133.18V132.37L253.449 132.78Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M115.789 212.1L115.469 212.29V211.92L115.789 212.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M123.629 226.05L122.379 225.33L123.629 224.6V226.05Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M124.117 253.34L121.397 251.77L124.117 250.2V253.34Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M140.938 251.77L137.247 253.9V249.64L140.938 251.77Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M171.027 255.26L164.977 251.77L171.027 248.28V255.26Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M188.949 251.77L181.939 255.82V247.72L188.949 251.77Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M217.629 256.65L209.179 251.77L217.629 246.89V256.65Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M236.098 251.77L227.068 256.99V246.55L236.098 251.77Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M240.508 243.39L232.118 238.55L240.508 233.71V243.39Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M257.508 212.1L250.708 216.03V208.18L257.508 212.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M262.66 202.42L256.53 198.88L262.66 195.34V202.42Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M279.648 172.44L273.988 175.71V169.17L279.648 172.44Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M285.359 162.42L279.819 159.22L285.359 156.02V162.42Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M278.328 146L274.658 148.12V143.88L278.328 146Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M260.789 119.86L260.269 119.55L260.789 119.25V119.86Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M116.641 238.55L115.051 239.47V237.63L116.641 238.55Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M117.5 264.99L114.62 266.65V263.33L117.5 264.99Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M147.828 267.96L142.678 264.99L147.828 262.02V267.96Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M165.609 264.99L159.259 268.65V261.33L165.609 264.99Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M194.668 269.77L186.398 264.99L194.668 260.22V269.77Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M213.16 264.99L204.19 270.17V259.81L213.16 264.99Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M241 270.69L231.13 264.99L241 259.29V270.69Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M259.059 238.55L249.939 243.81V233.29L259.059 238.55Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M263.488 230.31L254.858 225.33L263.488 220.34V230.31Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M280.98 198.88L273.33 203.3V194.47L280.98 198.88Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M285.988 189.94L278.568 185.66L285.988 181.38V189.94Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M303.82 159.22L296.26 163.58V154.86L303.82 159.22Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M284.668 134.78L281.198 132.78L284.668 130.77V134.78Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M277.047 119.55L275.297 120.56V118.54L277.047 119.55Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M100.57 239L99.7903 238.55L100.57 238.1V239Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M124.547 280.53L120.537 278.21L124.547 275.9V280.53Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M141.891 278.21L136.771 281.17V275.26L141.891 278.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M171.52 282.55L164.01 278.21L171.52 273.88V282.55Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M189.93 278.21L181.46 283.11V273.32L189.93 278.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M218.047 283.82L208.327 278.21L218.047 272.6V283.82Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M236.777 278.21L226.727 284.02V272.41L236.777 278.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M259.898 264.99L249.518 270.98V259L259.898 264.99Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M264.008 257.65L253.818 251.77L264.008 245.89V257.65Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M282.367 225.33L272.637 230.95V219.71L282.367 225.33Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M286.68 217.59L277.18 212.1L286.68 206.62V217.59Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M305.02 185.66L295.66 191.06V180.26L305.02 185.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M309.617 177.99L300.007 172.44L309.617 166.9V177.99Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M308.957 150.4L301.327 146L308.957 141.59V150.4Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M302.32 132.78L297.01 135.84V129.71L302.32 132.78Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M284.027 107.22L282.487 106.33L284.027 105.45V107.22Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M276.09 93.11L275.78 93.29V92.93L276.09 93.11Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M93.3281 251.77L92.3481 252.33V251.21L93.3281 251.77Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M100.969 266.14L98.9887 264.99L100.969 263.84V266.14Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M101.328 293.19L98.2781 291.43L101.328 289.68V293.19Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M118.289 291.43L114.219 293.78V289.08L118.289 291.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M148.207 295.06L141.917 291.43L148.207 287.81V295.06Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M166.359 291.43L158.889 295.75V287.12L166.359 291.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M194.957 296.71L185.817 291.43L194.957 286.16V296.71Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M213.68 291.43L203.93 297.06V285.81L213.68 291.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M241.16 297.41L230.82 291.43L241.16 285.46V297.41Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M260 291.43L249.47 297.51V285.36L260 291.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M264.18 284.4L253.47 278.21L264.18 272.03V284.4Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M283.078 251.77L272.278 258V245.54L283.078 251.77Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M287.117 244.8L276.297 238.55L287.117 232.3V244.8Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M306.199 212.1L295.069 218.53V205.68L306.199 212.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M310.188 205.41L298.867 198.88L310.188 192.35V205.41Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M329.398 172.44L317.818 179.13V165.76L329.398 172.44Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M333.23 166L321.49 159.22L333.23 152.44V166Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M328.129 146L318.459 151.58V140.41L328.129 146Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M308.109 122.49L303.029 119.55L308.109 116.62V122.49Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M300.809 106.33L297.769 108.09V104.58L300.809 106.33Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M283.578 80.0103L283.378 79.8902L283.578 79.7702V80.0103Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M77.5391 251.99L77.1591 251.77L77.5391 251.56V251.99Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M94.0391 278.21L91.989 279.4V277.03L94.0391 278.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M94.7383 304.66L91.6483 306.44V302.87L94.7383 304.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M124.918 307.62L119.788 304.66L124.918 301.69V307.62Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M142.57 304.66L136.43 308.2V301.11L142.57 304.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M171.758 309.42L163.508 304.66L171.758 299.89V309.42Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M190.348 304.66L181.248 309.91V299.4L190.348 304.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M218.16 310.45L208.13 304.66L218.16 298.87V310.45Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M236.891 304.66L226.681 310.55V298.76L236.891 304.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M264.078 310.66L253.678 304.66L264.078 298.65V310.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M283.18 278.21L272.23 284.53V271.9L283.18 278.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M287.25 271.46L276.04 264.99L287.25 258.52V271.46Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M306.668 238.55L294.828 245.38V231.71L306.668 238.55Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M310.52 232.43L298.21 225.33L310.52 218.22V232.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M330.348 198.88L317.348 206.39V191.38L330.348 198.88Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M333.719 193.29L320.509 185.66L333.719 178.03V193.29Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M353.668 159.22L340.038 167.09V151.35L353.668 159.22Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M332.43 138.16L323.09 132.78L332.43 127.39V138.16Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M326.32 119.55L319.36 123.57V115.54L326.32 119.55Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M307.309 94.6699L304.619 93.1099L307.309 91.5499V94.6699Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M299.578 79.8901L298.378 80.5801V79.2001L299.578 79.8901Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M70.1875 264.99L69.5675 265.35V264.63L70.1875 264.99Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M77.8594 279L76.4994 278.21L77.8594 277.43V279Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M101.68 320.26L97.5597 317.88L101.68 315.5V320.26Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M119.008 317.88L113.858 320.85V314.91L119.008 317.88Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M148.48 321.98L141.38 317.88L148.48 313.78V321.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M166.777 317.88L158.677 322.55V313.21L166.777 317.88Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M195 323.23L185.73 317.88L195 312.52V323.23Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M213.629 317.88L203.959 323.46V312.29L213.629 317.88Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M240.957 323.49L231.227 317.88L240.957 312.27V323.49Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M259.41 317.88L249.77 323.45V312.31L259.41 317.88Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M282.73 304.66L272.46 310.58V298.73L282.73 304.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M287.098 297.64L276.348 291.43L287.098 285.23V297.64Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M306.547 264.99L294.897 271.72V258.26L306.547 264.99Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M310.469 258.8L298.299 251.77L310.469 244.74V258.8Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M330.758 225.33L317.138 233.19V217.46L330.758 225.33Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M334.039 220.29L319.859 212.1L334.039 203.92V220.29Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M354.438 185.66L339.647 194.2V177.13L354.438 185.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M357.277 181.22L342.077 172.44L357.277 163.66V181.22Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M356.738 153.83L343.178 146L356.738 138.17V153.83Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M352.09 132.78L340.83 139.27V126.28L352.09 132.78Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M331.43 109.99L325.09 106.33L331.43 102.67V109.99Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M324.418 93.1102L320.308 95.4902V90.7402L324.418 93.1102Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M306.77 67.29L305.7 66.67L306.77 66.05V67.29Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M54.5781 265.11L54.3682 264.99L54.5781 264.87V265.11Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M70.7891 291.43L69.269 292.31V290.56L70.7891 291.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M78.1875 306L75.8575 304.66L78.1875 303.31V306Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M78.4883 332.96L75.2583 331.1L78.4883 329.23V332.96Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M95.3789 331.1L91.3289 333.44V328.76L95.3789 331.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M125.168 334.49L119.288 331.1L125.168 327.71V334.49Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M142.949 331.1L136.239 334.97V327.23L142.949 331.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M171.75 335.85L163.53 331.1L171.75 326.35V335.85Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M190.059 331.1L181.389 336.1V326.1L190.059 331.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M217.797 336.28L208.827 331.1L217.797 325.92V336.28Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M235.988 331.1L227.128 336.22V325.98L235.988 331.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M263.469 336.04L254.909 331.1L263.469 326.16V336.04Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M281.379 331.1L273.129 335.86V326.34L281.379 331.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M286.609 323.23L277.329 317.88L286.609 312.52V323.23Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M306.039 291.43L295.149 297.72V285.15L306.039 291.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M310.23 284.82L298.79 278.21L310.23 271.61V284.82Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M330.277 251.77L317.377 259.22V244.32L330.277 251.77Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M333.91 246.51L320.12 238.55L333.91 230.59V246.51Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M354.848 212.1L339.448 221V203.21L354.848 212.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M357.547 208.13L341.537 198.88L357.547 189.64V208.13Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M378.469 172.44L361.989 181.96V162.92L378.469 172.44Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M380.648 168.81L364.038 159.22L380.648 149.63V168.81Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M377.648 146L362.398 154.81V137.19L377.648 146Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M355.797 125.76L345.047 119.55L355.797 113.35V125.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M349.98 106.33L341.88 111.01V101.65L349.98 106.33Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M330.527 81.9902L326.877 79.8902L330.527 77.7802V81.9902Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M323.09 66.6701L320.98 67.8901V65.4502L323.09 66.6701Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M47.1602 278.21L46.7402 278.45V277.97L47.1602 278.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M54.8281 291.99L53.8682 291.43L54.8281 290.88V291.99Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M71.457 317.88L68.937 319.33V316.42L71.457 317.88Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M71.8789 344.32L68.7189 346.14V342.5L71.8789 344.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M101.859 347L97.2194 344.32L101.859 341.64V347Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M119.199 344.32L113.769 347.46V341.18L119.199 344.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M148.379 348.24L141.579 344.32L148.379 340.4V348.24Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M166.277 344.32L158.927 348.56V340.08L166.277 344.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M194.5 348.81L186.73 344.32L194.5 339.83V348.81Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M212.457 344.32L204.537 348.89V339.75L212.457 344.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M240.219 348.66L232.699 344.32L240.219 339.98V348.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M257.918 344.32L250.508 348.6V340.05L257.918 344.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M285.777 348.24L278.987 344.32L285.777 340.4V348.24Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M304.82 317.88L295.76 323.11V312.65L304.82 317.88Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M309.809 310.54L299.619 304.66L309.809 298.77V310.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M329.457 278.21L317.787 284.95V271.47L329.457 278.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M333.488 272.22L320.968 264.99L333.488 257.76V272.22Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M354.289 238.55L339.729 246.96V230.14L354.289 238.55Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M357.41 234.32L341.83 225.33L357.41 216.34V234.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M378.828 198.88L361.808 208.71V189.06L378.828 198.88Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M380.848 195.6L363.638 185.66L380.848 175.73V195.6Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M401.957 159.22L384.587 169.25V149.19L401.957 159.22Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M379.988 141.21L365.368 132.78L379.988 124.34V141.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M375.77 119.55L363.34 126.73V112.38L375.77 119.55Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M354.66 97.3398L347.33 93.1098L354.66 88.8799V97.3398Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M347.969 79.8903L342.889 82.8203V76.9603L347.969 79.8903Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M329.859 54.3999L328.219 53.4499L329.859 52.4899V54.3999Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M321.957 40.2302L321.537 40.4702V39.9802L321.957 40.2302Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M31.6797 278.33L31.4797 278.21L31.6797 278.09V278.33Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M47.6406 304.66L46.5007 305.32V303.99L47.6406 304.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M55.1172 318.94L53.2872 317.88L55.1172 316.82V318.94Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M78.6094 359.61L75.0193 357.54L78.6094 355.47V359.61Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M95.457 357.54L91.287 359.95V355.13L95.457 357.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M125.02 360.68L119.59 357.54L125.02 354.4V360.68Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M142.418 357.54L136.508 360.96V354.13L142.418 357.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M171.23 361.39L164.58 357.54L171.23 353.7V361.39Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M188.789 357.54L182.019 361.45V353.64L188.789 357.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M217.027 361.39L210.367 357.54L217.027 353.7V361.39Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M234.367 357.54L227.937 361.26V353.83L234.367 357.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M262.609 361L256.619 357.54L262.609 354.08V361Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M279.648 357.54L273.998 360.81V354.28L279.648 357.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M303.207 344.32L296.557 348.16V340.48L303.207 344.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M309.027 335.62L301.187 331.1L309.027 326.58V335.62Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M328.43 304.66L318.3 310.5V298.81L328.43 304.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M333 297.81L321.95 291.43L333 285.06V297.81Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M353.16 264.99L340.29 272.42V257.56L353.16 264.99Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M356.859 259.82L342.919 251.77L356.859 243.72V259.82Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M378.219 225.33L362.109 234.62V216.03L378.219 225.33Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M380.789 221.93L363.769 212.1L380.789 202.28V221.93Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M402.199 185.66L384.479 195.89V175.43L402.199 185.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M403.969 182.75L386.119 172.44L403.969 162.13V182.75Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M403.629 155.72L386.789 146L403.629 136.28V155.72Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M400.699 132.78L385.219 141.71V123.84L400.699 132.78Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M378.867 112.84L367.597 106.33L378.867 99.8298V112.84Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M373.379 93.1102L364.529 98.2202V88.0002L373.379 93.1102Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M353.758 69.3501L349.118 66.6701L353.758 63.9901V69.3501Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M346.41 53.45L343.67 55.02V51.87L346.41 53.45Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M329.32 27.0103L329.31 27.0002H329.32V27.0103Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M24.2188 291.43L23.8488 291.65V291.22L24.2188 291.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M31.8594 305.08L31.1094 304.66L31.8594 304.23V305.08Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M48.1172 331.1L46.2571 332.18V330.02L48.1172 331.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M55.3281 345.74L52.8682 344.32L55.3281 342.9V345.74Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M55.3906 372.29L52.7407 370.76L55.3906 369.23V372.29Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M71.8789 370.76L68.7189 372.59V368.94L71.8789 370.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M101.699 373.17L97.5292 370.76L101.699 368.36V373.17Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M118.68 370.76L114.03 373.45V368.08L118.68 370.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M147.898 373.85L142.538 370.76L147.898 367.67V373.85Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M165.078 370.76L159.528 373.97V367.56L165.078 370.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M193.777 373.99L188.177 370.76L193.777 367.54V373.99Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M210.828 370.76L205.348 373.93V367.6L210.828 370.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M239.418 373.72L234.298 370.76L239.418 367.81V373.72Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M256.23 370.76L251.36 373.58V367.95L256.23 370.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M285 373.33L280.55 370.76L285 368.19V373.33Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M301.758 370.76L297.288 373.34V368.18L301.758 370.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M308.25 360.72L302.74 357.54L308.25 354.36V360.72Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M326.828 331.1L319.108 335.56V326.64L326.828 331.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M332.27 323L323.4 317.88L332.27 312.75V323Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M352.02 291.43L340.87 297.87V285L352.02 291.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M356.219 285.14L344.219 278.21L356.219 271.28V285.14Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M376.91 251.77L362.77 259.94V243.6L376.91 251.77Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M380.199 247.35L364.949 238.55L380.199 229.75V247.35Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M401.848 212.1L384.648 222.04V202.17L401.848 212.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M403.938 209.14L386.167 198.88L403.938 188.63V209.14Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M402.738 127.73L388.578 119.55L402.738 111.38V127.73Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M398.43 106.33L386.36 113.3V99.3698L398.43 106.33Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M377.68 84.3301L369.99 79.8901L377.68 75.4501V84.3301Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M371.379 66.6698L365.529 70.0498V63.2898L371.379 66.6698Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M352.879 41.3799L350.879 40.2299L352.879 39.0699V41.3799Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M345 27.0001L344.37 27.3701V26.6401L345 27.0001Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M8.77734 291.55L8.56732 291.43L8.77734 291.32V291.55Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M24.5898 317.88L23.6699 318.41V317.35L24.5898 317.88Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M32.0703 331.89L30.7003 331.1L32.0703 330.3V331.89Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M48.4102 357.54L46.1102 358.87V356.22L48.4102 357.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M48.4375 383.98L46.0875 385.34V382.63L48.4375 383.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M78.457 385.81L75.307 383.98L78.457 382.16V385.81Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M95.0078 383.98L91.5078 386.01V381.96L95.0078 383.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M124.609 386.41L120.409 383.98L124.609 381.56V386.41Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M141.438 383.98L136.997 386.54V381.43L141.438 383.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M170.578 386.7L165.878 383.98L170.578 381.27V386.7Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M187.348 383.98L182.738 386.65V381.32L187.348 383.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M216.289 386.55L211.849 383.98L216.289 381.42V386.55Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M232.898 383.98L228.668 386.43V381.54L232.898 383.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M261.898 386.21L258.048 383.98L261.898 381.76V386.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M278.289 383.98L274.679 386.07V381.9L278.289 383.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M307.648 386.13L303.938 383.98L307.648 381.84V386.13Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M325.34 357.54L319.85 360.71V354.37L325.34 357.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M331.508 348.13L324.918 344.32L331.508 340.52V348.13Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M350.438 317.88L341.647 322.95V312.81L350.438 317.88Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M355.559 310.44L345.529 304.66L355.559 298.87V310.44Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M375.367 278.21L363.537 285.05V271.38L375.367 278.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M379.359 272.34L366.629 264.99L379.359 257.64V272.34Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M400.449 238.55L385.349 247.26V229.83L400.449 238.55Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M403.41 234.68L387.22 225.33L403.41 215.98V234.68Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M401.398 98.96L391.258 93.11L401.398 87.26V98.96Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M395.93 79.8899L387.61 84.6899V75.0899L395.93 79.8899Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M376.688 56.1699L371.967 53.45L376.688 50.7199V56.1699Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M369.359 40.2301L366.539 41.8501V38.6001L369.359 40.2301Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M352.258 13.8599L352.128 13.7798L352.258 13.7099V13.8599Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M1.32031 304.66L0.950317 304.87V304.44L1.32031 304.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M8.91797 318.24L8.28796 317.88L8.91797 317.51V318.24Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M24.918 344.32L23.498 345.14V343.5L24.918 344.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M32.1992 358.56L30.4293 357.54L32.1992 356.52V358.56Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M55.3203 398.6L52.9003 397.21L55.3203 395.81V398.6Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M71.5703 397.21L68.8803 398.76V395.66L71.5703 397.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M101.359 399.02L98.2194 397.21L101.359 395.39V399.02Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M117.828 397.21L114.448 399.16V395.26L117.828 397.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M147.34 399.33L143.66 397.21L147.34 395.08V399.33Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M163.848 397.21L160.138 399.35V395.06L163.848 397.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M193.129 399.32L189.469 397.21L193.129 395.09V399.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M209.539 397.21L205.999 399.25V395.16L209.539 397.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M238.809 399.11L235.509 397.21L238.809 395.3V399.11Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M255.027 397.21L251.957 398.98V395.43L255.027 397.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M284.457 398.85L281.617 397.21L284.457 395.57V398.85Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M300.707 397.21L297.817 398.88V395.53L300.707 397.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M324.27 383.98L320.39 386.23V381.74L324.27 383.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M330.848 373.43L326.238 370.76L330.848 368.1V373.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M348.949 344.32L342.399 348.11V340.54L348.949 344.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M354.719 335.43L347.209 331.1L354.719 326.77V335.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M373.93 304.66L364.26 310.24V299.07L373.93 304.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M378.629 297.53L368.079 291.43L378.629 285.34V297.53Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M398.598 264.99L386.268 272.11V257.88L398.598 264.99Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M402.527 259.59L388.987 251.77L402.527 243.95V259.59Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M400.289 70.6099L393.459 66.6699L400.289 62.7299V70.6099Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M393.719 53.4498L388.709 56.3398V50.5498L393.719 53.4498Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M375.699 28.02L373.939 27L375.699 25.98V28.02Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M367.898 13.7798L367.268 14.1499V13.4199L367.898 13.7798Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M1.57031 331.1L0.830322 331.53V330.67L1.57031 331.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M9.05859 344.92L8.00854 344.32L9.05859 343.72V344.92Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M25.0781 370.76L23.4282 371.72V369.81L25.0781 370.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M32.2383 385.07L30.3583 383.98L32.2383 382.9V385.07Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M32.1602 411.38L30.5101 410.43L32.1602 409.48V411.38Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M48.207 410.43L46.207 411.58V409.28L48.207 410.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M78.1875 411.78L75.8575 410.43L78.1875 409.08V411.78Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M94.3086 410.43L91.8586 411.84V409.02L94.3086 410.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M124.098 411.97L121.428 410.43L124.098 408.89V411.97Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M140.328 410.43L137.548 412.03V408.83L140.328 410.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M169.938 412.03L167.158 410.43L169.938 408.83V412.03Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M186.117 410.43L183.357 412.03V408.83L186.117 410.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M215.688 411.96L213.047 410.43L215.688 408.9V411.96Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M231.809 410.43L229.219 411.93V408.93L231.809 410.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M261.379 411.76L259.079 410.43L261.379 409.1V411.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M277.34 410.43L275.15 411.69V409.16L277.34 410.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M307.23 411.84L304.79 410.43L307.23 409.02V411.84Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M323.488 410.43L320.778 411.99V408.86L323.488 410.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M330.457 399.18L327.037 397.21L330.457 395.23V399.18Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M347.758 370.76L342.988 373.52V368.01L347.758 370.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M354.07 360.75L348.51 357.54L354.07 354.34V360.75Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M372.309 331.1L365.069 335.28V326.92L372.309 331.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M377.82 322.56L369.71 317.88L377.82 313.2V322.56Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M397.09 291.43L387.03 297.24V285.63L397.09 291.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M401.617 284.46L390.797 278.21L401.617 271.96V284.46Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M399.129 42.1401L395.799 40.2301L399.129 38.3101V42.1401Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M391.707 26.9999L389.717 28.1499V25.8599L391.707 26.9999Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M1.76953 357.54L0.729553 358.14V356.95L1.76953 357.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M9.12891 371.5L7.85895 370.76L9.12891 370.03V371.5Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M25.0781 397.21L23.4282 398.16V396.25L25.0781 397.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M24.8477 423.65L23.5377 424.4V422.9L24.8477 423.65Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M55.0586 424.6L53.4086 423.65L55.0586 422.7V424.6Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M70.9375 423.65L69.1875 424.66V422.64L70.9375 423.65Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M100.898 424.66L99.1385 423.65L100.898 422.64V424.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M116.789 423.65L114.969 424.7V422.6L116.789 423.65Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M146.73 424.72L144.88 423.65L146.73 422.58V424.72Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M162.609 423.65L160.769 424.71V422.59L162.609 423.65Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M192.527 424.71L190.677 423.65L192.527 422.59V424.71Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M208.328 423.65L206.608 424.65V422.65L208.328 423.65Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M238.289 424.65L236.559 423.65L238.289 422.65V424.65Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M254.047 423.65L252.447 424.58V422.72L254.047 423.65Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M284.047 424.58L282.437 423.65L284.047 422.72V424.58Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M299.957 423.65L298.187 424.67V422.63L299.957 423.65Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M330.117 425.04L327.707 423.65L330.117 422.26V425.04Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M346.969 397.21L343.389 399.28V395.14L346.969 397.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M353.648 386.46L349.348 383.98L353.648 381.51V386.46Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M371.129 357.54L365.659 360.7V354.39L371.129 357.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M377.16 347.86L371.03 344.32L377.16 340.78V347.86Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M395.43 317.88L387.86 322.25V313.5L395.43 317.88Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M400.848 309.56L392.348 304.66L400.848 299.75V309.56Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M398.297 14.2803L397.447 13.7803L398.297 13.2903V14.2803Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M1.83984 383.98L0.689819 384.65V383.33L1.83984 383.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M9.14062 397.96L7.84064 397.21L9.14062 396.45V397.96Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M31.9688 437.49L30.8887 436.87L31.9688 436.25V437.49Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M47.668 436.87L46.478 437.56V436.18L47.668 436.87Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M77.8086 437.56L76.6086 436.87L77.8086 436.18V437.56Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M93.457 436.87L92.277 437.55V436.19L93.457 436.87Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M123.57 437.49L122.5 436.87L123.57 436.26V437.49Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M139.188 436.87L138.117 437.49V436.26L139.188 436.87Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M169.359 437.48L168.309 436.87L169.359 436.26V437.48Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M184.98 436.87L183.93 437.48V436.26L184.98 436.87Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M215.199 437.54L214.039 436.87L215.199 436.21V437.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M230.859 436.87L229.689 437.54V436.2L230.859 436.87Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M261 437.54L259.84 436.87L261 436.2V437.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M276.68 436.87L275.48 437.56V436.18L276.68 436.87Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M306.969 437.83L305.309 436.87L306.969 435.92V437.83Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M323.02 436.87L321.01 438.03V435.71L323.02 436.87Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M346.41 423.65L343.67 425.23V422.07L346.41 423.65Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M353.289 412.29L350.059 410.43L353.289 408.57V412.29Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M370.398 383.98L366.018 386.51V381.46L370.398 383.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M376.738 373.58L371.868 370.76L376.738 367.95V373.58Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M394.207 344.32L388.467 347.64V341.01L394.207 344.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M400.078 334.68L393.888 331.1L400.078 327.52V334.68Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M1.79688 410.43L0.716858 411.05V409.8L1.79688 410.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M9.04688 424.25L8.01685 423.65L9.04688 423.05V424.25Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M8.87891 450.4L8.35895 450.09L8.87891 449.79V450.4Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M24.3984 450.09L23.7684 450.46V449.73L24.3984 450.09Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M54.7773 450.56L53.9673 450.09L54.7773 449.63V450.56Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M70.2695 450.09L69.5295 450.52V449.66L70.2695 450.09Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M100.488 450.4L99.9583 450.09L100.488 449.79V450.4Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M115.918 450.09L115.408 450.39V449.8L115.918 450.09Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M146.277 450.38L145.777 450.09L146.277 449.8V450.38Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M161.707 450.09L161.207 450.38V449.8L161.707 450.09Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M192.117 450.44L191.507 450.09L192.117 449.74V450.44Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M207.66 450.09L206.94 450.51V449.68L207.66 450.09Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M238 450.59L237.14 450.09L238 449.59V450.59Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M253.609 450.09L252.669 450.64V449.55L253.609 450.09Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M283.898 450.77L282.738 450.09L283.898 449.42V450.77Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M299.73 450.09L298.31 450.92V449.27L299.73 450.09Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M329.988 451.27L327.948 450.09L329.988 448.92V451.27Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M346.168 450.09L343.788 451.47V448.71L346.168 450.09Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M353.117 438.43L350.407 436.87L353.117 435.31V438.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M369.867 410.43L366.287 412.49V408.37L369.867 410.43Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M376.449 399.53L372.439 397.21L376.449 394.89V399.53Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M393.578 370.76L388.788 373.53V368L393.578 370.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M399.648 360.37L394.758 357.54L399.648 354.72V360.37Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M1.53906 436.87L0.83905 437.27V436.47L1.53906 436.87Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M1.14062 463.32L1.04065 463.37V463.26L1.14062 463.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M31.7383 463.55L31.3383 463.32L31.7383 463.08V463.55Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M47.1602 463.32L46.7402 463.55V463.08L47.1602 463.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M77.5078 463.49L77.2078 463.32L77.5078 463.14V463.49Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M92.7969 463.32L92.6069 463.42V463.2L92.7969 463.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M123.238 463.37L123.148 463.32L123.238 463.26V463.37Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M138.539 463.32L138.449 463.37V463.26L138.539 463.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M169.09 463.44L168.86 463.32L169.09 463.19V463.44Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M184.488 463.32L184.168 463.5V463.13L184.488 463.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M214.988 463.63L214.448 463.32L214.988 463V463.63Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M230.559 463.32L229.839 463.73V462.9L230.559 463.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M260.93 463.86L259.99 463.32L260.93 462.77V463.86Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M276.609 463.32L275.519 463.94V462.69L276.609 463.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M306.93 464.2L305.39 463.32L306.93 462.42V464.2Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M322.938 463.32L321.047 464.41V462.22L322.938 463.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M353.039 464.74L350.579 463.32L353.039 461.89V464.74Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M369.469 436.87L366.489 438.59V435.15L369.469 436.87Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M376.23 425.58L372.89 423.65L376.23 421.72V425.58Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M393.141 397.21L389.001 399.6V394.82L393.141 397.21Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M399.469 386.51L395.099 383.98L399.469 381.46V386.51Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M23.9805 476.54H23.9705V476.53L23.9805 476.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M54.5391 476.59L54.449 476.54L54.5391 476.48V476.59Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M191.98 476.66L191.77 476.54L191.98 476.41V476.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M207.438 476.54L207.047 476.76V476.31L207.438 476.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M237.949 476.95L237.229 476.54L237.949 476.12V476.95Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M253.559 476.54L252.689 477.03V476.04L253.559 476.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M283.91 477.22L282.72 476.54L283.91 475.85V477.22Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M299.73 476.54L298.31 477.36V475.71L299.73 476.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M329.957 477.65L328.027 476.54L329.957 475.42V477.65Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M346.02 476.54L343.86 477.78V475.29L346.02 476.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M369.309 463.32L366.569 464.9V461.73L369.309 463.32Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M376.109 451.81L373.129 450.09L376.109 448.37V451.81Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M392.758 423.65L389.188 425.71V421.59L392.758 423.65Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M399.297 412.66L395.437 410.43L399.297 408.2V412.66Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M184.328 489.76L184.248 489.8V489.71L184.328 489.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M214.938 489.98L214.547 489.76L214.938 489.53V489.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M230.438 489.76L229.897 490.06V489.45L230.438 489.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M260.898 490.25L260.038 489.76L260.898 489.26V490.25Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M276.547 489.76L275.547 490.34V489.18L276.547 489.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M306.891 490.58L305.461 489.76L306.891 488.93V490.58Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M322.809 489.76L321.119 490.74V488.78L322.809 489.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M352.918 490.98L350.798 489.76L352.918 488.53V490.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M369 489.76L366.72 491.07V488.45L369 489.76Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M375.988 478.05L373.368 476.54L375.988 475.02V478.05Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M392.527 450.09L389.307 451.95V448.24L392.527 450.09Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M399.168 438.87L395.708 436.87L399.168 434.88V438.87Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M191.949 503.04L191.839 502.98L191.949 502.92V503.04Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M207.32 502.98L207.11 503.1V502.86L207.32 502.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M237.891 503.29L237.361 502.98L237.891 502.67V503.29Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M253.48 502.98L252.73 503.41V502.55L253.48 502.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M283.879 503.61L282.789 502.98L283.879 502.35V503.61Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M299.648 502.98L298.348 503.73V502.23L299.648 502.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M329.867 503.94L328.207 502.98L329.867 502.02V503.94Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M345.828 502.98L343.958 504.06V501.9L345.828 502.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M375.859 504.26L373.629 502.98L375.859 501.7V504.26Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M392.277 476.54L389.427 478.18V474.89L392.277 476.54Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M399.047 465.1L395.947 463.32L399.047 461.53V465.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M184.289 516.2L184.279 516.21V516.2H184.289Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M214.918 516.38L214.598 516.2L214.918 516.02V516.38Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M230.359 516.2L229.939 516.45V515.96L230.359 516.2Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M260.859 516.64L260.109 516.2L260.859 515.77V516.64Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M276.527 516.2L275.557 516.76V515.64L276.527 516.2Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M306.848 516.96L305.538 516.2L306.848 515.44V516.96Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M322.699 516.2L321.169 517.09V515.32L322.699 516.2Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M352.84 517.28L350.97 516.2L352.84 515.12V517.28Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M368.82 516.2L366.81 517.36V515.05L368.82 516.2Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M391.938 502.98L389.598 504.33V501.63L391.938 502.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M398.891 491.27L396.271 489.76L398.891 488.25V491.27Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M191.949 529.48L191.839 529.42L191.949 529.36V529.48Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M207.32 529.42L207.11 529.54V529.3L207.32 529.42Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M237.891 529.73L237.361 529.42L237.891 529.12V529.73Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M253.41 529.42L252.77 529.79V529.05L253.41 529.42Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M283.84 529.98L282.86 529.42L283.84 528.86V529.98Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M299.578 529.42L298.378 530.12V528.73L299.578 529.42Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M329.828 530.31L328.288 529.42L329.828 528.53V530.31Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M345.688 529.42L344.027 530.38V528.46L345.688 529.42Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M375.758 530.53L373.828 529.42L375.758 528.31V530.53Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M391.738 529.42L389.698 530.6V528.25L391.738 529.42Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M398.758 517.49L396.518 516.2L398.758 514.91V517.49Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M169.02 542.65L169 542.64H169.02V542.65Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M184.348 542.64L184.238 542.71V542.58L184.348 542.64Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M214.918 542.83L214.588 542.64L214.918 542.45V542.83Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M230.438 542.64L229.897 542.95V542.34L230.438 542.64Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M260.859 543.08L260.109 542.64L260.859 542.21V543.08Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M276.527 542.64L275.557 543.21V542.08L276.527 542.64Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M306.828 543.36L305.588 542.64L306.828 541.93V543.36Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M322.629 542.64L321.209 543.47V541.82L322.629 542.64Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M352.777 543.62L351.087 542.64L352.777 541.67V543.62Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M368.688 542.64L366.878 543.69V541.6L368.688 542.64Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M398.699 543.82L396.659 542.64L398.699 541.47V543.82Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M161.391 555.86L161.381 555.87V555.86H161.391Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M191.98 555.99L191.77 555.86L191.98 555.74V555.99Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M207.391 555.86L207.071 556.05V555.68L207.391 555.86Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M237.891 556.17L237.361 555.86L237.891 555.56V556.17Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M253.48 555.86L252.73 556.3V555.43L253.48 555.86Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M283.867 556.49L282.797 555.86L283.867 555.24V556.49Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M299.578 555.86L298.378 556.56V555.17L299.578 555.86Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M329.789 556.69L328.359 555.86L329.789 555.04V556.69Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M345.629 555.86L344.059 556.78V554.96L345.629 555.86Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M375.738 556.96L373.858 555.86L375.738 554.77V556.96Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M391.719 555.86L389.709 557.02V554.71L391.719 555.86Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M169.02 569.1L169 569.09L169.02 569.07V569.1Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M184.359 569.09L184.239 569.16V569.02L184.359 569.09Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M214.957 569.33L214.527 569.09L214.957 568.84V569.33Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M230.438 569.09L229.897 569.4V568.78L230.438 569.09Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M260.898 569.59L260.038 569.09L260.898 568.59V569.59Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M276.527 569.09L275.557 569.65V568.52L276.527 569.09Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M306.82 569.78L305.61 569.09L306.82 568.39V569.78Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M322.629 569.09L321.209 569.91V568.26L322.629 569.09Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M352.77 570.04L351.11 569.09L352.77 568.13V570.04Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M368.66 569.09L366.89 570.11V568.06L368.66 569.09Z' fill='#5B9E4F' fillOpacity='0.2' />
      <path d='M398.688 570.25L396.678 569.09L398.688 567.93V570.25Z' fill='#5B9E4F' fillOpacity='0.2' />
    </svg>
  )
}
