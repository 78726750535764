import React from 'react'
import { Col, Row } from 'antd'
import { useIntl } from 'react-intl'
import { GreenBlur } from '../GreenBlur'
import qrcodePhone from '../../../asserts/png/landing-phone-qrcode.png'
import shops from '../../../asserts/png/landing-shops.png'
import createQR from '../../../asserts/png/landing-createQR.png'
import addConsultant from '../../../asserts/png/landing-add-consultant.png'
import consultant from '../../../asserts/png/landing-consultant.png'
import iphone2 from '../../../asserts/png/landing-iphone2.png'
import { PatternIcon } from '../../../asserts/svg/PatternIcon'
import { Line } from '../Line'

export const MainBlock7 = () => {
  const intl = useIntl()

  return (
    <Row justify='center' className='py-md-80 py-40' style={{ position: 'relative', background: '#0D181E' }}>
      <Col id='web-app' xl={20} xs={22} style={{ zIndex: 100, position: 'relative' }}>
        <Row justify='center' className='mb-md-210 mb-20' gutter={[0, 16]}>
          <Col md={0} xs={24}>
            <Line style={{ marginLeft: 0, justifyContent: 'flex-start', width: '24%' }}>2</Line>
          </Col>
          <Col md={14} xs={24}>
            <p className='landing-text-46'>
              {intl.formatMessage({ id: 'Implementation using the customers\' mobile phone' })}
            </p>
          </Col>
        </Row>
        <div className='hidden block-md' style={{ position: 'absolute', left: 0, top: 14, width: 380 }}>
          <Line style={{ marginLeft: 0, justifyContent: 'flex-start' }}>2</Line>
        </div>
      </Col>
      <Col xl={20} md={22} xs={24} style={{ zIndex: 100, position: 'relative' }}>
        <Row className='mb-md-110 mb-50'>
          <Col span={24}>
            <div className='basic-info-container'>
              <Row justify='center' gutter={[32, 16]} className='py-md-60'>
                <Col md={0} xs={24} style={{ textAlign: 'center' }}>
                  <img
                    width='140%'
                    style={{ transform: 'rotate(-15deg)', maxWidth: 550 }}
                    loading='lazy'
                    src={qrcodePhone}
                    alt='landing-qrcode-phone_image'
                  />
                </Col>
                <Col md={7} xs={24}>
                  <div style={{ width: 'calc(14%)', height: 2, background: '#489E38' }} />
                  <p className='landing-text-20-thin' style={{ marginTop: 6 }}>
                    {intl.formatMessage({ id: 'In this alternative, a customer scans a QR code' })}
                  </p>
                </Col>
                <Col md={7} xs={0} style={{ position: 'relative' }}>
                  <img
                    style={{ position: 'absolute', top: -174 }}
                    height={460}
                    loading='lazy'
                    src={qrcodePhone}
                    alt='landing-qrcode-phone_image'
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row justify='center'>
          <Col md={24} xs={22}>
            <p className='landing-text-28 text-align-center text-align-md-left'>
              {intl.formatMessage({ id: 'The web application includes:' })}
            </p>
          </Col>
        </Row>
        <Row className='mb-md-32 mb-20'>
          <Col md={16} xs={24}>
            <div className='basic-info-container' style={{ padding: 10 }}>
              <Row gutter={[32, 24]}>
                <Col sm={12} xs={24}>
                  <img width='100%' className='br-16' loading='lazy' src={shops} alt='landing-shops_image' />
                </Col>
                <Col sm={11} xs={24} className='flex flex-column-center'>
                  <div style={{ width: 'calc(14%)', height: 2, background: '#489E38' }} />
                  <p className='landing-text-20-thin' style={{ marginTop: 6 }}>
                    {intl.formatMessage({ id: 'An admin panel in which store/organization' })}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row justify='center' className='mb-md-32 mb-20'>
          <Col md={16} xs={24}>
            <div className='basic-info-container' style={{ padding: 10 }}>
              <Row gutter={[32, 24]}>
                <Col sm={12} xs={24}>
                  <img width='100%' className='br-16' loading='lazy' src={addConsultant} alt='landing-add-cons_image' />
                </Col>
                <Col sm={11} xs={24} className='flex flex-column-center'>
                  <div style={{ width: 'calc(14%)', height: 2, background: '#489E38' }} />
                  <p className='landing-text-20-thin' style={{ marginTop: 6 }}>
                    {intl.formatMessage({ id: 'Add consultants, assign them to certain shops/organizations' })}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row justify='end' className='mb-md-32 mb-20'>
          <Col md={16} xs={24}>
            <div className='basic-info-container' style={{ padding: 10 }}>
              <Row gutter={[32, 24]}>
                <Col sm={12} xs={24}>
                  <img width='100%' className='br-16' loading='lazy' src={createQR} alt='landing-createQR_image' />
                </Col>
                <Col sm={11} xs={24} className='flex flex-column-center'>
                  <div style={{ width: 'calc(14%)', height: 2, background: '#489E38' }} />
                  <p className='landing-text-20-thin' style={{ marginTop: 6 }}>
                    {intl.formatMessage({ id: 'The ability to generate QR codes' })}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className='mb-md-32 mb-20'>
          <Col span={24}>
            <div className='basic-info-container' style={{ padding: 10 }}>
              <Row gutter={[24, 24]} justify='center'>
                <Col
                  md={{ span: 7, order: 1 }}
                  sm={{ span: 12, order: 2 }}
                  xs={{ span: 24, order: 2 }}
                  className='flex flex-column-center'
                >
                  <div style={{ width: 'calc(14%)', height: 2, background: '#489E38' }} />
                  <p className='landing-text-20-thin' style={{ marginTop: 6 }}>
                    {intl.formatMessage({
                      id: 'Panel for consultants - in which they receive calls from the customers',
                    })}
                  </p>
                </Col>
                <Col md={{ span: 8, order: 2 }} sm={{ span: 12, order: 1 }} xs={{ span: 24, order: 1 }}>
                  <img width='100%' className='br-16' loading='lazy' src={consultant} alt='landing-consultant_image' />
                </Col>
                <Col md={{ span: 7, order: 3 }} xs={0} className='hidden flex-md flex-column-center '>
                  <Row>
                    <Col span={8}>
                      <img width='100%' loading='lazy' src={iphone2} alt='landing-iphone2_image' />
                    </Col>
                    <Col md={16} xs={24} className='flex flex-column-center'>
                      <div style={{ width: 'calc(14%)', height: 2, background: '#489E38' }} />
                      <p className='landing-text-20-thin' style={{ marginTop: 6 }}>
                        {intl.formatMessage({ id: 'An interface for customers with call functionality' })}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={0} xs={24}>
            <div className='basic-info-container' style={{ padding: 10 }}>
              <Row justify='center'>
                <Col sm={10} xs={12}>
                  <img width='100%' height='105%' loading='lazy' src={iphone2} alt='landing-iphone2_image' />
                </Col>
                <Col sm={10} xs={12} className='flex flex-column-center'>
                  <div style={{ width: 'calc(14%)', height: 2, background: '#489E38' }} />
                  <p className='landing-text-20-thin' style={{ marginTop: 6 }}>
                    {intl.formatMessage({ id: 'An interface for customers with call functionality' })}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
      <GreenBlur className='hidden block-md' style={{ top: -40, right: 40 }} blur='big' />
      <GreenBlur style={{ top: '50%', right: -40, background: 'rgba(91, 158, 79, 0.2)' }} blur='big' />
      <GreenBlur className='hidden-md' style={{ top: '60%', left: 0 }} />
      <GreenBlur className='hidden-md' style={{ top: '80%', left: 0 }} />
      <GreenBlur className='hidden-md' style={{ bottom: '10%', right: 0 }} />
      <PatternIcon style={{ position: 'absolute', right: -40, top: 40 }} />
      <PatternIcon style={{ position: 'absolute', left: 0, top: '10%', transform: 'rotate(180deg)' }} />
      <PatternIcon style={{ position: 'absolute', right: -40, top: '35%', transform: 'rotateX(180deg)' }} />
      <PatternIcon style={{ position: 'absolute', left: 0, top: '55%', transform: 'rotateY(180deg)' }} />
    </Row>
  )
}
