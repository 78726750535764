import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Header } from '../components/Header'
import { MainBlock1 } from '../components/landing/MainBlock1'
import { MainBlock2 } from '../components/landing/MainBlock2'
import { MainBlock3 } from '../components/landing/MainBlock3'
import { MainBlock4 } from '../components/landing/MainBlock4'
import { MainBlock5 } from '../components/landing/MainBlock5'
import { MainBlock6 } from '../components/landing/MainBlock6'
import { MainBlock7 } from '../components/landing/MainBlock7'
import { MainBlock8 } from '../components/landing/MainBlock8'
import { MainBlock9 } from '../components/landing/MainBlock9'
import { MainBlock10 } from '../components/landing/MainBlock10'
import { Footer } from '../components/Footer'
import { AppStore } from '../store/applicationState'
import { SendMessageContactUs, TypeSendMessageContactUsR } from '../store/contact-us/actions'

export const Landing = () => {
  const dispatch = useDispatch()
  const { ContactUs } = useSelector((store: AppStore) => store)

  const onContactUs = (data: TypeSendMessageContactUsR) => {
    dispatch(SendMessageContactUs.request(data))
  }

  return (
    <>
      <Header />
      <MainBlock1 />
      <MainBlock2 />
      <MainBlock3 />
      <MainBlock4 />
      <MainBlock5 />
      <MainBlock6 />
      <MainBlock7 />
      <MainBlock8 />
      <MainBlock9 />
      <MainBlock10 />
      <Footer onContactUs={onContactUs} loading={ContactUs.loading} />
    </>
  )
}
