import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ActionTypes, TTradePointMessage, TTradePointData } from './types'
import { callApi } from '../../utils/callApi'
import { API_ROUTES } from '../../utils/apiRoutes'
import * as Actions from './actions'
import { getQueryFromObj } from '../../utils/helpers'

function* getTradePointsWorker(action: ReturnType<typeof Actions.GetTradePoints.request>) {
  const { callBack, query } = action.payload as Actions.TypeGetTradePointsR
  let success: boolean = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tradePoints}${getQueryFromObj(query || {})}`,
    })) as TTradePointData | TTradePointMessage

    yield put(Actions.GetTradePoints.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetTradePoints.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getTradePointById(action: ReturnType<typeof Actions.GetTradePointById.request>) {
  const { callBack, id } = action.payload as Actions.TypeGetTradePointById
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tradePoints}/${id}`,
    })) as TTradePointMessage

    yield put(Actions.GetTradePointById.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetTradePointById.error(`${data?.detail || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* createNewTradePoint(action: ReturnType<typeof Actions.CreateNewTradePoint.request>) {
  const { body, callBack } = action.payload as Actions.TypeCreateNewTradePoint
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.tradePoints,
      data: body,
    })) as TTradePointMessage

    yield put(Actions.CreateNewTradePoint.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.CreateNewTradePoint.error(`${data?.detail || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* updateTradePoint(action: ReturnType<typeof Actions.UpdateTradePoint.request>) {
  const { callBack, body, id } = action.payload as Actions.TypeUpdateTradePoint
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'put',
      path: `${API_ROUTES.tradePoints}/${id}`,
      data: body,
    })) as TTradePointMessage

    yield put(Actions.UpdateTradePoint.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.UpdateTradePoint.error(`${data?.detail || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* deleteTradePoint(action: ReturnType<typeof Actions.DeleteTradePoint.request>) {
  const { callBack, id } = action.payload as Actions.TypeDeleteTradePoint
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'delete',
      path: `${API_ROUTES.tradePoints}/${id}`,
    })) as TTradePointMessage

    yield put(Actions.DeleteTradePoint.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.DeleteTradePoint.error(`${data?.detail || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_TRADE_POINTS_R, getTradePointsWorker)
  yield takeEvery(ActionTypes.GET_TRADE_POINT_BY_ID_R, getTradePointById)
  yield takeEvery(ActionTypes.CREATE_NEW_TRADE_POINT_R, createNewTradePoint)
  yield takeEvery(ActionTypes.UPDATE_TRADE_POINT_R, updateTradePoint)
  yield takeEvery(ActionTypes.DELETE_TRADE_POINT_R, deleteTradePoint)
}

export default function* tradePointSaga() {
  yield all([fork(watchRequest)])
}
