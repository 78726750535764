/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { LogoIcon } from '../asserts/svg/LogoIcon'
import { SignInIcon } from '../asserts/svg/SignInIcon'
import { MenuIcon } from '../asserts/svg/MenuIcon'
import { CloseMenuIcon } from '../asserts/svg/CloseMenuIcon'
import { useLockScroll } from '../hooks/useLockScroll'
import { AppStore } from '../store/applicationState'

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const intl = useIntl()
  const { General } = useSelector((store: AppStore) => store)

  const [menuOpened, setMenuOpened] = useState(false)
  useLockScroll(menuOpened)

  useEffect(() => {
    document.querySelectorAll('a').forEach((link) => {
      if (link.hash) {
        link.addEventListener('click', (e) => {
          e.preventDefault()
          window.location.href = link.href
          const href = link.getAttribute('href')?.substring(1)
          if (href) {
            const header = document.getElementsByTagName('header')[0].getElementsByTagName('div')[0]
            const scrollTarget = document.getElementById(href)
            if (scrollTarget) {
              const topOffset = header.offsetHeight + 20
              const elementPosition = scrollTarget.getBoundingClientRect().top

              if (elementPosition) {
                const offsetPosition = elementPosition - topOffset
                window.scrollBy({ top: offsetPosition })
              }
            }
          }
        })
      }
    })

    const onResize = () => {
      if (window.innerWidth >= 768) setMenuOpened(false)
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <header style={menuOpened ? { bottom: 0 } : {}}>
      <Row justify='center'>
        <Col xl={20} xs={22}>
          <Row align='middle' justify='space-between'>
            <Col lg={9} md={6} xs={10} className='logo-container'>
              <LogoIcon fill={menuOpened ? 'rgba(255, 255, 255, 0.7)' : ''} />
            </Col>
            <Col md={0}>
              {menuOpened ? (
                <button type='button' className='no-styles' onClick={() => setMenuOpened(false)}>
                  <CloseMenuIcon />
                </button>
              ) : (
                <button type='button' className='no-styles' onClick={() => setMenuOpened(true)}>
                  <MenuIcon />
                </button>
              )}
            </Col>
            <Col lg={7} md={7} xs={0}>
              <Row align='middle' justify='space-between'>
                <Col>
                  <a href='#about'>{intl.formatMessage({ id: 'About' })}</a>
                </Col>
                <Col>
                  <a href='#web-app'>{intl.formatMessage({ id: 'Web App' })}</a>
                </Col>
                <Col>
                  <a href='#pricing'>{intl.formatMessage({ id: 'Pricing' })}</a>
                </Col>
              </Row>
            </Col>
            <Col md={5} xs={0}>
              <Row justify='center'>
                <Col>
                  <button
                    type='button'
                    className='default-landing-btn'
                    onClick={() => {
                      window.location.href = '#contact-us'
                    }}
                  >
                    {intl.formatMessage({ id: 'Contact Us' })}
                  </button>
                </Col>
              </Row>
            </Col>
            <Col lg={3} md={5} xs={0}>
              <Row justify='center'>
                <Col span={24}>
                  <a href={General.locale === 'pl' ? 'https://consultant.udtech.eu/login' : '/login'}>
                    <Row align='middle' justify='space-around' wrap={false}>
                      <Col> {intl.formatMessage({ id: 'Sign in' })}</Col>
                      <Col style={{ display: 'flex', alignItems: 'center' }}>
                        <SignInIcon />
                      </Col>
                    </Row>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className='burger-container' style={menuOpened ? {} : { height: 0 }}>
        <div style={{ background: 'rgba(14, 44, 53, 1)', height: 1, width: '100%', marginBottom: 34 }} />
        <Row justify='center' gutter={[0, 20]}>
          <Col span={21}>
            <a href='#about' onClick={() => setMenuOpened(false)}>
              {intl.formatMessage({ id: 'About' })}
            </a>
          </Col>
          <Col span={21}>
            <div style={{ background: 'rgba(14, 44, 53, 1)', height: 1, width: '100%' }} />
          </Col>
          <Col span={21}>
            <a href='#web-app' onClick={() => setMenuOpened(false)}>
              {intl.formatMessage({ id: 'Web App' })}
            </a>
          </Col>
          <Col span={21}>
            <div style={{ background: 'rgba(14, 44, 53, 1)', height: 1, width: '100%' }} />
          </Col>
          <Col span={21}>
            <a href='#pricing' onClick={() => setMenuOpened(false)}>
              {intl.formatMessage({ id: 'Pricing' })}
            </a>
          </Col>
          <Col span={21}>
            <div style={{ background: 'rgba(14, 44, 53, 1)', height: 1, width: '100%' }} />
          </Col>
          <Col span={21}>
            <a href='#contact-us' onClick={() => setMenuOpened(false)}>
              {intl.formatMessage({ id: 'Contact Us' })}
            </a>
          </Col>
          <Col span={21}>
            <div style={{ background: 'rgba(14, 44, 53, 1)', height: 1, width: '100%' }} />
          </Col>
        </Row>
      </div>
    </header>
  )
}
