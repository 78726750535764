import { Actions } from '../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../types/actions'

/// ///////////////////////////////////////////////////////////////////////
const GetCurrentUserInfoActions = new Actions('GET_CURRENT_USER_INFO', ActionTypes)

export interface TypeGetCurrentUserInfoR extends TDefRequest {}

export const GetCurrentUserInfo = {
  request: (payload?: TypeGetCurrentUserInfoR) => GetCurrentUserInfoActions.request(payload || {}),
  success: (payload: any) => GetCurrentUserInfoActions.success(payload),
  error: (payload: string) => GetCurrentUserInfoActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
export const SetOnline = (payload: boolean) => ({ type: ActionTypes.SET_ONLINE, payload })

/// ///////////////////////////////////////////////////////////////////////
export interface TypeSetQRCodeData extends TDefRequest {
  retailer_tag_id?: string
  trade_point_id?: string
  call_started?: boolean
}

export const SetQRCodeData = (payload: TypeSetQRCodeData | null) => ({
  type: ActionTypes.SET_QR_CODE_DATA,
  payload,
})

/// ///////////////////////////////////////////////////////////////////////
const UpdateMyPasswordActions = new Actions('UPDATE_MY_PASSWORD', ActionTypes)

export interface TypeUpdateMyPasswordR extends TDefRequest {
  old_password: string
  new_password: string
  confirm_new_password: string
}

export const UpdateMyPassword = {
  request: (payload: TypeUpdateMyPasswordR) => UpdateMyPasswordActions.request(payload),
  success: (payload: any) => UpdateMyPasswordActions.success(payload),
  error: (payload: string) => UpdateMyPasswordActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const UpdateMyInfoActions = new Actions('UPDATE_MY_INFO', ActionTypes)

export interface TypeUpdateMyInfoR extends TDefRequest {
  first_name: string
  last_name: string
}

export const UpdateMyInfo = {
  request: (payload: TypeUpdateMyInfoR) => UpdateMyInfoActions.request(payload),
  success: (payload: any) => UpdateMyInfoActions.success(payload),
  error: (payload: string) => UpdateMyInfoActions.error(payload),
}
