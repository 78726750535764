import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Form, Input, Col, message, Breadcrumb, Typography } from 'antd'
import { Container } from '../wrappers/Container'
import { GetTradePoints, CreateNewTradePoint, GetTradePointById, UpdateTradePoint } from '../store/tradePoints/actions'
import { ROUTES } from '../utils/routes'
import { AppStore } from '../store/applicationState'

interface CreateTradePointViewProps {}

export const TradePointView: React.FC<CreateTradePointViewProps> = () => {
  const history = useHistory()
  const [form] = Form.useForm()
  const { id } = useParams<{ id?: string }>()
  const dispatch = useDispatch()
  const { TradePoints } = useSelector((store: AppStore) => store)

  useEffect(() => {
    if (id) {
      dispatch(
        GetTradePointById.request({
          id,
          callBack: (success, data) => {
            if (success) {
              form.setFieldsValue({
                name: data?.name,
                address: data?.address,
                phone: data?.phone,
              })
            } else {
              message.error(data.message)
            }
          },
        })
      )
    }
  }, [])

  const onSubmitForm = (values: any) => {
    if (values) {
      dispatch(
        CreateNewTradePoint.request({
          body: {
            name: values.name,
            address: values.address,
            phone: values.phone,
          },
          callBack: (success, err) => {
            if (success) {
              dispatch(GetTradePoints.request())
              message.success('Shop added')
              history.push(ROUTES.tradePoints)
            } else {
              message.error(err.message)
            }
          },
        })
      )
    }
    return null
  }

  const onUpdate = (values: any) => {
    if (values && id) {
      dispatch(
        UpdateTradePoint.request({
          id,
          body: {
            name: values.name,
            address: values.address,
            phone: values.phone,
          },
          callBack: (success, err) => {
            if (success) {
              dispatch(GetTradePoints.request())
              message.success('Shop updated')
              history.push(ROUTES.tradePoints)
            } else {
              message.error(err.message)
            }
          },
        })
      )
    }
    return null
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item>Shops</Breadcrumb.Item>
        <Breadcrumb.Item>{id ? 'Edit shop' : 'Create shop'}</Breadcrumb.Item>
      </Breadcrumb>
      <Typography.Title level={3} className='pageTitle'>
        {id ? 'Edit shop' : 'Create shop'}
      </Typography.Title>
      <Form
        form={form}
        className='gx-form-inline-label-up gx-form-row0 '
        size='large'
        onFinish={(values) => {
          if (id) {
            onUpdate(values)
          } else {
            onSubmitForm(values)
          }
        }}
      >
        <Col span={8}>
          <Form.Item
            required
            rules={[{ required: true, message: 'Please enter shop name' }]}
            initialValue={TradePoints?.data?.tradePointForEdit?.name}
            label='Name shop'
            name='name'
            labelCol={{ span: 24 }}
          >
            <Input maxLength={80} placeholder='Name' />
          </Form.Item>
          <Form.Item
            initialValue={TradePoints?.data?.tradePointForEdit?.address}
            label='Address'
            name='address'
            labelCol={{ span: 24 }}
          >
            <Input maxLength={80} placeholder='Address' />
          </Form.Item>
          <Form.Item
            initialValue={TradePoints?.data?.tradePointForEdit?.phone}
            label='Phone'
            name='phone'
            labelCol={{ span: 24 }}
          >
            <Input maxLength={80} placeholder='Phone' />
          </Form.Item>
          <Form.Item>
            <Button
              className='submit-button'
              size='large'
              type='primary'
              style={{ width: '100%' }}
              onClick={() => form.submit()}
            >
              Save
            </Button>
          </Form.Item>
        </Col>
      </Form>
    </Container>
  )
}
