/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Card, Row, Col, Pagination, Dropdown, Menu, message, Modal } from 'antd'
import { Container } from '../../wrappers/Container'
import { snakeCaseToString } from '../../utils/helpers'
import { TRetailerTag, TRetailerTagsData } from '../../store/retailerTags/types'
import { DeleteRetailerTag } from '../../store/retailerTags/actions'
import { TTradePoint } from '../../store/tradePoints/types'

interface RetailerTagsListProps {
  retailerTagsData: TRetailerTagsData
  onPageClick: (page: number, pageSize: number) => void
  pagination: any
  currentPage: number
}

export const RetailerTagsList: React.FC<RetailerTagsListProps> = ({
  retailerTagsData,
  onPageClick,
  pagination,
  currentPage,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [modalData, setModalData] = useState<TRetailerTag | null>(null)

  const editRetailerTag = (rTag: TRetailerTag) => {
    history.push({ pathname: `/departments/edit/${rTag.id}` })
  }

  const removeRetailerTag = (rTag: TRetailerTag) => {
    dispatch(
      DeleteRetailerTag.request({
        id: rTag.id,
        callBack: (success) => {
          if (success) {
            message.success('Department removed')
            onPageClick(1, pagination.current.limit)
          }
        },
      })
    )
  }

  const handleOk = (data: TTradePoint | null) => {
    if (data) removeRetailerTag(data)
    setModalData(null)
  }

  const handleCancel = () => setModalData(null)

  return (
    <Container>
      <Row gutter={16}>
        {retailerTagsData?.RetailerTagInformation?.length > 0 &&
          retailerTagsData?.RetailerTagInformation.map((retailerTag) => (
            <Col key={retailerTag.id} span={8}>
              <Card className='department-card'>
                <Row justify='space-between'>
                  <Col style={{ cursor: 'pointer' }} onClick={() => editRetailerTag(retailerTag)}>
                    {snakeCaseToString(retailerTag.name)}
                  </Col>
                  <Dropdown
                    overlay={(
                      <Menu
                        items={[
                          {
                            key: '1',
                            label: <Row onClick={() => editRetailerTag(retailerTag)}>Edit</Row>,
                          },
                          {
                            key: '2',
                            label: <Row onClick={() => setModalData(retailerTag)}>Delete</Row>,
                          },
                        ]}
                      />
                    )}
                  >
                    <a>...</a>
                  </Dropdown>
                </Row>
              </Card>
            </Col>
          ))}
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Pagination
          style={{ marginTop: '20px' }}
          defaultCurrent={1}
          total={retailerTagsData?.total}
          onChange={onPageClick}
          pageSize={pagination.current.limit}
          current={currentPage}
        />
      </Row>
      <Modal title='Delete department' open={!!modalData} onOk={() => handleOk(modalData)} onCancel={handleCancel}>
        {modalData && (
          <div>
            Are you sure to delete <b>{snakeCaseToString(modalData?.name)}</b>?
          </div>
        )}
      </Modal>
    </Container>
  )
}
