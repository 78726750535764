export class Actions {
  requestType: string

  actionType: any

  constructor(reqType: string, actionType: any) {
    this.requestType = reqType
    this.actionType = actionType
  }

  setAction(type: 'R' | 'S' | 'E') {
    const actionT = `${this.requestType}_${type}`
    return this.actionType[actionT]
  }

  request(payload?: Object) {
    const actionType = this.setAction('R')
    return { type: actionType, payload: payload || null }
  }

  success(payload: Object) {
    const actionType = this.setAction('S')
    return { type: actionType, payload: payload || null }
  }

  error(payload: string) {
    const actionType = this.setAction('E')
    return { type: actionType, payload }
  }
}

export enum SocketActions {
  find_companion = 1,
  unregister,
  accept_call,
  cancel_call,
  finish_call,
  relay_sdp,
  relay_ice,
  webrtc_send,
  webrtc_receive,
}
