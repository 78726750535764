import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Form, Input, Col, Breadcrumb, Row, Typography, Steps } from 'antd'
import { Container } from '../wrappers/Container'
import { CreateNewUser, GetUserById, UpdateUserData, GetUsers } from '../store/users/actions'
import { AssignTradePoint } from '../components/consultants/AssignTradePoint'
import { AssignRetailerTag } from '../components/consultants/AssignRetailerTag'
import { AppStore } from '../store/applicationState'
import { ROUTES } from '../utils/routes'
import { GetTradePoints } from '../store/tradePoints/actions'
import { GetRetailerTags } from '../store/retailerTags/actions'

interface CreateConsultantViewProps {}

const steps = [{ title: '1' }, { title: '2' }, { title: '3' }]

export const ConsultantFormView: React.FC<CreateConsultantViewProps> = () => {
  const { id } = useParams<{ id?: string }>()
  const [userId, setUserId] = useState<string>(id || '')
  const [current, setCurrent] = useState(0)

  const history = useHistory()
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const { Users, TradePoints, RetailerTags } = useSelector((store: AppStore) => store)

  useEffect(() => {
    if (userId) dispatch(GetUserById.request({ id: userId }))
  }, [userId])

  useEffect(() => {
    dispatch(GetTradePoints.request())
    dispatch(GetRetailerTags.request())

    if (id) {
      dispatch(
        GetUserById.request({
          id,
          callBack: (success, data) => {
            if (success) {
              form.setFieldsValue({
                firstName: data?.first_name,
                lastName: data?.last_name,
                email: data?.email,
              })
            }
          },
        })
      )
    }
  }, [])

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const onSubmitForm = (values: any) => {
    if (values) {
      dispatch(
        CreateNewUser.request({
          body: {
            email: values.email,
            password: values.password,
            first_name: values.firstName,
            last_name: values.lastName,
          },
          callBack: (success, data) => {
            if (success) {
              setUserId(data.id)
              next()
            }
          },
        })
      )
    }
    return null
  }

  const onUpdate = (values: any) => {
    if (values && id) {
      dispatch(
        UpdateUserData.request({
          user_id: id,
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          callBack: (success) => (success ? dispatch(GetUsers.request()) : null),
        })
      )
    }
    return null
  }

  return (
    <Container>
      <Breadcrumb>
        <Breadcrumb.Item>Consultants</Breadcrumb.Item>
        <Breadcrumb.Item>{id ? 'Edit consultant' : 'Add consultant'}</Breadcrumb.Item>
      </Breadcrumb>
      <Typography.Title level={3} className='pageTitle'>
        {id ? 'Edit consultant' : 'Add consultant'}
      </Typography.Title>

      {!id && (
        <Col xl={21} lg={22} md={22} sm={24} xs={24} style={{ margin: '10px 0 30px' }}>
          <Steps current={current}>
            {steps.map((item) => (
              <Steps.Step key={item.title} />
            ))}
          </Steps>
        </Col>
      )}
      <Row>
        <Col xl={8} lg={8} md={24} sm={24} xs={24}>
          <Form
            form={form}
            className='gx-form-inline-label-up gx-form-row0 '
            size='large'
            disabled={current === 1 || current === 2}
            onFinish={(values) => {
              if (id) {
                onUpdate(values)
              } else {
                onSubmitForm(values)
              }
            }}
          >
            <Col xl={16} lg={20} md={22} sm={24} xs={24}>
              <Form.Item
                required
                rules={[{ required: true, message: 'Please enter first name' }]}
                initialValue=''
                label='First name'
                name='firstName'
                labelCol={{ span: 24 }}
              >
                <Input maxLength={80} placeholder='First name' />
              </Form.Item>
              <Form.Item
                required
                rules={[{ required: true, message: 'Please enter last name' }]}
                initialValue=''
                label='Last name'
                name='lastName'
                labelCol={{ span: 24 }}
              >
                <Input maxLength={80} placeholder='Last name' />
              </Form.Item>
              <Form.Item
                required
                rules={[
                  { required: true, message: 'Please enter email' },
                  { required: true, message: 'Please input your E-mail!' },
                ]}
                initialValue=''
                label='Email'
                name='email'
                labelCol={{ span: 24 }}
              >
                <Input maxLength={80} placeholder='Email' />
              </Form.Item>
              {!id && (
                <Row>
                  <Form.Item
                    required
                    rules={[{ required: true, message: 'Please enter password' }]}
                    initialValue=''
                    label='Password'
                    name='password'
                    labelCol={{ span: 24 }}
                    hasFeedback
                  >
                    <Input.Password maxLength={80} placeholder='Password' />
                  </Form.Item>
                  <Form.Item
                    required
                    rules={[
                      { required: true, message: 'Please confirm password' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve()
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'))
                        },
                      }),
                    ]}
                    initialValue=''
                    label='Confirm password'
                    name='confirmPassword'
                    labelCol={{ span: 24 }}
                    dependencies={['password']}
                    hasFeedback
                  >
                    <Input.Password maxLength={80} placeholder='Password' />
                  </Form.Item>
                </Row>
              )}
              {current === 0 ? (
                <Form.Item>
                  <Button
                    size='large'
                    type='primary'
                    className='consultant-button'
                    onClick={() => {
                      form.submit()
                      if (id) {
                        history.push(ROUTES.users)
                      }
                    }}
                  >
                    {id ? 'Save' : 'Add consultant'}
                  </Button>
                </Form.Item>
              ) : (
                <div style={{ height: '95px' }} />
              )}
            </Col>
          </Form>
        </Col>
        <Col xl={16} lg={16} md={24} sm={24} xs={24}>
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <div style={{ minHeight: '580px' }}>
                <AssignTradePoint
                  userId={userId}
                  userTradePoints={Users?.consultantsData?.trade_points}
                  tradePoints={TradePoints?.data?.trade_points || []}
                  step={current}
                  edit={!!id}
                />
              </div>
              {!id && userId && current === 1 && (
                <div className='steps-action'>
                  {current < steps.length - 1 && (
                    <Button
                      size='large'
                      type='primary'
                      className='consultant-button-step next'
                      onClick={() => next()}
                      disabled={!userId}
                    >
                      Next
                    </Button>
                  )}
                </div>
              )}
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={24}>
              <div style={{ minHeight: '580px' }}>
                <AssignRetailerTag
                  userId={userId}
                  userRetailerTags={Users?.consultantsData?.retailer_tags}
                  retailerTags={RetailerTags?.data?.RetailerTagInformation}
                  step={current}
                  edit={!!id}
                />
              </div>
              {!id && userId && current === 2 && (
                <div className='steps-action'>
                  {current > 0 && (
                    <Button
                      size='large'
                      className='consultant-button-step previous'
                      type='primary'
                      onClick={() => prev()}
                    >
                      Previous
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button
                      className='consultant-button-complete'
                      size='large'
                      type='primary'
                      onClick={() => history.push(ROUTES.users)}
                    >
                      Complete
                    </Button>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
