/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Table, TableColumnsType, Dropdown, Menu, Row, Modal, message } from 'antd'
import { useHistory } from 'react-router-dom'
import { Container } from '../../wrappers/Container'
import { TTradePoint } from '../../store/tradePoints/types'
import { DeleteTradePoint } from '../../store/tradePoints/actions'
import { snakeCaseToString } from '../../utils/helpers'

interface TradePointProps {
  tradePoints: TTradePoint[]
  pagination: any
  currentPage: number
  onPageClick: (page: number, pageSize: number) => void
  total: number
}

export const TradePointsTable: React.FC<TradePointProps> = ({
  tradePoints,
  pagination,
  currentPage,
  onPageClick,
  total,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [modalData, setModalData] = useState<TTradePoint | null>(null)

  const editTradePoint = (tPoint: TTradePoint) => {
    history.push({ pathname: `/shops/edit/${tPoint.id}` })
  }

  const removeTradePoint = (tPoint: TTradePoint) => {
    dispatch(
      DeleteTradePoint.request({
        id: tPoint.id,
        callBack: (success) => {
          if (success) {
            message.success('Shop removed')
            onPageClick(1, pagination.current.limit)
          }
        },
      })
    )
  }

  const handleOk = (data: TTradePoint | null) => {
    if (data) removeTradePoint(data)
    setModalData(null)
  }

  const handleCancel = () => setModalData(null)

  const columns: TableColumnsType<any> | undefined = [
    {
      title: '#',
      key: 'index',
      render: (_, __, index) => pagination.current.limit * (currentPage - 1) + index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <Row onClick={() => editTradePoint(record)} style={{ cursor: 'pointer' }}>
          {snakeCaseToString(record.name)}
        </Row>
      ),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      render: (_, record) => {
        return (
          <Dropdown
            overlay={(
              <Menu
                items={[
                  {
                    key: '1',
                    label: <Row onClick={() => editTradePoint(record)}>Edit</Row>,
                  },
                  {
                    key: '2',
                    label: <Row onClick={() => setModalData(record)}>Delete</Row>,
                  },
                ]}
              />
            )}
          >
            <a>...</a>
          </Dropdown>
        )
      },
    },
  ]

  return (
    <Container>
      {tradePoints && (
        <Table
          dataSource={tradePoints}
          rowKey='id'
          columns={columns}
          pagination={{
            total,
            onChange: onPageClick,
            defaultCurrent: 1,
            pageSize: pagination.current.limit,
            current: currentPage,
          }}
          onRow={(record) => {
            return {
              onDoubleClick: () => editTradePoint(record),
            }
          }}
        />
      )}
      <Modal title='Delete shop' open={!!modalData} onOk={() => handleOk(modalData)} onCancel={handleCancel}>
        {modalData && (
          <div>
            Are you sure to delete <b>{snakeCaseToString(modalData?.name)}</b>?
          </div>
        )}
      </Modal>
    </Container>
  )
}
