import { Actions } from '../../utils/Action'
import { TDefRequest } from '../../types/actions'
import { ActionTypes } from './types'

/// ///////////////////////////////////////////////////////////////////////
const GetUsersActions = new Actions('GET_USERS', ActionTypes)

export interface TypeUsersR extends TDefRequest {
  limit?: number
  offset?: number
  order?: 'asc' | 'desc'
  q?: string
  order_by?: 'email' | 'time_zone' | 'created_at'
  disabled?: boolean
  trade_points?: string[]
  tags?: string[]
}

export const GetUsers = {
  request: (payload?: TypeUsersR) => GetUsersActions.request(payload || {}),
  success: (payload: any) => GetUsersActions.success(payload),
  error: (payload: string) => GetUsersActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetUserByIdAction = new Actions('GET_USER_BY_ID', ActionTypes)

export interface TypeUserByIdR extends TDefRequest {
  id: string
}

export const GetUserById = {
  request: (payload?: TypeUserByIdR) => GetUserByIdAction.request(payload || {}),
  success: (payload: any) => GetUserByIdAction.success(payload),
  error: (payload: string) => GetUserByIdAction.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const CreateNewUserAction = new Actions('CREATE_NEW_USER', ActionTypes)

export interface TypeCreateNewUser extends TDefRequest {
  body: {
    email: string
    password: string
    first_name: string
    last_name: string
  }
}

export const CreateNewUser = {
  request: (payload: TypeCreateNewUser) => CreateNewUserAction.request(payload),
  success: (payload: any) => CreateNewUserAction.success(payload),
  error: (payload: string) => CreateNewUserAction.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const AddUserToTradePointAction = new Actions('ADD_USER_TO_TRADE_POINT', ActionTypes)

export interface TypeUserToTradePoint extends TDefRequest {
  body: {
    user_id: string
    trade_point_id: string
  }
}

export const AddUserToTradePoint = {
  request: (payload: TypeUserToTradePoint) => AddUserToTradePointAction.request(payload),
  success: (payload: any) => AddUserToTradePointAction.success(payload),
  error: (payload: string) => AddUserToTradePointAction.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const DeleteUserFromTradePointAction = new Actions('DELETE_USER_FROM_TRADE_POINT', ActionTypes)

export const DeleteUserFromTradePoint = {
  request: (payload: TypeUserToTradePoint) => DeleteUserFromTradePointAction.request(payload),
  success: (payload: any) => DeleteUserFromTradePointAction.success(payload),
  error: (payload: string) => DeleteUserFromTradePointAction.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const AddRetailerTagToUserAction = new Actions('ADD_RETAILER_TAG_TO_USER', ActionTypes)

export interface TypeRetailerTagToUser extends TDefRequest {
  body: {
    user_id: string
    retailer_tag_id: string
  }
}

export const AddRetailerTagToUser = {
  request: (payload: TypeRetailerTagToUser) => AddRetailerTagToUserAction.request(payload),
  success: (payload: any) => AddRetailerTagToUserAction.success(payload),
  error: (payload: string) => AddRetailerTagToUserAction.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const DeleteRetailerTagFromUserAction = new Actions('DELETE_RETAILER_TAG_FROM_USER', ActionTypes)

export const DeleteRetailerTagFromUser = {
  request: (payload: TypeRetailerTagToUser) => DeleteRetailerTagFromUserAction.request(payload),
  success: (payload: any) => DeleteRetailerTagFromUserAction.success(payload),
  error: (payload: string) => DeleteRetailerTagFromUserAction.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const UpdateUserDataActions = new Actions('UPDATE_USER_DATA', ActionTypes)

export interface TypeUpdateUserDataR extends TDefRequest {
  user_id: string
  email: string
  first_name: string
  last_name: string
  // disabled: boolean
}

export const UpdateUserData = {
  request: (payload: TypeUpdateUserDataR) => UpdateUserDataActions.request(payload),
  success: (payload: any) => UpdateUserDataActions.success(payload),
  error: (payload: string) => UpdateUserDataActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const UpdateUserPasswordActions = new Actions('UPDATE_USER_PASSWORD', ActionTypes)

export interface TypeUpdateUserPasswordR extends TDefRequest {
  user_id: string
  new_password: string
  confirm_new_password: string
}

export const UpdateUserPassword = {
  request: (payload: TypeUpdateUserPasswordR) => UpdateUserPasswordActions.request(payload),
  success: (payload: any) => UpdateUserPasswordActions.success(payload),
  error: (payload: string) => UpdateUserPasswordActions.error(payload),
}

const DeleteUserActions = new Actions('DELETE_USER', ActionTypes)

export interface TypeDeleteUser extends TDefRequest {
  id: string
}

export const DeleteUser = {
  request: (payload: TypeDeleteUser) => DeleteUserActions.request(payload),
  success: (payload: any) => DeleteUserActions.success(payload),
  error: (payload: string) => DeleteUserActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
