import React, { useEffect, useLayoutEffect, useMemo } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Layout } from 'antd'
import { useParams } from 'react-router'
import { IntlProvider } from 'react-intl'
import {
  MakeCall,
  Login,
  Consultant,
  Default,
  TradePointsListView,
  TradePointView,
  RetailerTagsListView,
  RetailerTagView,
  ConsultantsListView,
  ConsultantFormView,
  CreateQRCodeView,
  MyAccount,
  CallStatisticsView,
  Landing,
} from './pages'
import { ROUTES } from './utils/routes'
import { checkToken, ReturnType } from './utils/checkToken'
import { SetIsAuth } from './store/auth/actions'
import Sidebar from './components/Sidebar'
import { AppStore } from './store/applicationState'
import { CheckCamera } from './wrappers/CheckCamera'
import { GetCurrentUserInfo } from './store/myself/actions'
import AppLocale from './IntlProvider'

export const App: React.FC = () => {
  const { tradePointId, retailerTagId } = useParams<any>()

  const dispatch = useDispatch()
  const { Auth, General } = useSelector((store: AppStore) => store)

  useLayoutEffect(() => {
    ;(async () => {
      if (!tradePointId && !retailerTagId) {
        const userToken: ReturnType = await checkToken()
        if (userToken.success) {
          dispatch(SetIsAuth(userToken))
          dispatch(GetCurrentUserInfo.request())
        }
      }
    })()
  }, [tradePointId, retailerTagId])

  useEffect(() => {
    if (Auth.authorized && !Auth.user) {
      ;(async () => {
        const userToken: ReturnType = await checkToken()
        if (userToken.success) {
          dispatch(SetIsAuth(userToken))
          dispatch(GetCurrentUserInfo.request())
        }
      })()
    }
  }, [Auth.authorized])

  const AuthorizedManagerRoutes = useMemo(() => {
    if (Auth.authorized && Auth.user?.isManager && General.locale !== 'pl')
      return (
        <Switch>
          <Route exact path={ROUTES.tradePoints} component={TradePointsListView} />
          <Route exact path={ROUTES.createTradePoint} component={TradePointView} />
          <Route exact path={ROUTES.editTradePoint} component={TradePointView} />
          <Route exact path={ROUTES.retailerTags} component={RetailerTagsListView} />
          <Route exact path={ROUTES.createRetailerTags} component={RetailerTagView} />
          <Route exact path={ROUTES.editRetailerTag} component={RetailerTagView} />
          <Route exact path={ROUTES.users} component={ConsultantsListView} />
          <Route exact path={ROUTES.createUser} component={ConsultantFormView} />
          <Route exact path={ROUTES.editUser} component={ConsultantFormView} />
          <Route exact path={ROUTES.settingsMyAccount} component={MyAccount} />
          <Route exact path={ROUTES.createQRCode} component={CreateQRCodeView} />
          <Route exact path={ROUTES.callStatistics} component={CallStatisticsView} />
          <Route path=''>
            <Redirect to={ROUTES.tradePoints} />
          </Route>
        </Switch>
      )
    return null
  }, [Auth.authorized, Auth.user?.isManager])

  const AuthorizedConsultantRoutes = useMemo(() => {
    if (Auth.authorized && Auth.user?.isConsultant && General.locale !== 'pl')
      return (
        <Switch>
          <Route exact path={ROUTES.home}>
            <CheckCamera>
              <Consultant />
            </CheckCamera>
          </Route>
          <Route exact path={ROUTES.tradePoints} component={TradePointsListView} />
          <Route exact path={ROUTES.settingsMyAccount} component={MyAccount} />
        </Switch>
      )
    return null
  }, [Auth.authorized, Auth.user?.isConsultant])

  const NotAuthorizedRoutes = useMemo(() => {
    if (!Auth.authorized)
      return (
        <Switch>
          <Route exact path={ROUTES.makeCall}>
            <CheckCamera>
              <MakeCall />
            </CheckCamera>
          </Route>
          {General.locale !== 'pl' && <Route path={ROUTES.login} component={Login} />}
          <Route exact path={ROUTES.home} component={Landing} />
          <Route path='*' component={Default} />
        </Switch>
      )
    return null
  }, [Auth.authorized])

  return (
    <>
      <IntlProvider
        messages={AppLocale[General.locale].messages}
        locale={AppLocale[General.locale].locale}
        defaultLocale='en'
      >
        <Layout>
          {Auth.authorized && (
            <Layout.Sider trigger={null}>
              <Sidebar />
            </Layout.Sider>
          )}
          <Layout style={Auth.authorized ? { marginLeft: 200 } : {}}>
            {NotAuthorizedRoutes}
            {AuthorizedManagerRoutes}
            {AuthorizedConsultantRoutes}
          </Layout>
        </Layout>
      </IntlProvider>
    </>
  )
}
