import React, { CSSProperties } from 'react'

interface CloseMenuIconProps {
  style?: CSSProperties
  width?: number
  height?: number
}

export const CloseMenuIcon: React.FC<CloseMenuIconProps> = ({ style, width, height }) => {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      style={{ ...style }}
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect opacity='0.01' x='0.996094' width='24' height='24' fill='#D8D8D8' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.3832 4.2097C19.7755 3.90468 20.3427 3.93241 20.7032 4.29289C21.0937 4.68342 21.0937 5.31658 20.7032 5.70711L14.4103 12L20.7032 18.2929C21.0937 18.6834 21.0937 19.3166 20.7032 19.7071C20.3427 20.0676 19.7755 20.0953 19.3832 19.7903L19.289 19.7071L12.9961 13.4142L6.7032 19.7071L6.60899 19.7903C6.2167 20.0953 5.64947 20.0676 5.28899 19.7071C4.89846 19.3166 4.89846 18.6834 5.28899 18.2929L11.5819 12L5.28899 5.70711C4.89846 5.31658 4.89846 4.68342 5.28899 4.29289C5.64947 3.93241 6.2167 3.90468 6.60899 4.2097L6.7032 4.29289L12.9961 10.5858L19.289 4.29289L19.3832 4.2097Z'
        fill='white'
      />
    </svg>
  )
}
