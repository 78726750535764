export const checkCamera = async (): Promise<boolean> => {
  try {
    const stream: MediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    })

    const res = stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0
    stream.getTracks().forEach((track) => track.stop())
    return res
  } catch (e: any) {
    // eslint-disable-next-line no-alert
    alert('Your device or browser does not allow use camera.\nAllow use camera into your setting and try again!')
    return false
  }
}

const getEnumerateVideoDevices = async () => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices()
    return devices.filter((device) => device.kind === 'videoinput')
  } catch (e: any) {
    // eslint-disable-next-line no-alert
    alert('Your device or browser does not allow use camera.\nAllow use camera into your setting and try again!')
    throw new Error(e)
  }
}

const getUserMedia = async (videoProps?: MediaTrackConstraints, audioProps?: MediaTrackConstraints) => {
  try {
    return await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
        autoGainControl: true,
        noiseSuppression: true,
        ...audioProps,
      },
      video: {
        width: { min: 640, ideal: 1280, max: 1920 },
        height: { min: 480, ideal: 720, max: 1080 },
        ...videoProps,
      },
    })
  } catch (e: any) {
    throw new Error(e)
  }
}

const getBackCamera = async () => {
  try {
    return await getUserMedia({ facingMode: { exact: 'environment' } })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e)
  }
  // or find back camera manually
  try {
    const videoInput: MediaDeviceInfo[] = await getEnumerateVideoDevices()
    const backDeviceId = videoInput.find((item) => item.label.includes(' 0,'))?.deviceId
    if (!backDeviceId) throw new Error('No back cam')
    return await getUserMedia({ deviceId: backDeviceId })
  } catch (e: any) {
    throw new Error(e)
  }
}

export const getCapturedVideo = async (backCamera = false) => {
  try {
    if (!backCamera) return await getUserMedia()

    return await getBackCamera()
  } catch (e: any) {
    // eslint-disable-next-line no-alert
    alert('Your device or browser does not allow use camera.\nAllow use camera into your setting and try again!')
    throw new Error(e)
  }
}

// export const getAudioOutputs = async () => {
//   try {
//     const res = await navigator.mediaDevices.enumerateDevices()
//     return res.filter((item) => item.kind === 'audiooutput')
//   } catch (e: any) {
//     // eslint-disable-next-line no-console
//     console.error('getAudioOutputs', e)
//     throw new Error(e)
//   }
// }
//
// export const changeAudioOutput = async (soundType: 'speaker' | 'phone' | 'mute' | 'headphones', current: any) => {
//   try {
//     const audioOutputs = await getAudioOutputs()
//
//     if (soundType === 'speaker') {
//       const deviceID = audioOutputs.find(({ label }) => label.includes('Built-in'))?.deviceId
//       if (deviceID) await current?.setSinkId(deviceID)
//     } else if (soundType === 'headphones') {
//       const deviceID = audioOutputs.find(({ label }) => label.includes('Bluetooth'))?.deviceId
//       if (deviceID) await current?.setSinkId(deviceID)
//     }
//
//     return audioOutputs
//   } catch (e: any) {
//     // eslint-disable-next-line no-console
//     console.error('changeAudioOutput', e)
//     throw new Error(e)
//   }
// }

export const downloadFile = (contentType: string, decodedData: any, fileName: string) => {
  const uriContent = URL.createObjectURL(new Blob([decodedData], { type: contentType }))
  const link = document.createElement('a')
  link.setAttribute('href', uriContent)
  link.setAttribute('download', fileName)
  const event = new MouseEvent('click')
  link.dispatchEvent(event)
}
